import { Idb } from '@/utils/storage/idb/idb'

const TYPE = {
  SET_IDB_INSTANCE: 'SET_IDB_INSTANCE',
  SET_IDB: 'SET_IDB',
}
export default {
  state: {
    idbInstance: null,
    idb: null,
  },
  getters: {
    idbInstance(state) {
      return state.idbInstance
    },
    idb(state) {
      return state.idb
    },
  },
  actions: {
    async initIndexDB({ commit }, identifier) {
      const instance = new Idb(identifier)
      const idb = await instance.getDB()
      commit(TYPE.SET_IDB_INSTANCE, instance)
      commit(TYPE.SET_IDB, idb)
    },
    async getIndexDBRecords({ commit, getters }, objectStore) {
      return getters.idbInstance.getIndexResults(getters.idb, objectStore)
    },
    async addIndexDBRecord({ getters }, { record, objectStore }) {
      return getters.idbInstance.save(getters.idb, objectStore, record)
    },
    async deleteIndexDBRecord({ getters }, { id, objectStore }) {
      return getters.idbInstance.delete(getters.idb, objectStore, id)
    },
    async deleteAllIndexDBRecords({ getters }, objectStore) {
      return getters.idbInstance.clearAll(getters.idb, objectStore)
    },
  },
  mutations: {
    [TYPE.SET_IDB_INSTANCE](state, idbInstance) {
      state.idbInstance = idbInstance
    },
    [TYPE.SET_IDB](state, idb) {
      state.idb = idb
    },
  },
}
