import { TOAST } from '@/assets/js/variables'

const TYPE = {
  SET_API_ERROR_MESSAGE: 'SET_API_ERROR_MESSAGE',
}

export default {
  namespaced: true,
  state: {
    apiErrorMessage: null,
  },
  getters: {
    getApiErrorMessage(state) {
      return state.apiErrorMessage
    },
  },
  actions: {
    setApiErrorMessage({ commit }, message) {
      commit(TYPE.SET_API_ERROR_MESSAGE, message)
      setTimeout(() => {
        commit(TYPE.SET_API_ERROR_MESSAGE, null)
      }, TOAST.duration)
    },
  },
  mutations: {
    [TYPE.SET_API_ERROR_MESSAGE](state, message) {
      state.apiErrorMessage = message
    },
  },
}
