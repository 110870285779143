<template>
  <div>
    <label
      v-if="hasLabel"
      class="font-semibold text-xs"
      :for="id"
    >
      <slot name="label" />
    </label>
    <div
      class="wrapper"
      :class="{ 'w-full': grow }"
    >
      <div
        @click="click"
        class="outer p2 font-semibold"
        :class="{ [outerClasses]: true, 'w-full': grow, disabled, [outerClass]: true }"
        :style="overrideOuterStyle"
      >
        <div
          class="inner px-3"
          :id="id"
          :class="{ [innerClasses]: true, ...innerLayoutClasses, [innerClass]: true }"
          style="white-space: nowrap"
          :style="overrideInnerStyle"
        >
          <loader
            v-if="hasLeftIcon && loading"
            :loading="true"
            :size="16"
            :enable-transition="false"
          />
          <div v-else>
            <slot name="left-icon" />
          </div>
          <div v-if="hasText">
            <slot name="default" />
          </div>
          <loader
            v-if="hasIcon && loading"
            :loading="true"
            :size="16"
            :enable-transition="false"
          />
          <div v-else>
            <slot name="icon" />
          </div>
        </div>
      </div>
    </div>
    <transition
      name="page-fade"
      mode="out-in"
    >
      <slot
        v-if="hasTransitionContainer"
        name="transition-container"
      />
    </transition>
  </div>
</template>

<script>
import Loader from '@/components/loader/Loader.vue'

export default {
  name: 'the-button',
  components: { Loader },
  emits: ['click'],
  props: {
    id: {
      type: String,
    },
    overrideOuterStyle: {
      type: Object,
    },
    overrideInnerStyle: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
    cta: {
      type: Boolean,
      default: false,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    chip: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    enterprise: {
      type: Boolean,
      default: false,
    },
    proPlus: {
      type: Boolean,
      default: false,
    },
    pro: {
      type: Boolean,
      default: false,
    },
    free: {
      type: Boolean,
      default: false,
    },
    asBasePicker: {
      type: Boolean,
      default: false,
    },
    information: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    warn: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    innerClass: {
      type: String,
      default: '',
    },
    outerClass: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasIcon() {
      return !!this.$slots['icon']
    },
    hasLeftIcon() {
      return !!this.$slots['left-icon']
    },
    hasText() {
      return !!this.$slots['default']
    },
    hasTransitionContainer() {
      return !!this.$slots['transition-container']
    },
    hasLabel() {
      return !!this.$slots['label']
    },
    subscription() {
      return this.free || this.pro || this.proPlus || this.enterprise
    },
    outerClasses() {
      // Disabled buttons
      switch (true) {
        case this.disabled && this.inverse:
        case this.disabled:
          return 'bg-bb-cool-grey'
      }

      // Standard buttons
      switch (true) {
        case this.primary && this.inverse:
          return 'bg-white text-bb-neon-purple'
        case this.primary:
          return 'bg-bb-neon-purple'
        case this.cta && this.inverse:
          return 'bg-cta'
        case this.cta:
          return 'bg-cta'
        case this.secondary && this.inverse:
          return 'bg-bb-dark-grey text-white'
        case this.secondary:
          return 'bg-bb-neon-purple'
        case this.tertiary && this.inverse:
          return 'bg-white text-bb-dull-orange'
        case this.tertiary:
          return 'bg-bb-dull-orange'
        case this.chip:
          return 'border'
        case this.transparent:
          return 'bg-transparent'
      }

      // Subscription buttons
      switch (true) {
        case this.enterprise && this.inverse:
        case this.enterprise:
          return 'bg-enterprise'
        case this.proPlus && this.inverse:
        case this.proPlus:
          return 'bg-pro-plus'
        case this.pro && this.inverse:
        case this.pro:
          return 'bg-pro'
        case this.free && this.inverse:
        case this.free:
          return 'bg-bb-dark-grey'
      }

      // Alert buttons
      switch (true) {
        case this.warn:
          return 'bg-bb-dull-orange'
        case this.information:
          return 'bg-bb-dull-blue'
        case this.success:
          return 'bg-bb-dull-green'
        case this.error:
          return 'bg-bb-dull-red'
      }

      return 'bg-red-600'
    },
    innerLayoutClasses() {
      return {
        'w-full': this.grow,
        'space-x-2': !this.small,
        'space-x-1 p3': this.small,
        'pr-2': this.hasIcon,
        'pl-2': this.hasLeftIcon,
      }
    },
    innerClasses() {
      // Disabled buttons
      switch (true) {
        case this.disabled && this.inverse:
        case this.disabled && this.secondary:
          return 'bg-white text-bb-cool-grey'
        case this.disabled:
          return 'text-white'
      }

      // Standard buttons
      switch (true) {
        case this.primary && this.inverse:
          return 'bg-white'
        case this.primary:
          return 'bg-bb-neon-purple text-white'
        case this.cta && this.inverse:
          return 'bg-white text-bb-neon-purple'
        case this.cta:
          return 'text-white'
        case this.secondary && this.inverse:
          return 'bg-bb-dark-grey text-white'
        case this.secondary:
          return 'bg-white text-bb-neon-purple'
        case this.tertiary && this.inverse:
          return 'bg-white text-orange-500'
        case this.tertiary:
          return 'bg-white text-bb-dull-orange'
        case this.free:
          return 'text-white'
        case this.chip:
          return 'text-black'
        case this.transparent:
          return 'text-primary'
      }

      // Subscription buttons
      switch (true) {
        case this.enterprise && this.inverse:
          return 'bg-white text-orange-600'
        case this.enterprise:
          return 'text-white'
        case this.proPlus && this.inverse:
        case this.pro && this.inverse:
          return 'bg-white text-bb-neon-purple'
        case this.proPlus:
        case this.pro:
          return 'text-white'
        case this.free && this.inverse:
          return 'bg-white text-bb-dark-grey'
      }

      // Alert buttons
      switch (true) {
        case this.warn:
          return 'bg-bb-dull-orange text-white'
        case this.information:
          return 'bg-bb-dull-blue text-white'
        case this.success:
          return 'bg-bb-dull-green text-white'
        case this.error:
          return 'bg-bb-dull-red text-white'
      }

      return 'bg-white text-red-600'
    },
  },
  methods: {
    click(e) {
      if (!this.disabled && !this.loading) this.$emit('click', e)
    },
  },
}
</script>

<style lang="scss" scoped>
// Makes gradient border possible
$border: 2px;
$outer-radius: 6px;
$inner-radius: $outer-radius - $border;

.wrapper {
  // Keeps the children from expanding when context parent is flex, pt. 1
  display: flex;
  flex-direction: column;
}

.outer {
  padding: $border;
  border-radius: $outer-radius;
  cursor: pointer;
  transition:
    opacity 150ms ease-in-out,
    transform 150ms ease-in-out;

  &:hover {
    opacity: 0.85;
  }

  &:active {
    opacity: 1;
    transform: scale(0.98);
  }

  // Keeps the children from expanding when context parent is flex, pt. 2
  align-self: flex-start;
}

.inner {
  border-radius: $inner-radius;
  @apply flex justify-center items-center py-1 h-full;
}

.small {
  padding: 2px 8px;
}

.disabled {
  cursor: default;

  &:hover,
  &:active {
    opacity: 1;
    transform: scale(1);
  }
}

.bg-cta {
  background: linear-gradient(204.06deg, rgba(240, 75, 165, 0.7) 5.67%, rgba(240, 75, 165, 0) 94.25%), $bb-neon-purple;
}

.bg-pro {
  background: linear-gradient(247.34deg, rgba(0, 255, 133, 0.7) 6.3%, rgba(47, 211, 133, 0) 136.66%), $bb-neon-purple;
}

.bg-pro-plus {
  background: linear-gradient(247.34deg, rgba(240, 75, 165, 0.7) 6.3%, rgba(47, 211, 133, 0) 136.66%), $bb-neon-purple;
}

.bg-enterprise {
  background: linear-gradient(204.06deg, rgba(240, 75, 165, 0.85) 5.67%, rgba(240, 75, 165, 0) 94.25%), #f3a64c;
}
</style>
