<template>
  <div class="navbar">
    <div
      :class="{
        'col-menu': !$route.path.includes('portfolio'),
        hidden: $route.path.includes('portfolio'),
      }"
      @click.prevent="toggleMenu"
    >
      <ic-menu
        v-if="sidebarActive"
        title="Menu"
      />
      <ic-close
        v-else
        title="Close menu"
      />
    </div>

    <img
      class="hidden sm:block sm:logo-details my-auto ml-4 mr-7"
      src="/content/images/brightbid/brightbid-logo.png"
      alt="brightbid-logo"
      @click="$router.push({ name: 'portfolio' })"
    />

    <div class="col-site border-l">
      <transition
        name="page-fade"
        mode="out-in"
      >
        <site-dropdown class="store-container" />
      </transition>
    </div>
    <div class="col-account">
      <account-dropdown
        :drop-down="accountSidebarActive"
        @open="openAccountSidebar"
      />
    </div>
  </div>
</template>

<script>
import AccountDropdown from '@/components/layout/Navbar/AccountMenu/AccountDropdown.vue'
import IcMenu from 'vue-material-design-icons/Menu'
import IcClose from 'vue-material-design-icons/Close'
import { mapGetters } from 'vuex'
import SiteDropdown from '@/components/layout/Navbar/OrganizationAndSite/CustomerDropdown.vue'

export default {
  name: 'NavbarComponent',
  components: { SiteDropdown, AccountDropdown, IcMenu, IcClose },
  props: {
    sidebarActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      accountSidebarActive: false,
    }
  },
  computed: {
    ...mapGetters({
      stores: 'store/stores',
    }),
  },
  methods: {
    toggleMenu() {
      this.$emit('toggleMenu')
    },
    openAccountSidebar() {
      this.accountSidebarActive = !this.accountSidebarActive
    },
  },
}
</script>

<style scoped lang="scss">
.navbar {
  @apply bg-white w-full sticky top-0 left-0 flex border-b border-bb-pale-grey;
  height: 95px;
  z-index: 1000;
}

.col-menu {
  @apply block flex items-center border-r border-bb-pale-grey px-4 cursor-pointer;

  @screen lg {
    @apply hidden;
  }
}

.col-site {
  @apply w-full;
  .store-container {
    @apply relative border-r border-bb-pale-grey p-0;
    max-width: 320px;
  }
}

.lg-padding {
  @screen lg {
    padding-left: 224px;
  }
}

.col-account {
  @apply border-l border-bb-pale-grey;
}

.logo-details {
  height: 50px;
  width: 180px;
}
</style>
