const TYPE = {
  SET_SELECTED_TIER: 'SET_SELECTED_TIER',
}

export default {
  namespaced: true,
  state: {
    selectedTier: {},
  },
  getters: {
    getSelectedTier(state) {
      return state.selectedTier
    },
  },
  actions: {
    setSelectedTier({ commit }, tier) {
      commit(TYPE.SET_SELECTED_TIER, tier)
    },
  },
  mutations: {
    [TYPE.SET_SELECTED_TIER](state, tier) {
      state.selectedTier = tier
    },
  },
}
