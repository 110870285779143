exports.getStoreSettingsKey = storeId => `store_settings_${storeId}`
exports.getSelectedStoreKey = userId => `selected_store_${userId}`
exports.getRecentStoreIdsKey = userId => `recent_stores_${userId}`

const KEY = {
  BILLING_FORM: 'BILLING_FORM',
  FEED_FORM: 'FEED_FORM',
}

exports.writeStoreSettings = (storeId, data) => localStorage.setItem(this.getStoreSettingsKey(storeId), data)
exports.readStoreSettings = storeId => localStorage.getItem(this.getStoreSettingsKey(storeId))

exports.writeBillingForm = form => localStorage.setItem(KEY.BILLING_FORM, JSON.stringify(form))
exports.readBillingForm = () => JSON.parse(localStorage.getItem(KEY.BILLING_FORM))
exports.deleteBillingForm = () => localStorage.removeItem(KEY.BILLING_FORM)

exports.writeFeedForm = form => localStorage.setItem(KEY.FEED_FORM, JSON.stringify(form))
exports.readFeedForm = () => JSON.parse(localStorage.getItem(KEY.FEED_FORM))
exports.deleteFeedForm = () => localStorage.removeItem(KEY.FEED_FORM)

exports.writeSelectedStoreId = ({ userId, storeId }) =>
  localStorage.setItem(this.getSelectedStoreKey(userId), JSON.stringify(storeId))
exports.readSelectedStoreId = ({ userId }) => JSON.parse(localStorage.getItem(this.getSelectedStoreKey(userId)))
exports.deleteSelectedStoreId = ({ userId }) => localStorage.removeItem(this.getSelectedStoreKey(userId))

exports.writeRecentStoreIds = ({ userId, recentStoreIds }) =>
  localStorage.setItem(this.getRecentStoreIdsKey(userId), JSON.stringify(recentStoreIds))
exports.readRecentStoreIds = ({ userId }) => JSON.parse(localStorage.getItem(this.getRecentStoreIdsKey(userId)))
exports.deleteRecentStoreIds = ({ userId }) => localStorage.removeItem(this.getRecentStoreIdsKey(userId))
