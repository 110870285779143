import { omit, pick } from '@/utils/ObjectHelpers'

export const enterpriseSteps = async (rootGetters, dispatch, commit, stepConfigurations, TYPE) => {
  const store = rootGetters['store/selectedStore']

  await Promise.all([
    dispatch('billing/loadAccountBillingsIfNeeded', null, { root: true }),
    dispatch('feed/loadFeedsByStoreIdIfNeeded', store.id, { root: true }),
    dispatch('campaign/loadCampaignsByStoreIdIfNeeded', store.id, { root: true }),
  ])

  const feedsByStoreId = rootGetters['feed/getFeedsByStoreId'](store.id)
  const billings = rootGetters['billing/getAccountBillings']

  if (!billings.length) {
    localStorage.setItem('GMC_NOT_SET', 'true')
  } else {
    localStorage.removeItem('GMC_NOT_SET')
  }
  const GMC_SET = localStorage.getItem('GMC_NOT_SET')

  let omitConfigs = omit(stepConfigurations, 'creditCard')
  let configuration = pick(
    omitConfigs,
    'storeInfo',
    'contentApi',
    'productFeed',
    'supplementalFeed',
    'billingInformation',
  )

  if (store.country_id) {
    configuration = { ...configuration, storeInfo: { ...configuration.storeInfo, completed: true } }
  }
  if (feedsByStoreId.length) {
    configuration = { ...configuration, productFeed: { ...configuration.productFeed, completed: true } }
  }
  if (!GMC_SET) {
    configuration = { ...configuration, supplementalFeed: { ...configuration.supplementalFeed, completed: true } }
  }
  if (billings.length) {
    configuration = { ...configuration, billingInformation: { ...configuration.billingInformation, completed: true } }
  }
  configuration = {
    ...configuration,
    contentApi: {
      ...configuration.contentApi,
      completed:
        (store.integration.content_api.has_token && !!store.integration.content_api.account_id) ||
        feedsByStoreId.length,
    },
  }

  const configurationToArray = Object.keys(configuration).map(c => configuration[c])
  commit(TYPE.SET_STEPS, configurationToArray)
  commit(TYPE.SET_STEPS_CONFIGS, configuration)
}
