const TYPE = {
  SET_THRESHOLDS: 'SET_THRESHOLDS',
  SET_ALERT: 'SET_ALERT',
  TOGGLE_CHECKBOX: 'TOGGLE_CHECKBOX',
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_IS_SUBMIT: 'SET_IS_SUBMIT',
  CLEAR_STATE: 'CLEAR_STATE',
}

export default {
  namespaced: true,
  state: {
    alert: null,
    isLoading: false,
    isSubmit: false,
  },
  getters: {
    alert(state) {
      return state.alert
    },
    isLoading(state) {
      return state.isLoading
    },
    isSubmit(state) {
      return state.isSubmit
    },
  },
  actions: {
    async loadAlert({ commit, rootGetters }) {
      const store = rootGetters['store/selectedStore']
      const user = rootGetters['auth/user']
      commit(TYPE.SET_LOADING, true)
      try {
        const alert = await axios.get(`/store/${store.id}/alert`)
        const alertData = alert.data.data
        if (Array.isArray(alertData)) {
          commit(TYPE.SET_ALERT, {
            emails: [user.email],
            products_drop_threshold: 20,
            feed_offline_hours_threshold: 24,
            clicks_drop_threshold: null,
          })
          commit(TYPE.SET_LOADING, false)
        } else {
          commit(TYPE.SET_ALERT, alertData)
          commit(TYPE.SET_LOADING, false)
        }
      } catch (e) {
        commit(TYPE.SET_LOADING, false)
      }
    },
    async postAlert({ commit, getters, rootGetters }, alertForm) {
      const store = rootGetters['store/selectedStore']
      const alertModel = {}
      for (const eKey in alertForm) {
        alertModel[eKey] = alertForm[eKey].value
      }
      const mergeAlert = { ...getters.alert, ...alertModel, store_id: store.id, emails: alertModel.emails.split(',') }
      commit(TYPE.SET_IS_SUBMIT, true)
      try {
        await axios.post(`/store/${mergeAlert.store_id}/alert`, mergeAlert)
        commit(TYPE.SET_ALERT, mergeAlert)
        commit(TYPE.SET_IS_SUBMIT, false)
      } catch (e) {
        commit(TYPE.SET_IS_SUBMIT, false)
      }
    },
  },
  mutations: {
    [TYPE.SET_ALERT](state, alert) {
      state.alert = alert
    },
    [TYPE.SET_LOADING](state, loadState) {
      state.isLoading = loadState
    },
    [TYPE.SET_IS_SUBMIT](state, isSubmit) {
      state.isSubmit = isSubmit
    },
  },
}
