<template>
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>AI Brain</title>
    <path
      d="M1.64552 9.22241C2.69136 9.36502 3.68967 8.65195 3.83228 7.60611C3.87982 7.22581 3.83228 6.8455 3.64213 6.51273C3.49951 6.13243 3.68967 5.75212 3.78474 5.60951L3.83228 5.51443L3.92736 5.37182L4.11751 5.13412C4.3552 4.8489 4.68797 4.51613 5.11581 4.46859C5.82889 4.42105 6.44688 3.89813 6.54196 3.13752C6.63704 2.28183 6.06658 1.52122 5.21089 1.42615C4.40274 1.33107 3.64213 1.90153 3.54705 2.75722C3.49951 2.99491 3.54705 3.18506 3.59459 3.42275V3.47029C3.78474 3.94567 3.45198 4.46859 3.3569 4.6112V4.65874L3.16675 4.94397L3.11921 4.99151C2.97659 5.13412 2.64383 5.51443 2.07337 5.46689H2.02583C1.02753 5.41935 0.171841 6.13243 0.0292261 7.13073C-0.160927 8.12903 0.599684 9.07979 1.64552 9.22241Z"
      fill="#6E19F0"
    />
    <path
      d="M1.64552 9.22241C2.69136 9.36502 3.68967 8.65195 3.83228 7.60611C3.87982 7.22581 3.83228 6.8455 3.64213 6.51273C3.49951 6.13243 3.68967 5.75212 3.78474 5.60951L3.83228 5.51443L3.92736 5.37182L4.11751 5.13412C4.3552 4.8489 4.68797 4.51613 5.11581 4.46859C5.82889 4.42105 6.44688 3.89813 6.54196 3.13752C6.63704 2.28183 6.06658 1.52122 5.21089 1.42615C4.40274 1.33107 3.64213 1.90153 3.54705 2.75722C3.49951 2.99491 3.54705 3.18506 3.59459 3.42275V3.47029C3.78474 3.94567 3.45198 4.46859 3.3569 4.6112V4.65874L3.16675 4.94397L3.11921 4.99151C2.97659 5.13412 2.64383 5.51443 2.07337 5.46689H2.02583C1.02753 5.41935 0.171841 6.13243 0.0292261 7.13073C-0.160927 8.12903 0.599684 9.07979 1.64552 9.22241Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M12.9121 12.5025C12.4842 12.5025 12.1039 12.6452 11.7236 12.8353C11.6761 12.8353 11.6761 12.8829 11.6285 12.8829C10.9155 13.4058 9.63194 12.9779 9.63194 12.9779L9.48933 12.9304C9.15656 12.7878 8.34841 12.3599 8.15826 11.6469C8.15826 11.5993 8.11072 11.5518 8.11072 11.5042C8.11072 11.4567 8.11072 11.4567 8.11072 11.4092C7.92057 10.7912 8.15826 10.4584 8.25333 10.3158C8.49102 10.0781 8.91887 9.69779 9.63194 9.69779C9.72702 9.69779 9.77456 9.69779 9.86963 9.69779C11.4384 9.65025 12.7219 8.31918 12.6268 6.70289C12.5793 5.13412 11.2007 3.89813 9.63194 3.94567C8.06318 3.99321 6.77965 5.32428 6.87473 6.94058C6.87473 7.51103 7.11242 8.03395 7.39765 8.4618C7.68288 9.03226 7.39765 9.69779 7.30257 9.84041L7.35011 9.98302C7.20749 10.1256 6.92226 10.4109 6.30427 10.5059C6.25673 10.5059 6.20919 10.5059 6.16165 10.5535C5.3535 10.7436 4.78305 11.4567 4.83058 12.3124C4.87812 13.2632 5.68627 13.9762 6.63704 13.9762C6.68457 13.9762 6.73211 13.9762 6.77965 13.9762C6.87473 13.9762 6.9698 13.9287 7.15996 13.8812H7.20749C8.2058 13.5008 9.01394 13.8336 9.01394 13.8336L9.25164 13.9287C9.48933 14.0238 10.2499 14.4991 10.7729 15.8302V15.8778C10.7729 15.9253 10.8204 15.9728 10.8204 16.0204C11.2007 16.9236 12.1039 17.4941 13.1498 17.4465C14.4808 17.399 15.5742 16.2581 15.4791 14.927C15.3841 13.4533 14.2431 12.4075 12.9121 12.5025Z"
      fill="#6E19F0"
    />
    <path
      d="M12.9121 12.5025C12.4842 12.5025 12.1039 12.6452 11.7236 12.8353C11.6761 12.8353 11.6761 12.8829 11.6285 12.8829C10.9155 13.4058 9.63194 12.9779 9.63194 12.9779L9.48933 12.9304C9.15656 12.7878 8.34841 12.3599 8.15826 11.6469C8.15826 11.5993 8.11072 11.5518 8.11072 11.5042C8.11072 11.4567 8.11072 11.4567 8.11072 11.4092C7.92057 10.7912 8.15826 10.4584 8.25333 10.3158C8.49102 10.0781 8.91887 9.69779 9.63194 9.69779C9.72702 9.69779 9.77456 9.69779 9.86963 9.69779C11.4384 9.65025 12.7219 8.31918 12.6268 6.70289C12.5793 5.13412 11.2007 3.89813 9.63194 3.94567C8.06318 3.99321 6.77965 5.32428 6.87473 6.94058C6.87473 7.51103 7.11242 8.03395 7.39765 8.4618C7.68288 9.03226 7.39765 9.69779 7.30257 9.84041L7.35011 9.98302C7.20749 10.1256 6.92226 10.4109 6.30427 10.5059C6.25673 10.5059 6.20919 10.5059 6.16165 10.5535C5.3535 10.7436 4.78305 11.4567 4.83058 12.3124C4.87812 13.2632 5.68627 13.9762 6.63704 13.9762C6.68457 13.9762 6.73211 13.9762 6.77965 13.9762C6.87473 13.9762 6.9698 13.9287 7.15996 13.8812H7.20749C8.2058 13.5008 9.01394 13.8336 9.01394 13.8336L9.25164 13.9287C9.48933 14.0238 10.2499 14.4991 10.7729 15.8302V15.8778C10.7729 15.9253 10.8204 15.9728 10.8204 16.0204C11.2007 16.9236 12.1039 17.4941 13.1498 17.4465C14.4808 17.399 15.5742 16.2581 15.4791 14.927C15.3841 13.4533 14.2431 12.4075 12.9121 12.5025Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M7.47765 19.5312C8.31164 18.6942 8.30925 17.3397 7.47231 16.5057C6.63536 15.6717 5.2808 15.6741 4.44681 16.511C3.61282 17.348 3.61521 18.7025 4.45216 19.5365C5.2891 20.3705 6.64366 20.3681 7.47765 19.5312Z"
      fill="#6E19F0"
    />
    <path
      d="M7.47765 19.5312C8.31164 18.6942 8.30925 17.3397 7.47231 16.5057C6.63536 15.6717 5.2808 15.6741 4.44681 16.511C3.61282 17.348 3.61521 18.7025 4.45216 19.5365C5.2891 20.3705 6.64366 20.3681 7.47765 19.5312Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M10.2024 19.4431C9.44179 19.3005 8.68118 19.871 8.5861 20.6316C8.44349 21.3922 9.01394 22.1528 9.77456 22.2479C10.5352 22.3905 11.2958 21.82 11.3909 21.0594C11.5335 20.2988 11.0105 19.5857 10.2024 19.4431Z"
      fill="#6E19F0"
    />
    <path
      d="M10.2024 19.4431C9.44179 19.3005 8.68118 19.871 8.5861 20.6316C8.44349 21.3922 9.01394 22.1528 9.77456 22.2479C10.5352 22.3905 11.2958 21.82 11.3909 21.0594C11.5335 20.2988 11.0105 19.5857 10.2024 19.4431Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M7.35011 22.2479C6.68457 22.1528 6.11412 22.5806 5.9715 23.2462C5.87642 23.9117 6.30427 24.4822 6.9698 24.5772C7.63534 24.6723 8.2058 24.2445 8.34841 23.5789C8.44349 22.9609 8.01564 22.3429 7.35011 22.2479Z"
      fill="#6E19F0"
    />
    <path
      d="M7.35011 22.2479C6.68457 22.1528 6.11412 22.5806 5.9715 23.2462C5.87642 23.9117 6.30427 24.4822 6.9698 24.5772C7.63534 24.6723 8.2058 24.2445 8.34841 23.5789C8.44349 22.9609 8.01564 22.3429 7.35011 22.2479Z"
      fill="url(#paint4_linear)"
    />
    <path
      d="M24.7491 6.75042C24.1786 6.65535 23.6082 7.03565 23.5131 7.60611C23.418 8.17657 23.7983 8.74703 24.3688 8.8421C24.9392 8.93718 25.5097 8.55687 25.6048 7.98642C25.6999 7.41596 25.3195 6.8455 24.7491 6.75042Z"
      fill="#6E19F0"
    />
    <path
      d="M24.7491 6.75042C24.1786 6.65535 23.6082 7.03565 23.5131 7.60611C23.418 8.17657 23.7983 8.74703 24.3688 8.8421C24.9392 8.93718 25.5097 8.55687 25.6048 7.98642C25.6999 7.41596 25.3195 6.8455 24.7491 6.75042Z"
      fill="url(#paint5_linear)"
    />
    <path
      d="M18.7117 13.4058C17.9036 13.4058 17.2856 14.0238 17.2856 14.8319C17.2856 15.6401 17.9036 16.2581 18.7117 16.2581C19.5199 16.2581 20.1379 15.6401 20.1379 14.8319C20.1379 14.0713 19.4723 13.4058 18.7117 13.4058Z"
      fill="#6E19F0"
    />
    <path
      d="M18.7117 13.4058C17.9036 13.4058 17.2856 14.0238 17.2856 14.8319C17.2856 15.6401 17.9036 16.2581 18.7117 16.2581C19.5199 16.2581 20.1379 15.6401 20.1379 14.8319C20.1379 14.0713 19.4723 13.4058 18.7117 13.4058Z"
      fill="url(#paint6_linear)"
    />
    <path
      d="M24.2737 9.93548C24.1311 9.93548 23.9885 10.0306 23.8459 10.0781C23.5131 10.2682 23.2279 10.2682 22.9426 10.2207L22.4197 10.1256H22.3722C22.2296 10.0781 21.7542 9.98302 21.5165 9.4601C21.2788 8.65195 20.5657 8.03395 19.71 7.93888C19.6625 7.93888 19.615 7.93888 19.5674 7.93888C18.8068 7.93888 18.5216 7.4635 18.5216 7.4635L18.0462 6.60781C18.0462 6.60781 17.6659 5.70458 18.0462 4.80136C18.1888 4.46859 18.2364 4.08828 18.1888 3.70798C18.0462 2.51952 16.9528 1.71137 15.7644 1.85399C14.5759 1.9966 13.7678 3.08998 13.9104 4.27844C14.053 5.27674 14.8611 6.03735 15.8594 6.13243C15.907 6.13243 15.9545 6.13243 16.0021 6.13243C16.6676 6.13243 17.143 6.89304 17.2381 7.08319L17.6659 7.8438C17.6659 7.8438 17.9511 8.36672 17.6184 8.98472C17.3807 9.41256 17.2381 9.88794 17.3331 10.3633C17.4757 11.5518 18.5691 12.3599 19.7576 12.2173C20.233 12.1698 20.7083 11.9321 20.9936 11.5993C21.469 11.1239 21.8968 11.0289 22.2296 11.1239L22.9426 11.2666C23.1328 11.3141 23.2754 11.4092 23.5131 11.6469C23.5131 11.6469 23.5131 11.6944 23.5606 11.6944C23.5606 11.6944 23.5606 11.7419 23.6082 11.7419C23.8459 12.0272 24.1786 12.1698 24.5589 12.1222C25.1294 12.0272 25.5572 11.5042 25.5097 10.9338C25.4146 10.2682 24.8917 9.84041 24.2737 9.93548Z"
      fill="#6E19F0"
    />
    <path
      d="M24.2737 9.93548C24.1311 9.93548 23.9885 10.0306 23.8459 10.0781C23.5131 10.2682 23.2279 10.2682 22.9426 10.2207L22.4197 10.1256H22.3722C22.2296 10.0781 21.7542 9.98302 21.5165 9.4601C21.2788 8.65195 20.5657 8.03395 19.71 7.93888C19.6625 7.93888 19.615 7.93888 19.5674 7.93888C18.8068 7.93888 18.5216 7.4635 18.5216 7.4635L18.0462 6.60781C18.0462 6.60781 17.6659 5.70458 18.0462 4.80136C18.1888 4.46859 18.2364 4.08828 18.1888 3.70798C18.0462 2.51952 16.9528 1.71137 15.7644 1.85399C14.5759 1.9966 13.7678 3.08998 13.9104 4.27844C14.053 5.27674 14.8611 6.03735 15.8594 6.13243C15.907 6.13243 15.9545 6.13243 16.0021 6.13243C16.6676 6.13243 17.143 6.89304 17.2381 7.08319L17.6659 7.8438C17.6659 7.8438 17.9511 8.36672 17.6184 8.98472C17.3807 9.41256 17.2381 9.88794 17.3331 10.3633C17.4757 11.5518 18.5691 12.3599 19.7576 12.2173C20.233 12.1698 20.7083 11.9321 20.9936 11.5993C21.469 11.1239 21.8968 11.0289 22.2296 11.1239L22.9426 11.2666C23.1328 11.3141 23.2754 11.4092 23.5131 11.6469C23.5131 11.6469 23.5131 11.6944 23.5606 11.6944C23.5606 11.6944 23.5606 11.7419 23.6082 11.7419C23.8459 12.0272 24.1786 12.1698 24.5589 12.1222C25.1294 12.0272 25.5572 11.5042 25.5097 10.9338C25.4146 10.2682 24.8917 9.84041 24.2737 9.93548Z"
      fill="url(#paint7_linear)"
    />
    <path
      d="M21.7066 6.03735C22.6099 6.03735 23.3705 5.27674 23.3705 4.37351C23.3705 3.47029 22.6099 2.70968 21.7066 2.70968C20.8034 2.70968 20.0428 3.47029 20.0428 4.37351C20.0428 5.27674 20.8034 6.03735 21.7066 6.03735Z"
      fill="#6E19F0"
    />
    <path
      d="M21.7066 6.03735C22.6099 6.03735 23.3705 5.27674 23.3705 4.37351C23.3705 3.47029 22.6099 2.70968 21.7066 2.70968C20.8034 2.70968 20.0428 3.47029 20.0428 4.37351C20.0428 5.27674 20.8034 6.03735 21.7066 6.03735Z"
      fill="url(#paint8_linear)"
    />
    <path
      d="M10.6778 2.85229C11.4859 2.85229 12.1039 2.2343 12.1039 1.42615C12.1039 0.617996 11.4859 0 10.6778 0C9.86963 0 9.25164 0.617996 9.25164 1.42615C9.25164 2.2343 9.86963 2.85229 10.6778 2.85229Z"
      fill="#6E19F0"
    />
    <path
      d="M10.6778 2.85229C11.4859 2.85229 12.1039 2.2343 12.1039 1.42615C12.1039 0.617996 11.4859 0 10.6778 0C9.86963 0 9.25164 0.617996 9.25164 1.42615C9.25164 2.2343 9.86963 2.85229 10.6778 2.85229Z"
      fill="url(#paint9_linear)"
    />
    <path
      d="M14.3858 8.41426C13.5776 8.41426 12.9596 9.03226 12.9596 9.84041C12.9596 10.6486 13.5776 11.2666 14.3858 11.2666C15.1939 11.2666 15.8119 10.6486 15.8119 9.84041C15.8119 9.07979 15.1464 8.41426 14.3858 8.41426Z"
      fill="#6E19F0"
    />
    <path
      d="M14.3858 8.41426C13.5776 8.41426 12.9596 9.03226 12.9596 9.84041C12.9596 10.6486 13.5776 11.2666 14.3858 11.2666C15.1939 11.2666 15.8119 10.6486 15.8119 9.84041C15.8119 9.07979 15.1464 8.41426 14.3858 8.41426Z"
      fill="url(#paint10_linear)"
    />
    <path
      d="M1.83568 10.5535C1.02753 10.5535 0.409532 11.1715 0.409532 11.9796C0.409532 12.7878 1.02753 13.4058 1.83568 13.4058C2.64383 13.4058 3.26182 12.7878 3.26182 11.9796C3.26182 11.219 2.64383 10.5535 1.83568 10.5535Z"
      fill="#6E19F0"
    />
    <path
      d="M1.83568 10.5535C1.02753 10.5535 0.409532 11.1715 0.409532 11.9796C0.409532 12.7878 1.02753 13.4058 1.83568 13.4058C2.64383 13.4058 3.26182 12.7878 3.26182 11.9796C3.26182 11.219 2.64383 10.5535 1.83568 10.5535Z"
      fill="url(#paint11_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="20.9168"
        y1="-4.87868e-07"
        x2="9.50764"
        y2="26.6214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F04BA5" />
        <stop
          offset="1"
          stop-color="#F04BA5"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'the-gradient-brain',
}
</script>
