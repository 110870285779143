const state = {
  countries: null,
  amazonMarkets: null,
  loading: false,
  error: null,
}

const MUTATION_TYPES = {
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_AMAZONMARKETS: 'SET_AMAZONMARKETS',
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
}

const mutations = {
  [MUTATION_TYPES.SET_COUNTRIES](state, countries) {
    state.countries = countries
  },
  [MUTATION_TYPES.SET_AMAZONMARKETS](state, amazonMarkets) {
    state.amazonMarkets = amazonMarkets
  },
  [MUTATION_TYPES.SET_LOADING](state, loading) {
    state.loading = loading
  },
  [MUTATION_TYPES.SET_ERROR](state, error) {
    state.error = error
  },
}

const actions = {
  async fetchCountries({ commit, state }) {
    if (state.countries === null) {
      commit(MUTATION_TYPES.SET_LOADING, true)
      try {
        const response = await axios.get('common/country')
        const countries = response.data.map(country => ({
          value: country.code,
          label: country.name,
        }))
        commit(MUTATION_TYPES.SET_COUNTRIES, countries)
      } catch (error) {
        commit(MUTATION_TYPES.SET_ERROR, error.response?.data?.message || error.message)
        commit(MUTATION_TYPES.SET_COUNTRIES, null)
      } finally {
        commit(MUTATION_TYPES.SET_ERROR, null)
        commit(MUTATION_TYPES.SET_LOADING, false)
      }
    }
  },
  async fetchAmazonAvailableMarketsData({ commit }) {
    commit(MUTATION_TYPES.SET_LOADING, true)
    try {
      const response = await axios.get('common/site/amazon_available_markets_data')
      commit(MUTATION_TYPES.SET_AMAZONMARKETS, response.data)
    } catch (error) {
      commit(MUTATION_TYPES.SET_ERROR, error.response?.data?.message || error.message)
    } finally {
      commit(MUTATION_TYPES.SET_LOADING, false)
    }
  },
}
const getters = {
  formattedAmazonMarkets: state => {
    if (!state.amazonMarkets || !state.countries) {
      return []
    }

    return Object.keys(state.amazonMarkets).map(countryName => {
      // Find the corresponding country object in the countries state
      const matchedCountry = state.countries.find(country => country.label === countryName)
      // Get the country code, if available
      const countryCode = matchedCountry ? matchedCountry.value : null

      return {
        label: countryName,
        value: countryCode,
        languages: state.amazonMarkets[countryName].map(language => {
          // Assuming you have a similar mechanism for language codes
          return { label: language, value: getLanguageCode(language) }
        }),
      }
    })
  },
}
function getLanguageCode(language) {
  return language.slice(0, 2).toUpperCase() // Example implementation
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
