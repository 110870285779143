import { nanSafeDivision } from '@/utils/compute-metrics-class'

const state = {
  dates: {
    startDate: '',
    endDate: '',
    compareStartDate: '',
    compareEndDate: '',
  },
  data: null,
  siteMetrics: {},
  portfolioViewMode: 'site',
}

const mutations = {
  SET_DATES(state, dates) {
    state.dates = { ...dates }
  },
  SET_DATA(state, data) {
    state.data = data
  },
  SET_SITE_METRICS(state, { siteId, metrics }) {
    state.siteMetrics = { ...state.siteMetrics, [siteId]: metrics }
  },
  SET_VIEW_MODE(state, value) {
    state.portfolioViewMode = value
  },
}

const actions = {
  async loadPortfolioData({ commit }, { startDate, endDate, compareStartDate, compareEndDate, organizations }) {
    try {
      const sitesPortfolioResponse = await axios.get(`common/portfolio/sites`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          compare_start_date: compareStartDate,
          compare_end_date: compareEndDate,
        },
      })

      const sitesData = sitesPortfolioResponse.data
      let organizationsData = []
      const groupedSites = groupByOrganizationId(sitesData)
      organizations.forEach(organization => {
        const hasSites = !!groupedSites[organization.id]
        let data = {
          id: organization.id,
          name: organization.name,
          starred: organization.star,
          currency: null,
          mixed_currencies: false,
        }

        // Aggregate notifications
        data.notification_count = hasSites
          ? groupedSites[organization.id].reduce((sum, site) => sum + site.notification_count, 0)
          : 0

        // Aggregate simple metrics
        ;['clicks', 'conversions', 'cost'].forEach(metric => {
          const current = hasSites
            ? groupedSites[organization.id].reduce((sum, site) => sum + site[metric].current, 0)
            : null
          const compare = hasSites
            ? groupedSites[organization.id].reduce((sum, site) => sum + site[metric].compare, 0)
            : null
          data[metric] = { current: current, compare: compare }
        })

        // Calculate CPC manually
        const currentCPC = hasSites ? nanSafeDivision(data.cost.current, data.conversions.current) : null
        const compareCPC = hasSites ? nanSafeDivision(data.cost.compare, data.conversions.compare) : null
        data.cpc = { current: currentCPC, compare: compareCPC }

        organizationsData.push(data)
      })

      commit('SET_DATA', { sitesData, organizationsData })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  },

  updateDates({ commit }, dates) {
    commit('SET_DATES', dates)
  },

  setSiteMetrics({ commit }, { siteId, metrics }) {
    commit('SET_SITE_METRICS', { siteId, metrics })
  },

  updateSite({ commit, state }, { siteId, data }) {
    const sitesData = state.data.sitesData.map(site => (site.id === siteId ? { ...site, ...data } : site))
    commit('SET_DATA', { ...state.data, sitesData })
  },
  updateOrganization({ commit, state }, { organizationId, data }) {
    const organizationsData = state.data.organizationsData.map(organization =>
      organization.id === organizationId ? { ...organization, ...data } : organization,
    )
    commit('SET_DATA', { ...state.data, organizationsData })
  },
}

function groupByOrganizationId(sites) {
  return sites.reduce((acc, site) => {
    const orgId = site.organization_id
    if (!acc[orgId]) {
      acc[orgId] = []
    }
    acc[orgId].push(site)
    return acc
  }, {})
}

const getters = {
  portfolioData: state => ({
    organizationsData: state.data?.organizationsData
      ? [...state.data.organizationsData].sort((a, b) => (a.starred === b.starred ? 0 : a.starred ? -1 : 1))
      : [],
    sitesData: state.data?.sitesData
      ? [...state.data.sitesData].sort((a, b) => (a.starred === b.starred ? 0 : a.starred ? -1 : 1))
      : [],
  }),
  siteMetrics: state => state.siteMetrics,
  dates: state => state.dates,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
