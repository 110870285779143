const dayjs = require('dayjs')

export const dateToString = date => (date ? date.toISOString().split('T')[0] : null)

export const dateToHumanReadable = value => {
  if (!value) return '-'
  return value.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

export const humanizeDate = date => (date ? dayjs(date).format('ddd D MMM, YYYY') : null)

const chartDateStringValidator = value => {
  if (value === null || value.match(/^^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][\d]|3[01])$/)) return true
  if (process.env.NODE_ENV !== 'production')
    console.error(`Chart date props must must be null or have format 'yyyy-mm-dd', but was '${value}'`)
  return false
}

export { chartDateStringValidator }

export const dateRangePresets = preset => {
  const today = dayjs().hour(0).minute(0).second(0).millisecond(0)
  const todayMonth = today.month()
  switch (preset) {
    case 'Today':
      return { from: today.toDate(), to: today.toDate() }
    case 'Yesterday':
      return { from: today.subtract(1, 'day').toDate(), to: today.toDate() }
    case 'Last 7 days':
      return { from: today.subtract(6, 'day').toDate(), to: today.toDate() }
    case 'Last 14 days':
      return { from: today.subtract(13, 'day').toDate(), to: today.toDate() }
    case 'Last 30 days':
      return { from: today.subtract(29, 'day').toDate(), to: today.toDate() }
    case 'Last month':
      return {
        from: today
          .month(todayMonth - 1)
          .startOf('month')
          .toDate(),
        to: today
          .month(todayMonth - 1)
          .endOf('month')
          .toDate(),
      }
    case 'Last 3 months':
      return {
        from: today
          .month(todayMonth - 3)
          .startOf('month')
          .toDate(),
        to: today
          .month(todayMonth - 1)
          .endOf('month')
          .toDate(),
      }
  }
}
