<template>
  <div>
    <base-alert
      v-if="showInactiveFeedAlert"
      type="error"
      sharp
    >
      <template #title> Warning </template>
      <template #content> One or more product feeds are unavailable - Please correct any outstanding issues </template>
      <template #action>
        <div class="flex space-x-8">
          <the-button
            @click="$router.push({ name: 'campaign:feed' })"
            :disabled="$route.name === 'campaign:feed'"
            error
          >
            Go to feed page
          </the-button>
        </div>
      </template>
    </base-alert>

    <base-alert
      v-if="showBillingAlert"
      type="warn"
      sharp
    >
      <template #content> Add your billing information to complete you account setup. </template>
      <template #action>
        <div class="flex space-x-8">
          <the-button
            @click="$router.push({ name: 'account:billing:profiles' })"
            :disabled="$route.name === 'account:billing:profiles'"
            warn
          >
            Add billing information
          </the-button>
        </div>
      </template>
    </base-alert>

    <base-alert
      v-if="showCreditCardAlert"
      type="warn"
      sharp
    >
      <template #content> Add credit card to complete your account setup. </template>
      <template #action>
        <div class="flex space-x-8">
          <the-button
            @click="$router.push({ name: 'account:billing:cards' })"
            :disabled="$route.name === 'account:billing:cards'"
            warn
          >
            Add credit card
          </the-button>
        </div>
      </template>
    </base-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseAlert from '@/components/alert/BaseAlert'

export default {
  name: 'console-alerts',
  components: { BaseAlert },
  data() {
    return {
      showInactiveFeedAlert: false,
    }
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
      feedsByStoreId: 'feed/getFeedsByStoreId',
      billings: 'billing/getAccountBillings',
      cards: 'card/getCards',
      account: 'account/getAccount',
    }),
    feeds() {
      return !!this.store ? this.feedsByStoreId(this.store.id) : []
    },
    accountRoute() {
      // Some alerts should only be visible on "account level" pages
      return this.$route.name.startsWith('account')
    },
    showBillingAlert() {
      return this.accountRoute && this.billings.length < 1
    },
    showCreditCardAlert() {
      return (
        this.accountRoute &&
        !!this.store &&
        this.cards.length < 1 &&
        !this.account.enterprise &&
        !this.account.sales_led &&
        this.store.subscription_slug !== 'free'
      )
    },
  },
  watch: {
    feeds() {
      this.checkForInactiveFeeds()
    },
  },
  methods: {
    checkForInactiveFeeds() {
      this.showInactiveFeedAlert = false
      for (let feed of this.feeds)
        if (!feed.active) {
          this.showInactiveFeedAlert = true
          break
        }
    },
  },
  mounted() {
    this.checkForInactiveFeeds()
  },
}
</script>
