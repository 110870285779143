<template>
  <div class="space-y-4 max-h-68 overflow-y-auto base-scrollbar">
    <div v-if="!siteList.length">
      <p class="py-2 px-6">No sites found <span v-if="organization">in organization</span></p>
    </div>
    <div
      v-for="site in siteList"
      v-else
      :key="site.id"
      class="py-2 px-6 flex justify-between items-center transition duration-300 delay-50 ease-in-out"
      :class="[
        selectedSite?.value === site.value ? 'bg-bb-purple-100 hover:bg-bb-purple-100' : 'hover:bg-bb-purple-100',
      ]"
      @click="clickSite(site, organization)"
    >
      <div class="flex flex-row w-full">
        <p class="p2 truncate">
          {{ site.label }}
        </p>
        <p class="text-bb-text-secondary ml-2 whitespace-no-wrap">({{ site.site_organization_name }})</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SiteDropdownMenu',
  props: {
    sites: {
      type: Array,
      required: true,
    },
    organization: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      clickedsite: null,
    }
  },
  mounted() {},
  computed: {
    ...mapState('site', ['selectedSite']),
    siteList() {
      let sites = this.sites
      if (!this.organization) {
        return sites
      }
      if (this.organization) {
        sites = this.organization.sites.map(site => {
          const siteIntegration = site.site_integrations.map(integration => integration.data)
          return {
            label: site.name,
            value: site.id,
            url: site.url,
            site_integration: siteIntegration,
            site_organization_name: this.organization.label,
            site_organization_id: this.organization.value,
          }
        })
      }
      return sites
    },
  },
  methods: {
    ...mapActions({
      loadSelectedSite: 'site/loadSelectedSite',
    }),
    clickSite(site) {
      this.loadSelectedSite(site)
      this.$emit('close')
      this.$router.push({ name: 'dashboard' })
    },
  },
}
</script>
