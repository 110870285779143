<template>
  <transition
    :css="enableTransition"
    name="fade"
  >
    <template v-if="loading">
      <div
        class="c-loader__container"
        v-if="container"
        :style="containerStyles"
        style="z-index: 999"
      >
        <div class="c-loader__text-container">
          <div
            class="c-loader__text p1"
            v-if="text && text.length > 0"
          >
            {{ text }}
          </div>
          <div
            class="c-loader__subtext-container p3"
            v-if="subtext && subtext.length > 0"
          >
            {{ subtext }}
          </div>
        </div>
        <div
          class="c-loader"
          v-if="spinner"
          :style="loaderStyles"
        ></div>
      </div>
      <div
        class="c-loader"
        v-else
        :style="loaderStyles"
      ></div>
    </template>
  </transition>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    loading: {
      default: false,
    },
    container: {
      type: Boolean,
      default: false,
    },
    fullpage: {
      type: Boolean,
      default: false,
    },
    ignoreHeader: {
      type: Boolean,
      default: false,
    },
    spinner: {
      type: Boolean,
      default: true,
    },
    size: {
      default: 30,
    },
    text: {
      default: null,
    },
    subtext: {
      default: null,
    },
    containerBackgroundColor: {
      default: '#FFF',
    },
    enableTransition: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      headerHeight: 60,
    }
  },
  computed: {
    loaderStyles() {
      return {
        height: this.size + 'px',
        width: this.size + 'px',
      }
    },
    containerStyles() {
      let height = null

      if (this.ignoreHeader) height = '100vh'
      else if (this.fullpage) height = `calc(100vh - ${this.headerHeight}px)`

      return {
        backgroundColor: this.containerBackgroundColor,
        height,
      }
    },
  },
}
</script>

<style lang="scss">
@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.c-loader {
  display: inline-block;
  transition: all 0.3s;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-left: 2px solid transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-right: 2px solid transparent;
  animation: spinner 0.7s linear infinite;
  z-index: 192 !important;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__text-container {
    max-width: 400px;
    text-align: center;
    margin: 0 auto 15px;
  }

  &__subtext-container {
    max-width: 300px;
    margin: 0 auto;
  }
}
</style>
