<template>
  <div
    class="c-shape"
    :class="classes"
  >
    <div
      class="border-bb-pale-grey"
      :class="{ [slotCalcFullWidth]: !!$slots.header, 'border-b': !!$slots.header && !!$slots.default }"
    >
      <slot name="header" />
    </div>
    <div class="border-bb-pale-grey">
      <slot name="hero" />
    </div>

    <div
      :class="{ [slotCalcFullWidth]: !!$slots.default }"
      :style="contentStyle"
    >
      <slot name="default" />
    </div>

    <div
      class="border-bb-pale-grey"
      :class="{ [slotCalcFullWidth]: !!$slots.footer, 'border-t': $slots.footer && $slots.default }"
    >
      <slot name="footer" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'shape',
  props: {
    type: {
      type: String,
      default: 'regular',
      validator: val => ['regular', 'success', 'warn', 'error', 'information'].includes(val),
    },
    contentStyle: {
      type: String,
      default: '',
    },
    slotFullWidth: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'text-bb-dull-orange bg-custom-warn border-bb-dull-orange': this.type === 'warn',
        'text-bb-dull-green bg-custom-success border-bb-dull-green': this.type === 'success',
        'text-bb-dull-red bg-custom-error border-bb-dull-red': this.type === 'error',
        'text-bb-dull-blue bg-custom-information border-bb-dull-blue': this.type === 'information',
        'text-bb-dark-grey bg-white border-bb-pale-grey': this.type === 'regular',
        'border-0': this.noBorder,
      }
    },
    slotCalcFullWidth() {
      return this.slotFullWidth ? 'slot-padding-full' : 'slot-padding'
    },
  },
}
</script>
<style lang="scss">
.c-shape {
  font-weight: 500;
}

.slot-padding {
  padding: 16px;

  &-full {
    padding: 16px;

    &:first-of-type {
      max-width: 100%;
    }

    @screen s {
      padding: 24px;
    }
  }

  & :first-of-type {
    max-width: 1200px;
  }

  @screen s {
    padding: 24px;
  }
}

.bg-custom-error {
  background-color: #f9c7c6;
}

.bg-custom-information {
  background-color: #cbebfb;
}

.bg-custom-warn {
  background-color: #ffeebb;
}

.bg-custom-success {
  background-color: #effff2;
}
</style>
