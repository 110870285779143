const TYPE = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_NOTIFICATIONS_COUNT: 'SET_NOTIFICATIONS_COUNT',
  SET_NOTIFICATIONS_LOADING: 'SET_NOTIFICATIONS_LOADING',
  SET_NOTIFICATIONS_ERROR: 'SET_NOTIFICATIONS_ERROR',
}
export default {
  namespaced: true,
  state: {
    notifications: [],
    notificationsCount: 0,
    loadingNotifications: false,
    notificationsError: null,
  },
  getters: {
    siteNotifications(state) {
      return state.notifications
    },
    siteNotificationsCount(state) {
      return state.notificationsCount
    },
    loadingNotifications(state) {
      return state.loadingNotifications
    },
    notificationsError(state) {
      return state.notificationsError
    },
  },
  actions: {
    async fetchSiteNotifications({ commit }, { siteId, limit }) {
      commit(TYPE.SET_NOTIFICATIONS_LOADING, true)
      try {
        const response = await axios.get(`/common/site/${siteId}/notifications`, {
          params: { limit },
        })
        commit(TYPE.SET_NOTIFICATIONS, response.data.data)
        commit(TYPE.SET_NOTIFICATIONS_COUNT, response.data.count)
        commit(TYPE.SET_NOTIFICATIONS_LOADING, false)
      } catch (error) {
        commit(TYPE.SET_NOTIFICATIONS_ERROR, error.message)
        commit(TYPE.SET_NOTIFICATIONS_LOADING, false)
      }
    },
  },
  mutations: {
    [TYPE.SET_NOTIFICATIONS](state, notifications) {
      state.notifications = notifications
    },
    [TYPE.SET_NOTIFICATIONS_LOADING](state, loading) {
      state.loadingNotifications = loading
    },
    [TYPE.SET_NOTIFICATIONS_ERROR](state, error) {
      state.notificationsError = error
    },
    [TYPE.SET_NOTIFICATIONS_COUNT](state, count) {
      state.notificationsCount = count
    },
  },
}
