const state = {
  chartData: null,
  chartDataLoading: false,
  chartDataError: null,
}

const mutations = {
  SET_CHART_DATA(state, data) {
    state.chartData = data
  },
  SET_CHART_DATA_LOADING(state, loading) {
    state.chartDataLoading = loading
  },
  SET_CHART_DATA_ERROR(state, error) {
    state.chartDataError = error
  },
}

const actions = {
  async fetchChartData({ commit }, { siteId, start_date, end_date }) {
    commit('SET_CHART_DATA_LOADING', true)
    commit('SET_CHART_DATA_ERROR', null)
    try {
      const response = await axios.get(`/common/site/${siteId}/monthly-overview`, {
        params: { start_date, end_date },
      })
      commit('SET_CHART_DATA', response.data)
    } catch (error) {
      commit('SET_CHART_DATA_ERROR', error)
      commit('SET_CHART_DATA', null)
    } finally {
      commit('SET_CHART_DATA_LOADING', false)
    }
  },
}

const getters = {
  chartData: state => state.chartData,
  chartDataLoading: state => state.chartDataLoading,
  chartDataError: state => state.chartDataError,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
