const TYPE = {
  SET_FEED_FILTER: 'SET_FEED_FILTER',
  SET_FEED_FILTER_CALL: 'SET_FEED_FILTER_CALL',
  SET_FEED_FILTER_ERROR: 'SET_FEED_FILTER_ERROR',
  SET_CONTENT_API_FILTER: 'SET_CONTENT_API_FILTER',
  SET_CONTENT_API_FILTER_CALL: 'SET_CONTENT_API_FILTER_CALL',
  SET_CONTENT_API_FILTER_ERROR: 'SET_CONTENT_API_FILTER_ERROR',
}

const restructureData = data =>
  data.reduce((agg, item) => {
    agg[item['field']] = Object.entries(item['operators']).map(([key, val]) => ({
      slug: key,
      name: val,
    }))
    return agg
  }, {})

export default {
  namespaced: true,
  state: {
    feedFilter: {},
    loadFeedFilterCall: null,
    loadFeedFilterError: null,
    contentApiFilter: {},
    loadContentApiFilterCall: null,
    loadContentApiFilterError: null,
  },
  getters: {
    getFeedFilter(state) {
      return state.feedFilter
    },
    getContentFilter(state) {
      return state.contentApiFilter
    },
    getContentApiFilter(state) {
      return state.contentApiFilter
    },
    getFeedFilterFields(state) {
      return Object.keys(state.feedFilter)
    },
    getContentApiFilterFields(state) {
      return Object.keys(state.contentApiFilter)
    },
    loadingFilters(state) {
      return !!state.loadFeedFilterCall || !!state.loadContentApiFilterCall
    },
    getLoadFilterCalls(state) {
      return [state.loadFeedFilterCall, state.loadContentApiFilterCall]
    },
    getOperatorsByField: state => (field, filterType) => {
      if (Object.keys(state[filterType]).length === 0) return []
      return state[filterType][field]
    },
  },
  actions: {
    loadFilters({ commit }) {
      commit(TYPE.SET_FEED_FILTER_ERROR, null)
      commit(TYPE.SET_CONTENT_API_FILTER_ERROR, null)

      return Promise.allSettled([axios.get('campaign/filter/feed'), axios.get('campaign/filter/content-api')])
        .then(responses => {
          responses[0].status === 'rejected'
            ? commit(TYPE.SET_FEED_FILTER_ERROR, responses[0].reason)
            : commit(TYPE.SET_FEED_FILTER, restructureData(responses[0].value.data.data))
          responses[1].status === 'rejected'
            ? commit(TYPE.SET_CONTENT_API_FILTER_ERROR, responses[1].reason)
            : commit(TYPE.SET_CONTENT_API_FILTER, restructureData(responses[1].value.data.data))
        })
        .finally(() => {
          commit(TYPE.SET_FEED_FILTER_CALL, null)
          commit(TYPE.SET_CONTENT_API_FILTER_CALL, null)
        })
    },
    loadFiltersIfNeeded({ dispatch, getters }) {
      if (!!getters.loadingFilters) return Promise.all(getters.getLoadFilterCalls)

      if (getters.getFeedFilter.length > 0 && getters.getContentApiFilter.length > 0)
        return Promise.resolve([getters.getFeedFilter, getters.getContentApiFilter])

      return dispatch('loadFilters')
    },
  },
  mutations: {
    [TYPE.SET_FEED_FILTER_CALL](state, call) {
      state.loadFeedFilterCall = call
    },
    [TYPE.SET_FEED_FILTER](state, data) {
      state.feedFilter = data
    },
    [TYPE.SET_FEED_FILTER_ERROR](state, error) {
      state.loadFeedFilterError = error
    },
    [TYPE.SET_CONTENT_API_FILTER_CALL](state, call) {
      state.loadContentApiFilterCall = call
    },
    [TYPE.SET_CONTENT_API_FILTER](state, data) {
      state.contentApiFilter = data
    },
    [TYPE.SET_CONTENT_API_FILTER_ERROR](state, error) {
      state.loadContentApiFilterError = error
    },
  },
}
