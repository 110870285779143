<template>
  <div class="fixed w-full full-page bg-white">
    <div class="fixed w-full top-0 top-stripe" />
    <div
      class="transition-all duration-400 h-full mt-2"
      :class="{ 'pb-48 s:pb-16': isCookiesConsentShowing }"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FullPage',
  props: {
    shinyBackground: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      cookiesAccepted: 'isCookiesAccepted',
    }),
    isSignup() {
      return this.$route.name.includes('signup')
    },
    isCookiesConsentShowing() {
      return !this.cookiesAccepted
    },
    isProduction() {
      return window.location.host.startsWith('app.brightbid')
    },
  },
  mounted() {
    this.$gtm.enable(this.isProduction)
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    // VERY HACKY FIX WATCH OUT FOR REDIRECT LOOPS
    if (this.$route.path === '/') {
      this.$router.push({ name: 'signin' })
    }
  },
}
</script>
<style lang="scss">
.full-page {
  height: 100vh;
}

.bg-shiny {
  background: url('/content/images/shiny-background.svg') top center no-repeat;
  background-position-y: -200px;
  background-position-x: center;
  background-size: 700px;

  @screen s {
    background-position-y: -280px;
    background-size: 1000px;
  }
}

.top-stripe {
  height: 8px;
  background: linear-gradient(90deg, #3e4552 0.01%, #6366fa 35.66%, #84fae4 65.91%, #677fea 103.72%);
}
</style>
