const BREAKPOINT = {
  XXS: 320,
  XS: 375,
  S: 480,
  SM: 667,
  MD: 769,
  ML: 812,
  LG: 1024,
  XL: 1200,
}

const HEADER = {
  height: 60,
}

const TOAST = {
  duration: 4000,
}

const COLOR = {
  approved: '#64e19a',
  pending: '#53abcb',
  inactive: '#403969',
  disapproved: '#f5516e',
  'bb-neon-purple': '#6E19F0',
  'bb-neon-green': '#3AE995',
  'bb-neon-pink': '#F04BA5',
}

const CAMPAIGN = {
  ALL_CAMPAIGNS: -1,
}

export { BREAKPOINT, HEADER, COLOR, TOAST, CAMPAIGN }
