<template>
  <div id="app">
    <transition
      name="page-fade"
      mode="out-in"
    >
      <router-view />
    </transition>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import '@/configs/google-client'

export default {
  name: 'App',
  metaInfo() {
    if (this.isProduction) {
      return null
    }

    return { meta: [{ name: 'robots', content: 'noindex' }] }
  },
  computed: {
    isProduction() {
      // test and stage starts with 'subdomain.'
      return window.location.host.startsWith('bidbrain')
    },
  },
  async mounted() {
    /**
     * Fetch me on startup
     */
    this.$router.onReady(() => {
      const requiresAuth = this.$route.matched.some(match => match.meta.requiresAuth)
      if (requiresAuth) {
        this.fetchMe()
      }
    })
  },
  methods: {
    ...mapActions({
      fetchMe: 'auth/loadMe',
    }),
  },
}
</script>
<style lang="scss">
@import 'src/assets/style/base';
</style>
