import Vue from 'vue'
import PageTitle from '@/components/shared/PageTitle'
import BaseModal from '@/components/modals/base/BaseModal'
import TheButton from '@/components/btn/TheButton'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import MergeButtonSquare from '@/components/btn/MergeButtonSquare'
import TheLogo from '@/components/shared/logo/TheLogo'
import TheGradientBrain from '@/components/shared/logo/TheGradientBrain'
import Shape from '@/components/base/Shape'
import BbIcon from '@/components/shared/bbIcon.vue'
import IcPlus from 'vue-material-design-icons/Plus'

Vue.component('page-title', PageTitle)
Vue.component('BaseModal', BaseModal)
Vue.component('the-button', TheButton)
Vue.component('MergeButtonRound', MergeButtonRound)
Vue.component('MergeButtonSquare', MergeButtonSquare)
Vue.component('the-logo', TheLogo)
Vue.component('the-gradient-brain', TheGradientBrain)
Vue.component('shape', Shape)
Vue.component('ic-plus', IcPlus)
Vue.component('bb-icon', BbIcon)
