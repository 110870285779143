exports.readDate = (key, fallback) => {
  const date = sessionStorage.getItem(key)
  if (date) return new Date(date)
  return fallback
}

exports.writeDate = (key, date) => (date ? sessionStorage.setItem(key, date) : sessionStorage.removeItem(key))

exports.readString = (key, fallback) => sessionStorage.getItem(key) || fallback

exports.writeString = (key, str) => (str ? sessionStorage.setItem(key, str) : sessionStorage.removeItem(key))

exports.KEY = {
  START_DATE: 'dates_start_date',
  END_DATE: 'dates_end_date',
  COMPARE_START_DATE: 'dates_compare_start_date',
  COMPARE_END_DATE: 'dates_compare_end_date',
  PRESET: 'dates_preset',
  COMPARE_PRESET: 'dates_compare_preset',
}
