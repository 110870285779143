import { HUB_ENVIRONMENT } from '@/core/constants'

const TYPE = {
  SET_HUB_ENVIRONMENT: 'SET_HUB_ENVIRONMENT',
}
export default {
  namespaced: true,
  state: {
    hubEnvironment: HUB_ENVIRONMENT.PRODUCTION,
  },
  getters: {
    isProductionEnvironment: state => {
      const environment = state.hubEnvironment ?? HUB_ENVIRONMENT.PRODUCTION ?? process.env.VUE_APP_ENVIRONMENT
      return environment === HUB_ENVIRONMENT.PRODUCTION
    },
  },
  actions: {
    setHubEnvironment({ commit }) {
      const environment = process.env.VUE_APP_ENVIRONMENT
      commit(TYPE.SET_HUB_ENVIRONMENT, environment)
    },
  },
  mutations: {
    [TYPE.SET_HUB_ENVIRONMENT](state, environment) {
      if (!environment) {
        state.hubEnvironment = HUB_ENVIRONMENT.PRODUCTION
      }
      state.hubEnvironment = environment
    },
  },
}
