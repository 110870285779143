import { normalize } from '@/utils/util'

const TYPE = {
  SET_ITEMS: 'SET_ITEMS',
  SET_ITEM: 'SET_ITEM',
  SET_LOAD_CALL: 'SET_LOAD_CALL',
  SET_LOAD_ERROR: 'SET_LOAD_ERROR',
  SET_CREATE_CALL: 'SET_CREATE_CALL',
  SET_CREATE_ERROR: 'SET_CREATE_ERROR',
  SET_UPDATE_CALL: 'SET_UPDATE_CALL',
  SET_UPDATE_ERROR: 'SET_UPDATE_ERROR',
  SET_EVENT_LISTENER: 'SET_EVENT_LISTENER',
  REMOVE_EVENT_LISTENER: 'REMOVE_EVENT_LISTENER',
}

export default {
  namespaced: true,
  state: {
    items: {},
    loadCalls: {},
    loadErrors: {},
    createCalls: {},
    createErrors: {},
    updateCalls: {},
    updateErrors: {},
    eventListeners: {},
  },
  getters: {
    getBudgetsByStoreId: state => storeId => {
      if (!state.items[storeId]) return []
      return Object.values(state.items[storeId]).sort(a => (a.unlimited_budget ? 1 : -1))
    },
    getStoreBudgetById: state => (storeId, budgetId) => {
      if (!state.items[storeId]) return []
      return state.items[storeId][budgetId]
    },
    isBudgetsLoadingByStoreId: state => storeId => {
      return !!state.loadCalls[storeId]
    },
    getLoadBudgetErrorByStoreId: state => storeId => {
      return state.loadErrors[storeId]
    },
    getCreateBudgetErrorByStoreId: state => storeId => {
      return state.createErrors[storeId]
    },
    getUpdateBudgetErrorByStoreId: state => (storeId, budgetId) => {
      return state.updateErrors[storeId] ? state.updateErrors[storeId][budgetId] : undefined
    },
    getBudgetLoadCallByStoreId: state => storeId => {
      return state.loadCalls[storeId]
    },
    isCreatingBudgetByStoreId: state => storeId => {
      return !!state.createCalls[storeId]
    },
    isUpdatingBudgetByStoreId: state => (storeId, budgetId) => {
      return !!state.updateCalls[storeId] && !!state.updateCalls[storeId][budgetId]
    },
  },
  actions: {
    registerEventListener({ commit }, { storeId, listenerId, listener }) {
      commit(TYPE.SET_EVENT_LISTENER, { storeId, listenerId, listener })
    },
    unregisterEventListener({ commit }, { storeId, listenerId }) {
      commit(TYPE.REMOVE_EVENT_LISTENER, { storeId, listenerId })
    },
    _sendEvent({ state }, { event = 'budget_created', data: { storeId, budget } }) {
      if (state.eventListeners[storeId]?.length > 0)
        for (const listener of state.eventListeners[storeId]) listener({ event, data: budget })
    },
    createBudget({ commit, dispatch }, { storeId, formData }) {
      commit(TYPE.SET_CREATE_ERROR, { storeId, error: null })

      const call = axios
        .post(`/store/${storeId}/budget`, formData)
        .then(({ data }) => {
          commit(TYPE.SET_ITEM, { storeId, data: data.data })
          dispatch('_sendEvent', { storeId, data: { budget: data.data } })
          return data.data
        })
        .catch(e => commit(TYPE.SET_CREATE_ERROR, { storeId, error: e }))
        .finally(() => commit(TYPE.SET_CREATE_CALL, { storeId, call: null }))

      commit(TYPE.SET_CREATE_CALL, { storeId, call })

      return call
    },
    updateBudget({ commit }, { storeId, budgetId, formData }) {
      commit(TYPE.SET_UPDATE_ERROR, { storeId, budgetId, error: null })

      const call = axios
        .patch(`/store/${storeId}/budget/${budgetId}`, formData)
        .then(({ data }) => {
          commit(TYPE.SET_ITEM, { storeId, data: data.data })
          return data.data
        })
        .catch(e => commit(TYPE.SET_UPDATE_ERROR, { storeId, budgetId, error: e }))
        .finally(() => commit(TYPE.SET_UPDATE_CALL, { storeId, budgetId, call: null }))

      commit(TYPE.SET_UPDATE_CALL, { storeId, budgetId, call })

      return call
    },
    loadBudgetsByStoreId({ commit }, storeId) {
      commit(TYPE.SET_LOAD_ERROR, { storeId, error: null })

      const call = axios
        .get(`/store/${storeId}/budget`)
        .then(({ data }) => commit(TYPE.SET_ITEMS, { storeId, data: normalize(data.data) }))
        .catch(e => commit(TYPE.SET_LOAD_ERROR, { storeId, error: e }))
        .finally(() => commit(TYPE.SET_LOAD_CALL, { storeId, call: null }))

      commit(TYPE.SET_LOAD_CALL, { storeId, call })

      return call
    },
    loadBudgetsByStoreIdIfNeeded({ dispatch, getters }, storeId) {
      if (!!getters.getBudgetLoadCallByStoreId(storeId)) return getters.getBudgetLoadCallByStoreId(storeId)

      if (getters.getBudgetsByStoreId(storeId).length > 0) return Promise.resolve(getters.getBudgetsByStoreId(storeId))

      return dispatch('loadBudgetsByStoreId', storeId)
    },
    clearCreateErrorByStoreId({ commit }, storeId) {
      commit(TYPE.SET_CREATE_ERROR, { storeId, error: null })
    },
    clearUpdateErrorByStoreId({ commit }, storeId, budgetId) {
      commit(TYPE.SET_UPDATE_ERROR, { storeId, budgetId, error: null })
    },
  },
  mutations: {
    [TYPE.SET_UPDATE_CALL](state, { storeId, budgetId, call }) {
      state.updateCalls = {
        ...state.updateCalls,
        [storeId]: {
          ...state.updateCalls[storeId],
          [budgetId]: call,
        },
      }
    },
    [TYPE.SET_UPDATE_ERROR](state, { storeId, budgetId, error }) {
      state.updateErrors = {
        ...state.updateErrors,
        [storeId]: {
          ...state.updateErrors[storeId],
          [budgetId]: error,
        },
      }
    },
    [TYPE.SET_CREATE_CALL](state, { storeId, call }) {
      state.createCalls = {
        ...state.createCalls,
        [storeId]: call,
      }
    },
    [TYPE.SET_LOAD_CALL](state, { storeId, call }) {
      state.loadCalls = {
        ...state.loadCalls,
        [storeId]: call,
      }
    },
    [TYPE.SET_LOAD_ERROR](state, { storeId, error }) {
      state.loadErrors = {
        ...state.loadErrors,
        [storeId]: error,
      }
    },
    [TYPE.SET_CREATE_ERROR](state, { storeId, error }) {
      state.createErrors = {
        ...state.createErrors,
        [storeId]: error,
      }
    },
    [TYPE.SET_ITEMS](state, { storeId, data }) {
      state.items = {
        ...state.items,
        [storeId]: data,
      }
    },
    [TYPE.SET_ITEM](state, { storeId, data }) {
      state.items = {
        ...state.items,
        [storeId]: {
          ...state.items[storeId],
          [data.id]: data,
        },
      }
    },
    [TYPE.SET_EVENT_LISTENER](state, { storeId, listenerId, listener }) {
      state.eventListeners = {
        ...state.eventListeners,
        [storeId]: {
          ...state.eventListeners[storeId],
          [listenerId]: listener,
        },
      }
    },
    [TYPE.REMOVE_EVENT_LISTENER](state, { storeId, listenerId }) {
      let listeners = { ...state.eventListeners }
      if (!!listeners[storeId][listenerId]) delete listeners[storeId][listenerId]
      state.eventListeners = { ...listeners }
    },
  },
}
