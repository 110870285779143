<template>
  <div class="c-base-modal p-4 sm:p-8">
    <header
      v-if="!hideHeader"
      class="pb-10 text-center"
    >
      <slot name="header"></slot>
    </header>
    <section>
      <slot></slot>
    </section>
    <footer :class="{ 'pt-4 sm:pt-8': !!$slots.footer }">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
/* Override vue-js-modal overlay */
.vm--container {
  background: rgba(61, 58, 92, 0.2) !important;
}
</style>
