var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"router-link-base flex flex-row justify-between mr-3",class:{
      'router-link-base-active': _vm.$route.name.startsWith(_vm.menuItem.routeLevel),
      'router-link-base-hover': !_vm.$route.name.startsWith(_vm.menuItem.routeLevel),
    },on:{"click":function($event){_vm.subMenuOpen = !_vm.subMenuOpen}}},[_c('span',{staticClass:"flex",attrs:{"role":"link"}},[(_vm.menuItem.bbIcon)?_c('bb-icon',{staticClass:"nav-icon",attrs:{"icon":_vm.menuItem.icon,"title":_vm.menuItem.name}}):_c(_vm.menuItem.icon,{tag:"component",staticClass:"nav-icon",attrs:{"size":24}}),_c('span',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(_vm.menuItem.name)+" ")])],1),_c('ic-chevron',{staticClass:"transform",class:{ 'rotate-180': _vm.subMenuOpen },attrs:{"size":18}})],1),_c('div',{staticClass:"ml-5 transition-all duration-300 ease-in-out transform overflow-hidden",class:{
      'max-h-0': !_vm.subMenuOpen,
      'max-h-subMenu': _vm.subMenuOpen,
    }},[_c('div',{staticClass:"grid grid-cols-1"},_vm._l((_vm.menuItem.subMenu),function(subMenuItem){return _c('router-link',{key:subMenuItem.link,staticClass:"nav-submenu__item p2",class:{ 'disabled hidden': subMenuItem.disabled },attrs:{"active-class":"active","to":{ name: subMenuItem.link }}},[_c('span',{staticClass:"flex flex-col font-medium"},[_vm._v(" "+_vm._s(subMenuItem.name)+" "),(subMenuItem.comingSoon)?_c('span',{staticClass:"italic font-normal"},[_vm._v("(Coming Soon)")]):_vm._e()])])}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }