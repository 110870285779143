import { normalize } from '@/utils/util'
import { campaignTransformer } from '@/core/campaign/campaign-transformer'

const TYPE = {
  SET_ITEMS: 'SET_ITEMS',
  SET_ITEM: 'SET_ITEM',
  SET_LOAD_CALL: 'SET_LOAD_CALL',
  SET_LOAD_ERROR: 'SET_LOAD_ERROR',
  SET_CREATE_CALL: 'SET_CREATE_CALL',
  SET_CREATE_ERROR: 'SET_CREATE_ERROR',
  SET_UPDATE_CALL: 'SET_UPDATE_CALL',
  SET_UPDATE_ERROR: 'SET_UPDATE_ERROR',
  SET_CAMPAIGN_NOTIFICATION: 'SET_CAMPAIGN_NOTIFICATION',
  SET_COMPLETE_COUNT: 'SET_COMPLETE_COUNT',
}

export default {
  namespaced: true,
  state: {
    items: {},
    loadCalls: {},
    loadErrors: {},
    createCalls: {},
    createErrors: {},
    updateCalls: {},
    updateErrors: {},
    newCampaignNotification: {},
    completeCount: {},
  },
  getters: {
    getCampaignsByStoreId: state => storeId => {
      if (!state.items[storeId]) return []
      return Object.values(state.items[storeId])
    },
    isAnyCampaignLoading(state) {
      return (
        Object.values(state.loadCalls).length > 0 ||
        Object.values(state.updateCalls).length > 0 ||
        Object.values(state.createCalls).length > 0
      )
    },
    isCampaignsLoadingByStoreId: state => storeId => {
      return !!state.loadCalls[storeId]
    },
    getLoadCampaignErrorByStoreId: state => storeId => {
      return state.loadErrors[storeId]
    },
    getCampaignLoadCallByStoreId: state => storeId => {
      return state.loadCalls[storeId]
    },
    isCreatingCampaignByStoreId: state => storeId => {
      return !!state.createCalls[storeId]
    },
    isUpdatingCampaignByStoreId: state => storeId => {
      return !!state.updateCalls[storeId]
    },
    getUpdateCampaignErrorByCampaignId: state => campaignId => {
      return state.updateErrors[campaignId]
    },
    getCreateCampaignErrorByStoreId: state => storeId => {
      return state.createErrors[storeId]
    },
    getNewCampaignNotificationByStoreId: state => storeId => {
      return state.newCampaignNotification[storeId]
    },
    getCampaignsCompletedCountByStoreId: state => storeId => {
      return state.completeCount[storeId] || 0
    },
  },
  actions: {
    createCampaign({ commit }, { storeId, formData }) {
      commit(TYPE.SET_CREATE_ERROR, { storeId, error: null })

      let call = axios
        .post(`/store/${storeId}/campaign`, formData)
        .then(({ data }) => {
          commit(TYPE.SET_ITEM, { storeId, data: campaignTransformer(data.data) })
          return data.data
        })
        .catch(e => commit(TYPE.SET_CREATE_ERROR, { storeId, error: e }))
        .finally(() => commit(TYPE.SET_CREATE_CALL, { storeId, call: null }))

      commit(TYPE.SET_CREATE_CALL, { storeId, call })

      return call
    },
    updateCampaign({ commit, dispatch }, { storeId, campaignId, data }) {
      commit(TYPE.SET_UPDATE_ERROR, { campaignId, error: null })

      const call = axios
        .put(`/store/${storeId}/campaign/${campaignId}`, data)
        .then(_ => dispatch('loadCampaignsByStoreId', storeId))
        .catch(e => commit(TYPE.SET_UPDATE_ERROR, { campaignId, error: e }))
        .finally(() => commit(TYPE.SET_UPDATE_CALL, { storeId, call: null }))

      commit(TYPE.SET_UPDATE_CALL, { storeId, call })

      return call
    },
    loadCampaignsByStoreId({ commit }, storeId) {
      commit(TYPE.SET_LOAD_ERROR, { storeId, error: null })

      const call = axios
        .get(`/store/${storeId}/campaign`)
        .then(({ data }) => {
          let count = 0
          for (const campaign of data.data) if (campaign.status === 'live') count++

          commit(TYPE.SET_ITEMS, {
            storeId,
            data: normalize(data.data.map(campaignTransformer)),
          })
          commit(TYPE.SET_COMPLETE_COUNT, { storeId, count })
          return data.data
        })
        .catch(e => commit(TYPE.SET_LOAD_ERROR, { storeId, error: e }))
        .finally(() => commit(TYPE.SET_LOAD_CALL, { storeId, call: null }))

      commit(TYPE.SET_LOAD_CALL, { storeId, call })

      return call
    },
    loadCampaignsByStoreIdIfNeeded({ dispatch, getters }, storeId) {
      if (!!getters.getCampaignLoadCallByStoreId(storeId)) return getters.getCampaignLoadCallByStoreId(storeId)

      if (getters.getCampaignsByStoreId(storeId).length > 0) {
        return Promise.resolve(getters.getCampaignsByStoreId(storeId))
      }

      return dispatch('loadCampaignsByStoreId', storeId)
    },
    setNotificationByStoreId({ commit }, { storeId, value }) {
      commit(TYPE.SET_CAMPAIGN_NOTIFICATION, { storeId, value })
    },
  },
  mutations: {
    [TYPE.SET_CREATE_CALL](state, { storeId, call }) {
      state.createCalls = {
        ...state.createCalls,
        [storeId]: call,
      }
    },
    [TYPE.SET_CREATE_ERROR](state, { storeId, error }) {
      state.createErrors = {
        ...state.createErrors,
        [storeId]: error,
      }
    },
    [TYPE.SET_UPDATE_CALL](state, { storeId, call }) {
      state.updateCalls = {
        ...state.updateCalls,
        [storeId]: call,
      }
    },
    [TYPE.SET_LOAD_CALL](state, { storeId, call }) {
      state.loadCalls = {
        ...state.loadCalls,
        [storeId]: call,
      }
    },
    [TYPE.SET_LOAD_ERROR](state, { storeId, error }) {
      state.loadErrors = {
        ...state.loadErrors,
        [storeId]: error,
      }
    },
    [TYPE.SET_ITEMS](state, { storeId, data }) {
      state.items = {
        ...state.items,
        [storeId]: data,
      }
    },
    [TYPE.SET_UPDATE_ERROR](state, { campaignId, error }) {
      state.updateErrors = {
        ...state.updateErrors,
        [campaignId]: error,
      }
    },
    [TYPE.SET_ITEM](state, { storeId, data }) {
      state.items = {
        ...state.items,
        [storeId]: {
          ...state.items[storeId],
          [data.id]: data,
        },
      }
    },
    [TYPE.SET_CAMPAIGN_NOTIFICATION](state, { storeId, value }) {
      state.newCampaignNotification = {
        ...state.newCampaignNotification,
        [storeId]: value,
      }
    },
    [TYPE.SET_COMPLETE_COUNT](state, { storeId, count }) {
      state.completeCount = {
        ...state.completeCount,
        [storeId]: count,
      }
    },
  },
}
