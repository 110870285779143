<template>
  <div>
    <div
      class="router-link-base flex flex-row justify-between mr-3"
      :class="{
        'router-link-base-active': $route.name.startsWith(menuItem.routeLevel),
        'router-link-base-hover': !$route.name.startsWith(menuItem.routeLevel),
      }"
      @click="subMenuOpen = !subMenuOpen"
    >
      <span
        role="link"
        class="flex"
      >
        <bb-icon
          v-if="menuItem.bbIcon"
          :icon="menuItem.icon"
          class="nav-icon"
          :title="menuItem.name"
        />
        <component
          :is="menuItem.icon"
          v-else
          class="nav-icon"
          :size="24"
        />
        <span class="my-auto">
          {{ menuItem.name }}
        </span>
      </span>
      <ic-chevron
        :size="18"
        class="transform"
        :class="{ 'rotate-180': subMenuOpen }"
      />
    </div>
    <div
      class="ml-5 transition-all duration-300 ease-in-out transform overflow-hidden"
      :class="{
        'max-h-0': !subMenuOpen,
        'max-h-subMenu': subMenuOpen,
      }"
    >
      <div class="grid grid-cols-1">
        <router-link
          v-for="subMenuItem in menuItem.subMenu"
          :key="subMenuItem.link"
          class="nav-submenu__item p2"
          :class="{ 'disabled hidden': subMenuItem.disabled }"
          active-class="active"
          :to="{ name: subMenuItem.link }"
        >
          <span class="flex flex-col font-medium">
            {{ subMenuItem.name }}
            <span
              v-if="subMenuItem.comingSoon"
              class="italic font-normal"
              >(Coming Soon)</span
            >
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import IcCog from 'vue-material-design-icons/Cog.vue'
import IcChevron from 'vue-material-design-icons/ChevronDown.vue'
import BbIcon from '@/components/shared/bbIcon.vue'

export default {
  name: 'SidebarMenuGroupLink',
  components: { BbIcon, IcChevron, IcCog },
  props: ['menuItem'],
  data() {
    return {
      subMenuOpen: false,
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        if (to.name.startsWith(this.menuItem.routeLevel)) {
          this.subMenuOpen = true
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}
</style>
