export default async function auditAuth({ to, next, store }) {
  const storageUser = localStorage.getItem('userAuditGoogleCredentials')
  if (store.getters['auth/userAuditGoogleCredentials'] === null && storageUser !== null) {
    await store.dispatch('auth/SET_USER_AUDIT_GOOGLE_CREDENTIALS', JSON.parse(storageUser))
  }
  const user = store.getters['auth/userAuditGoogleCredentials']

  if (user === null) {
    return next({
      name: 'audit/signin',
      params: {
        nextUrl: to.fullPath,
      },
    })
  }

  return next()
}
