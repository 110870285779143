const TYPE = {
  SET_LOAD_CALL: 'SET_LOAD_CALL',
  SET_ERROR: 'SET_ERROR',
  SET_ITEM: 'SET_ITEM',
  SET_ITEM_BILLING_ID: 'SET_ITEM_BILLING_ID',
}

export default {
  namespaced: true,
  state: {
    account: {},
    loadCall: null,
    accountError: null,
  },
  getters: {
    getAccount(state) {
      return state.account
    },
    getAccountId(state) {
      return state.account.id
    },
    isAccountLoading(state) {
      return !!state.loadCall
    },
    getLoadCall(state) {
      return state.loadCall
    },
    getAccountError(state) {
      return state.accountError
    },
  },
  actions: {
    loadAccount({ commit, rootGetters }) {
      commit(TYPE.SET_ERROR, null)
      const user = rootGetters['auth/user']
      const accountId = user?.account_id || user?.account?.id
      if (!accountId) {
        return Promise.resolve()
      }

      const call = axios
        .get(`/account/${accountId}`)
        .then(({ data }) => {
          commit(TYPE.SET_ITEM, data.data)
        })
        .catch(e => commit(TYPE.SET_ERROR, e))
        .finally(() => commit(TYPE.SET_LOAD_CALL, null))

      commit(TYPE.SET_LOAD_CALL, call)
      return call
    },
    loadAccountIfNeeded({ dispatch, getters }) {
      if (!!getters.loadCall) return getters.loadCall

      if (!!getters.getAccount) return Promise.resolve(getters.getAccount)

      return dispatch('loadAccount')
    },
    setAccountBillingId({ commit, getters }, billing_id) {
      if (!!getters.getAccount) commit(TYPE.SET_ITEM_BILLING_ID, billing_id)
    },
    clear({ commit }) {
      commit(TYPE.SET_ITEM, null)
      commit(TYPE.SET_ERROR, null)
      commit(TYPE.SET_LOAD_CALL, false)
    },
  },
  mutations: {
    [TYPE.SET_LOAD_CALL](state, call) {
      state.loadCall = call
    },
    [TYPE.SET_ERROR](state, error) {
      state.accountError = error
    },
    [TYPE.SET_ITEM](state, account) {
      state.account = account
    },
    [TYPE.SET_ITEM_BILLING_ID](state, billing_id) {
      state.account = { ...state.account, billing_id }
    },
  },
}
