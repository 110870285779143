import { normalize } from '@/utils/util'

const TYPE = {
  SET_SUBSCRIPTIONS_LOAD_CALL: 'SET_SUBSCRIPTIONS_LOAD_CALL',
  SET_SUBSCRIPTIONS_ERROR: 'SET_SUBSCRIPTIONS_ERROR',
  SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS',
}

const READABLE_PRICING = subscription => {
  switch (true) {
    case subscription.slug === 'free':
      return 'Free'
    case subscription.slug === 'enterprise':
      return 'Custom'
    case !!subscription.pricing.license_fee && !!subscription.pricing.ad_spend:
      return {
        SEK: `${subscription.pricing.license_fee.SEK} SEK per month, ${subscription.pricing.ad_spend}% adspend`,
        EUR: `€${subscription.pricing.license_fee.EUR} per month, ${subscription.pricing.ad_spend}% adspend`,
      }
    case !!subscription.pricing.license_fee:
      return {
        SEK: `${subscription.pricing.license_fee.SEK} SEK per month`,
        EUR: `€${subscription.pricing.license_fee.EUR} per month`,
      }
    default:
      return 'Custom'
  }
}

const CTA_TEXT = slug => {
  switch (true) {
    case slug === 'free':
      return 'Try for free'
    case slug === 'pro':
      return 'Subscribe'
    case slug === 'pro_plus':
      return 'Subscribe'
    case slug === 'enterprise':
      return 'Contact us'
    default:
      return 'Select'
  }
}

const addTexts = sub => ({
  ...sub,
  cta_text: CTA_TEXT(sub.slug),
  pricing: {
    ...sub.pricing,
    readable: READABLE_PRICING(sub),
  },
})

export default {
  namespaced: true,
  state: {
    subscriptions: {},
    subscriptionsCall: null,
    subscriptionsError: null,
  },
  getters: {
    getSubscriptions(state) {
      return Object.values(state.subscriptions)
    },
    getSubscriptionBySlug: state => slug => {
      return state.subscriptions[slug]
    },
    isSubscriptionsLoading(state) {
      return !!state.subscriptionsCall
    },
    getSubscriptionsError(state) {
      return state.subscriptionsError
    },
    subscriptionsCall(state) {
      return state.subscriptionsCall
    },
  },
  actions: {
    loadSubscriptions({ commit }) {
      commit(TYPE.SET_SUBSCRIPTIONS_ERROR, null)

      const call = axios
        .get('/subscription')
        .then(({ data }) => commit(TYPE.SET_SUBSCRIPTIONS, normalize(data.data.map(addTexts), 'slug')))
        .catch(e => commit(TYPE.SET_SUBSCRIPTIONS_ERROR, e))
        .finally(() => commit(TYPE.SET_SUBSCRIPTIONS_LOAD_CALL, null))

      commit(TYPE.SET_SUBSCRIPTIONS_LOAD_CALL, call)

      return call
    },
    loadSubscriptionsIfNeeded({ dispatch, getters }) {
      if (!!getters.subscriptionsCall) return getters.subscriptionsCall

      if (getters.getSubscriptions.length > 0) return Promise.resolve(getters.getSubscriptions)

      return dispatch('loadSubscriptions')
    },
  },
  mutations: {
    [TYPE.SET_SUBSCRIPTIONS_LOAD_CALL](state, call) {
      state.subscriptionsLoading = call
    },
    [TYPE.SET_SUBSCRIPTIONS_ERROR](state, error) {
      state.subscriptionsError = error
    },
    [TYPE.SET_SUBSCRIPTIONS](state, subscriptions) {
      state.subscriptions = subscriptions
    },
  },
}
