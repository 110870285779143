<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 26.6666H18.6666V5.33331H13.3333V26.6666ZM5.33325 26.6666H10.6666V16H5.33325V26.6666ZM21.3333 12V26.6666H26.6666V12H21.3333Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcCloud',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
