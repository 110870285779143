const TYPE = {
  LOAD_ORGANIZATION_SITE: 'LOAD_ORGANIZATION_SITE',
  LOAD_SELECTED_SITE: 'LOAD_SELECTED_SITE',
  SET_SITE_DETAILS: 'SET_SITE_DETAILS',
  SET_SITE_PERFORMANCE: 'SET_SITE_PERFORMANCE',
  SET_SITE_PERFORMANCE_MONTHLY: 'SET_SITE_PERFORMANCE_MONTHLY',
  SET_SITE_CAMPAIGNS_PERFORMANCE: 'SET_SITE_CAMPAIGNS_PERFORMANCE',
  SET_SITE_CAMPAIGNS_PERFORMANCE_MONTHLY: 'SET_SITE_CAMPAIGNS_PERFORMANCE_MONTHLY',
  SET_SITE_WELCOME_MESSAGE: 'SET_SITE_WELCOME_MESSAGE',
  SET_SITE_BIDDING_UPDATES: 'SET_SITE_BIDDING_UPDATES',
  SET_SITE_CAMPAIGNS_PERFORMANCE_FUNNEL: 'SET_SITE_CAMPAIGNS_PERFORMANCE_FUNNEL',
  SITE_WELCOME_MESSAGE_ERROR: 'SITE_WELCOME_MESSAGE_ERROR',
  SET_SITE_MANAGED_CAMPAIGNS_IDENTIFIER: 'SET_SITE_MANAGED_CAMPAIGNS_IDENTIFIER',
}

const localStorageSite = 'selectedSite'

export default {
  namespaced: true,
  state: {
    organizationSite: null,
    selectedSite: JSON.parse(localStorage.getItem(localStorageSite)) || null,
    siteDetails: null,
    sitePerformance: null,
    sitePerformanceMonthly: [],
    siteCampaignsPerformance: [],
    siteCampaignsPerformanceMonthly: [],
    siteWelcomeMessage: null,
    siteBiddingUpdates: [],
    siteCampaignsPerformanceFunnel: [],
    siteWelcomeMessageError: null,
    managedCampaignsIdentifier: null,
  },
  getters: {
    organizationSiteRows(state) {
      if (!state.organizationSite) return []
      return state.organizationSite.map(item => ({
        id: item.id,
        google_ads_customer_id: item.site_integrations
          .map(integration => integration.data?.google_ads_customer_id)
          .filter(Number)
          .join(', '),
        site: item.name,
        isActive: item.active,
        url: item.url,
        site_integrations: item.site_integrations.map(integration => integration.integration_slug),
        description: item.description,
        target: item.target,
        status: item.status,
      }))
    },
    selectedSite(state) {
      return state.selectedSite
    },
    sitePerformance(state) {
      return state.sitePerformance
    },
    sitePerformanceMonthly(state) {
      return state.sitePerformanceMonthly
    },
    siteCampaignsPerformance(state) {
      return state.siteCampaignsPerformance
    },
    siteCampaignsPerformanceMonthly(state) {
      return state.siteCampaignsPerformanceMonthly
    },
    siteWelcomeMessage(state) {
      return state.siteWelcomeMessage
    },
    siteBiddingUpdates(state) {
      return state.siteBiddingUpdates
    },
    siteWelcomeMessageError(state) {
      return state.siteWelcomeMessageError
    },
    siteManagedCampaignsIndetifier(state) {
      return state.managedCampaignsIdentifier
    },
  },
  actions: {
    loadOrganizationSiteList({ commit }, data) {
      commit(TYPE.LOAD_ORGANIZATION_SITE, data)
    },
    loadSelectedSite({ commit, dispatch }, data) {
      commit(TYPE.LOAD_SELECTED_SITE, data)
      dispatch('fetchSiteDetails', data.value)
      return dispatch('clearCompetitorMonitoring', null, { root: true })
    },
    async fetchSiteDetails({ commit }, siteId) {
      try {
        const response = await axios.get(`/common/site/${siteId}`)
        commit(TYPE.SET_SITE_DETAILS, response.data)
      } catch (error) {
        console.error('Error fetching site details:', error)
      }
    },
    async fetchSitePerformanceData(_, params) {
      if (!params.siteId) {
        throw new Error('No siteId provided')
      }

      const { data } = await axios.get(`common/site/${params.siteId}/performance`, {
        params: {
          start_date: params.startDate,
          end_date: params.endDate,
          compare_start_date: params.compareStartDate,
          compare_end_date: params.compareEndDate,
        },
      })

      if (!data || !data[0]) {
        throw new Error('No data received')
      }

      return data[0]
    },
    async fetchSitePerformance({ commit, dispatch }, { siteId, startDate, endDate, compareStartDate, compareEndDate }) {
      try {
        let sitePerformance = await dispatch('fetchSitePerformanceData', {
          siteId,
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
        })

        // Ensure we only send existing values
        const keys = ['clicks', 'cost', 'conversions', 'cpc', 'impressions', 'ctr', 'conversion_rate']
        keys.forEach(key => {
          if (sitePerformance[key] === undefined) {
            delete sitePerformance[key]
          }
        })

        commit(TYPE.SET_SITE_PERFORMANCE, sitePerformance)
      } catch (error) {
        console.error('Error fetching site metrics:', error)
      }
    },
    async fetchSiteCampaignsPerformanceData(
      { state, commit },
      { siteId, startDate, endDate, compareStartDate, compareEndDate, channel, status = null, month = null },
    ) {
      if (!siteId) {
        throw new Error('No siteId provided')
      }

      const { data } = await axios.get(`common/campaign`, {
        params: {
          site_id: siteId,
          start_date: startDate,
          end_date: endDate,
          compare_start_date: compareStartDate,
          compare_end_date: compareEndDate,
          channel_slug: channel,
          status_slug: status,
        },
      })

      if (!data || !data[0]) {
        throw new Error('No data received')
      } else {
        if (month) {
          const dataForMonthExist = state.siteCampaignsPerformanceMonthly.filter(data => data.month === month)
          if (dataForMonthExist.length === 0) {
            commit('addElementToCampaigns', { data, month, siteId })
          }
        } else {
          commit(TYPE.SET_SITE_CAMPAIGNS_PERFORMANCE, data)
        }
      }
    },
    async getSitePerformanceMonthly(
      { state, dispatch, commit },
      { siteId, startDate, endDate, compareStartDate, compareEndDate, month },
    ) {
      commit(TYPE.SET_SITE_PERFORMANCE_MONTHLY, []) // Always clear previous data

      try {
        const data = await dispatch('fetchSitePerformanceData', {
          siteId,
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
        })

        commit('addElementToSite', { data, month, siteId })
        return data
      } catch (error) {
        console.error('Error fetching site performance:', error)
        return []
      }
    },
    async getSiteCampaignsPerformance(
      { state, dispatch, commit },
      { siteId, startDate, endDate, compareStartDate, compareEndDate, month },
    ) {
      const getDataForMonth = () => {
        const dataForMonth = state.siteCampaignsPerformanceMonthly.filter(data => data.month === month)
        return dataForMonth.length > 0 ? dataForMonth[0].data : null
      }

      if (
        state.siteCampaignsPerformanceMonthly.length > 0 &&
        state.siteCampaignsPerformanceMonthly[0].siteId === siteId
      ) {
        const data = getDataForMonth()
        if (data) return data
      } else {
        //Change the state to empty array
        commit(TYPE.SET_SITE_CAMPAIGNS_PERFORMANCE_MONTHLY, [])
      }
      try {
        await dispatch('fetchSiteCampaignsPerformanceData', {
          siteId,
          startDate,
          endDate,
          compareStartDate,
          compareEndDate,
          month,
        })
        const data = getDataForMonth()
        if (data) return data
      } catch (error) {
        console.error('Error fetching site campaigns performance:', error)
      }
      return []
    },
    async fetchSiteWelcomeMessage({ commit }, siteId) {
      try {
        const response = await axios.get(`/search/site/${siteId}/welcome`)

        const metricKeyMapping = {
          cpa: 'CPA',
          ctr: 'CTR',
          roas: 'ROAS',
          cpc: 'CPC',
          conversion_rate: 'Conversion Rate',
          conversion_value: 'Conversion Value',
        }

        // Exclude impressions and format metrics based on the mapping
        const welcomeData = { ...response.data }
        const metrics = welcomeData.metrics
        delete welcomeData.metrics.impressions

        // Check if all conversion values are 0 or null
        const conversionsThisMonth = metrics.conversions ? metrics.conversions.this_month : null
        const conversionsLastMonth = metrics.conversions ? metrics.conversions.last_month : null
        if (
          (conversionsThisMonth === 0 || conversionsThisMonth === null) &&
          (conversionsLastMonth === 0 || conversionsLastMonth === null)
        ) {
          delete metrics['cpa']
          delete metrics['CPA']
          delete metrics['roas']
          delete metrics['ROAS']
          delete metrics['conversion_rate']
          delete metrics['Conversion Rate']
          delete metrics['conversion_value']
          delete metrics['Conversion Value']
        }

        for (const originalKey in metricKeyMapping) {
          if (metrics[originalKey] !== undefined) {
            metrics[metricKeyMapping[originalKey]] = metrics[originalKey]
            delete metrics[originalKey]
          }
        }

        commit(TYPE.SET_SITE_WELCOME_MESSAGE, welcomeData)
        commit(TYPE.SITE_WELCOME_MESSAGE_ERROR, null)
      } catch (error) {
        commit(TYPE.SITE_WELCOME_MESSAGE_ERROR, error.message)
      }
    },
    async fetchSiteBiddingUpdates({ commit }, siteId) {
      const response = await axios.get(`/search/site/${siteId}/ai-activity/bidding-updates`)
      commit(TYPE.SET_SITE_BIDDING_UPDATES, response.data)
    },
    async fetchCampaignsPerformanceFunnelData(
      { commit },
      { siteId, startDate, endDate, compareStartDate, compareEndDate, channel, status = null },
    ) {
      if (!siteId) {
        throw new Error('No siteId provided')
      }

      const fetchData = async (start, end) =>
        axios.get(`common/campaign`, {
          params: {
            site_id: siteId,
            start_date: start,
            end_date: end,
            channel_slug: channel,
            status_slug: status,
            only_metrics: true,
          },
        })

      const [currentDataResponse, compareDataResponse] = await Promise.all([
        fetchData(startDate, endDate),
        fetchData(compareStartDate, compareEndDate),
      ])

      if (!currentDataResponse.data || !compareDataResponse.data) {
        throw new Error('No data received')
      }

      const filteredCampaigns = currentDataResponse.data.filter(campaign => campaign.cost > 0)

      const data = filteredCampaigns.map(campaign => {
        const campaignCompareData =
          compareDataResponse.data.find(compareCampaign => compareCampaign.id === campaign.id) || {}
        const calculateVariation = (current, compare) => (compare ? ((current - compare) / compare) * 100 : 0)

        return {
          id: campaign.id,
          campaign_name: campaign.campaign_name,
          impressions: {
            current: campaign.impressions,
            compare: campaignCompareData.impressions || 0,
            variation_percentage: calculateVariation(campaign.impressions, campaignCompareData.impressions),
          },
          conversions: {
            current: campaign.conversions,
            compare: campaignCompareData.conversions || 0,
            variation_percentage: calculateVariation(campaign.conversions, campaignCompareData.conversions),
          },
          clicks: {
            current: campaign.clicks,
            compare: campaignCompareData.clicks || 0,
            variation_percentage: calculateVariation(campaign.clicks, campaignCompareData.clicks),
          },
          cost: {
            current: campaign.cost,
            compare: campaignCompareData.cost || 0,
            variation_percentage: calculateVariation(campaign.cost, campaignCompareData.cost),
          },
          cpa: {
            current: campaign.cost_conv,
            compare: campaignCompareData.cost_conv || 0,
            variation_percentage: calculateVariation(campaign.cost_conv, campaignCompareData.cost_conv),
          },
          currency: campaign.currency,
        }
      })

      commit(TYPE.SET_SITE_CAMPAIGNS_PERFORMANCE_FUNNEL, data)
    },
    async fetchSiteManagedCampaignsIdentifier({ commit }, siteId) {
      if (!siteId) {
        throw new Error('No siteId provided')
      }
      try {
        const response = await axios.get(`/search/site/${siteId}/managed_campaign_identifier`)
        const managedCampaignsIdentifier = response?.data?.identify_managed_campaigns_with_brightbid
        commit(TYPE.SET_SITE_MANAGED_CAMPAIGNS_IDENTIFIER, managedCampaignsIdentifier)
      } catch (error) {
        console.error('Error fetching site managed campaigns identifier:', error)
      }
    },
  },
  mutations: {
    [TYPE.LOAD_ORGANIZATION_SITE](state, data) {
      if (data) {
        state.organizationSite = data
      } else {
        state.organizationSite = []
      }
    },
    [TYPE.LOAD_SELECTED_SITE](state, data) {
      if (!data) {
        localStorage.removeItem(localStorageSite)
        state.selectedSite = null
      }
      localStorage.setItem(localStorageSite, JSON.stringify(data))
      state.selectedSite = data
    },
    [TYPE.SET_SITE_DETAILS](state, siteDetails) {
      state.siteDetails = siteDetails
    },
    [TYPE.SET_SITE_PERFORMANCE](state, sitePerformance) {
      state.sitePerformance = sitePerformance
    },
    [TYPE.SET_SITE_PERFORMANCE_MONTHLY](state, sitePerformanceMonthly) {
      state.sitePerformanceMonthly = sitePerformanceMonthly
    },
    [TYPE.SET_SITE_CAMPAIGNS_PERFORMANCE](state, siteCampaignsPerformance) {
      state.siteCampaignsPerformance = siteCampaignsPerformance
    },
    [TYPE.SET_SITE_CAMPAIGNS_PERFORMANCE_MONTHLY](state, siteCampaignsPerformanceMonthly) {
      state.siteCampaignsPerformanceMonthly = siteCampaignsPerformanceMonthly
    },
    [TYPE.SET_SITE_WELCOME_MESSAGE](state, siteWelcomeMessage) {
      state.siteWelcomeMessage = siteWelcomeMessage
    },
    [TYPE.SET_SITE_BIDDING_UPDATES](state, siteBiddingUpdates) {
      state.siteBiddingUpdates = siteBiddingUpdates
    },
    [TYPE.SET_SITE_CAMPAIGNS_PERFORMANCE_FUNNEL](state, siteCampaignsPerformanceFunnel) {
      state.siteCampaignsPerformanceFunnel = siteCampaignsPerformanceFunnel
    },
    [TYPE.SITE_WELCOME_MESSAGE_ERROR](state, error) {
      state.siteWelcomeMessageError = error
    },
    [TYPE.SET_SITE_MANAGED_CAMPAIGNS_IDENTIFIER](state, managedCampaignsIdentifier) {
      state.managedCampaignsIdentifier = managedCampaignsIdentifier
    },
    addElementToCampaigns(state, element) {
      state.siteCampaignsPerformanceMonthly.push(element)
      //Limit the array to 12 elements
      if (state.siteCampaignsPerformanceMonthly.length > 12) {
        state.siteCampaignsPerformanceMonthly.shift()
      }
    },
    addElementToSite(state, element) {
      state.sitePerformanceMonthly.push(element)
      //Limit the array to 12 elements
      if (state.sitePerformanceMonthly.length > 12) {
        state.sitePerformanceMonthly.shift()
      }
    },
  },
}
