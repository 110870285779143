const state = {
  topKeywords: null,
  topKeywordsLoading: false,
  topKeywordsError: null,
}

const mutations = {
  SET_TOP_KEYWORDS(state, data) {
    if (data && Array.isArray(data)) {
      state.topKeywords = data.map((item, index) => ({
        ...item,
        id: index + 1,
      }))
    } else {
      state.topKeywords = null
    }
  },
  SET_TOP_KEYWORDS_LOADING(state, loading) {
    state.topKeywordsLoading = loading
  },
  SET_TOP_KEYWORDS_ERROR(state, error) {
    state.topKeywordsError = error
  },
}

const actions = {
  async fetchTopKeywords({ commit }, { siteId, start_date, end_date, target_type }) {
    commit('SET_TOP_KEYWORDS_LOADING', true)
    commit('SET_TOP_KEYWORDS_ERROR', null)
    try {
      const response = await axios.get(`/search/site/${siteId}/ai-activity/top-keywords`, {
        params: { start_date, end_date, target_type },
      })
      commit('SET_TOP_KEYWORDS', response.data)
    } catch (error) {
      commit('SET_TOP_KEYWORDS_ERROR', error)
      commit('SET_TOP_KEYWORDS', null)
    } finally {
      commit('SET_TOP_KEYWORDS_LOADING', false)
    }
  },
}

const getters = {
  topKeywords: state => state.topKeywords,
  topKeywordsLoading: state => state.topKeywordsLoading,
  topKeywordsError: state => state.topKeywordsError,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
