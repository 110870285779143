import { normalize } from '@/utils/util'
import { feedTransformer } from '@/core/feed/feed-transformer'

const TYPE = {
  SET_LOAD_CALL: 'SET_LOAD_CALL',
  SET_ERROR: 'SET_ERROR',
  SET_ITEMS: 'SET_ITEMS',
}

export default {
  namespaced: true,
  state: {
    items: {},
    loadCalls: {},
    loadErrors: {},
  },
  getters: {
    storeHasFeedsByStoreId: (_, getters) => storeId => {
      return getters.getFeedsByStoreId(storeId).length > 0
    },
    getFeedsByStoreId: state => storeId => {
      return state.items[storeId] ? Object.values(state.items[storeId]) : []
    },
    isFeedsLoadingByStoreId: state => storeId => {
      return !!state.loadCalls[storeId]
    },
    getLoadCallByStoreId: state => storeId => {
      return state.loadCalls[storeId]
    },
    getFeedsErrorByStoreId: state => storeId => {
      return state.loadErrors[storeId]
    },
  },
  actions: {
    loadFeedsByStoreId({ commit }, storeId) {
      commit(TYPE.SET_ERROR, { storeId, e: null })

      const call = axios
        .get(`store/${storeId}/feed`)
        .then(({ data }) => {
          commit(TYPE.SET_ITEMS, { storeId, data: normalize(data.data.map(feedTransformer)) })
          return data.data
        })
        .catch(e => commit(TYPE.SET_ERROR, { storeId, e }))
        .finally(() => commit(TYPE.SET_LOAD_CALL, { storeId, call: null }))

      commit(TYPE.SET_LOAD_CALL, { storeId, call })

      return call
    },
    loadFeedsByStoreIdIfNeeded({ dispatch, getters }, storeId) {
      if (!!getters.getLoadCallByStoreId(storeId)) return getters.getLoadCallByStoreId(storeId)

      if (getters.getFeedsByStoreId(storeId).length > 0) return Promise.resolve(getters.getFeedsByStoreId(storeId))

      return dispatch('loadFeedsByStoreId', storeId)
    },
  },
  mutations: {
    [TYPE.SET_LOAD_CALL](state, { storeId, call }) {
      state.loadCalls = {
        ...state.loadCalls,
        [storeId]: call,
      }
    },
    [TYPE.SET_ERROR](state, { storeId, e }) {
      state.loadErrors = {
        ...state.loadErrors,
        [storeId]: e,
      }
    },
    [TYPE.SET_ITEMS](state, { storeId, data }) {
      state.items = {
        ...state.items,
        [storeId]: data,
      }
    },
  },
}
