/**
 * Returns a copy of the passed object without a reference to the original object. Falsy param get returned as is.
 * @param {Object} obj
 * @returns {Object}
 */
exports.newReference = obj => (obj ? JSON.parse(JSON.stringify(obj)) : obj)

/**
 * Rudimentary url param string builder
 * @param obj
 * @returns {string}
 */
exports.buildURLQuery = obj =>
  Object.entries(obj)
    .map(pair => pair.map(encodeURIComponent).join('='))
    .join('&')

/**
 * Normalizes data from an array to an object with each array item as prop with given key as prop name.
 * @param {Array} data
 * @param {String} key
 * @returns {Object}
 */
exports.normalize = (data = [], key = 'id') => {
  let normalizedData = {}
  for (const i in data) {
    normalizedData[data[i][key]] = data[i]
  }
  return normalizedData
}

exports.getOrdinalNum = n => {
  return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '')
}

/**
 * VAT number regex per country code
 * @type {{EU: RegExp}}
 */
exports.vatPattern = {
  AT: /^(AT)(U\d{8}$)/i, // Austria
  BE: /^(BE)(\d{10}$)/i, // Belgium
  BG: /^(BG)(\d{9,10}$)/i, // Bulgaria
  CY: /^(CY)([0-5|9]\d{7}[A-Z]$)/i, // Cyprus
  CZ: /^(CZ)(\d{8,10})?$/i, // Czech Republic
  DE: /^(DE)([1-9]\d{8}$)/i, // Germany
  DK: /^(DK)(\d{8}$)/i, // Denmark
  EE: /^(EE)(10\d{7}$)/i, // Estonia
  EL: /^(EL)(\d{9}$)/i, // Greece
  ES: /^(ES)([0-9A-Z][0-9]{7}[0-9A-Z]$)/i, // Spain
  EU: /^(EU)(\d{9}$)/i, // EU-type
  FI: /^(FI)(\d{8}$)/i, // Finland
  FR: /^(FR)([0-9A-Z]{2}[0-9]{9}$)/i, // France
  GB: /^(GB)((?:[0-9]{12}|[0-9]{9}|(?:GD|HA)[0-9]{3})$)/i, // UK (Standard = 9 digits), (Branches = 12 digits), (Government = GD + 3 digits), (Health authority = HA + 3 digits)
  GR: /^(GR)(\d{8,9}$)/i, // Greece
  HR: /^(HR)(\d{11}$)/i, // Croatia
  HU: /^(HU)(\d{8}$)/i, // Hungary
  // eslint-disable-next-line no-useless-escape
  IE: /^(IE)([0-9A-Z\*\+]{7}[A-Z]{1,2}$)/i, // Ireland
  IT: /^(IT)(\d{11}$)/i, // Italy
  LV: /^(LV)(\d{11}$)/i, // Latvia
  LT: /^(LT)(\d{9}$|\d{12}$)/i, // Lithunia
  LU: /^(LU)(\d{8}$)/i, // Luxembourg
  MT: /^(MT)([1-9]\d{7}$)/i, // Malta
  NL: /^(NL)(\d{9}B\d{2}$)/i, // Netherlands
  NO: /^(NO)(\d{9}$)/i, // Norway (not EU)
  PL: /^(PL)(\d{10}$)/i, // Poland
  PT: /^(PT)(\d{9}$)/i, // Portugal
  RO: /^(RO)([1-9]\d{1,9}$)/i, // Romania
  RU: /^(RU)(\d{10}$|\d{12}$)/i, // Russia
  RS: /^(RS)(\d{9}$)/i, // Serbia
  SI: /^(SI)([1-9]\d{7}$)/i, // Slovenia
  SK: /^(SK)([1-9]\d[(2-4)|(6-9)]\d{7}$)/i, // Slovakia Republic
  SE: /^(SE)(\d{10}01$)/i, // Sweden
}

/**
 *
 * @return {void}
 */
exports.exportToCsv = (filename, rows) => {
  const processRow = function (row) {
    let finalVal = ''
    for (let j = 0; j < row.length; j++) {
      if (row[j]) {
        let innerValue = row[j] === null ? '' : row[j].toString()
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString()
        }
        let result = innerValue.replace(/"/g, '""')
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'
        if (j > 0) finalVal += ','
        finalVal += result
      }
    }
    return finalVal + '\n'
  }

  let csvFile = ''
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i])
  }
  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

exports.exportToCsvAsync = (filename, data) => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

exports.getErrorStatus = error => {
  return error.response?.status ?? error.status ?? 500
}
