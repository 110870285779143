import { normalize } from '@/utils/util'

const TYPE = {
  SET_LOAD_CALL: 'SET_LOAD_CALL',
  SET_ERROR: 'SET_ERROR',
  SET_ITEMS: 'SET_ITEMS',
}

export default {
  namespaced: true,
  state: {
    items: {},
    loadCall: null,
    itemsError: null,
  },
  getters: {
    getCards(state) {
      return Object.values(state.items)
    },
    hasCards(state) {
      return Object.values(state.items).length !== 0
    },
    getCardByStripeId: state => stripeId => {
      return state.items[stripeId]
    },
    isCardsLoading(state) {
      return !!state.loadCall
    },
    getCardsError(state) {
      return state.itemsError
    },
    getLoadCall(state) {
      return state.loadCall
    },
  },
  actions: {
    clear({ commit }) {
      commit(TYPE.SET_ITEMS, null)
      commit(TYPE.SET_ERROR, null)
      commit(TYPE.SET_LOAD_CALL, null)
    },
    loadCards({ commit }) {
      commit(TYPE.SET_ERROR, null)

      const call = axios
        .get(`/card/list`)
        .then(({ data }) => {
          return commit(
            TYPE.SET_ITEMS,
            normalize(
              data.data.map(item => ({
                ...item,
                id: item.stripe_id,
                name: `${item.brand}, ****${item.last4}, ${item.exp_year}/${item.exp_month < 10 ? '0' : ''}${item.exp_month}`,
              })),
              'stripe_id',
            ),
          )
        })
        .catch(e => commit(TYPE.SET_ERROR, e))
        .finally(() => commit(TYPE.SET_LOAD_CALL, null))

      commit(TYPE.SET_LOAD_CALL, call)

      return call
    },
    loadCardsIfNeeded({ dispatch, getters }) {
      if (!!getters.getLoadCall) return getters.getLoadCall
      if (getters.hasCards) return Promise.resolve(getters.getCards)

      return dispatch('loadCards')
    },
  },
  mutations: {
    [TYPE.SET_LOAD_CALL](state, call) {
      state.loadCall = call
    },
    [TYPE.SET_ERROR](state, error) {
      state.itemsError = error
    },
    [TYPE.SET_ITEMS](state, items) {
      state.items = items
    },
  },
}
