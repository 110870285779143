import dayjs from 'dayjs'
import { dateRangePresets } from '@/utils/date'
import { exportToCsvAsync } from '@/utils/util'

const TYPE = {
  SET_SEARCH_TERMS: 'SET_SEARCH_TERMS',
  SET_OVERALL_LOAD: 'SET_OVERALL_LOAD',
  SET_TABLE_LOAD: 'SET_TABLE_LOAD',
  SET_FILTERS: 'SET_FILTERS',
  SET_SORT: 'SET_SORT',
  SET_CAMPAIGN: 'SET_CAMPAIGN',
  SET_COUNTER: 'SET_COUNTER',
  SET_RANGE_DATES: 'SET_RANGE_DATES',
  CLEAR_ON_CHANGE_STORE: 'CLEAR_ON_CHANGE_STORE',
}
const qb = getters => {
  let queryBuilder = {}
  let sortBuilder = {}
  let totalBuilder = null

  if (getters.filters.length) {
    const filters = getters.filters.filter(filter => filter.id !== 0)

    for (const filter of filters) {
      queryBuilder[filter.metric.value] = {
        [filter.modifier.value]: typeof filter.value === 'string' ? filter.value : filter.value.value,
      }
    }
  }

  if (getters.searchTerms) {
    totalBuilder = getters.searchTerms.pagination.total_search_terms
  }

  if (getters.primaryDateRange) {
    const from = dayjs(getters.primaryDateRange.from).format('YYYY-MM-DD')
    const to = dayjs(getters.primaryDateRange.to).format('YYYY-MM-DD')
    queryBuilder = { ...queryBuilder, date: { greater_than: from, less_than: to } }
  }

  if (getters.selectedCampaign) {
    queryBuilder = { ...queryBuilder, campaign_name: { exact_match: getters.selectedCampaign } }
  }

  const hasSortProperty = getters.headers.some(header => header.sort)
  if (hasSortProperty) {
    const filtered = getters.headers.filter(h => h.sort).sort((a, b) => b.sortOrder - a.sortOrder)
    for (const filteredElement of filtered) {
      sortBuilder = {
        ...sortBuilder,
        [filteredElement.accessibility]: filteredElement.sort,
      }
    }
  }

  return { queryBuilder, sortBuilder, totalBuilder }
}

export default {
  namespaced: true,
  state: {
    overallLoad: false,
    loadingTable: false,
    filters: [],
    dateRanges: {
      primary: null,
    },
    campaign: null,
    counter: null,
    searchTerms: null,
    columnDefs: [{ 'min-width': '30px' }, { 'min-width': '200px' }],
    headers: [
      {
        header: 'Search term',
        accessibility: 'search_term',
        slot_name: 'search-term',
        hasSort: false,
        footerAccessibility: true,
      },
      {
        header: 'Conv. intent',
        accessibility: 'quality',
        slot_name: 'quality',
        hasSort: false,
        footerAccessibility: false,
      },
      {
        header: 'Impressions',
        accessibility: 'impressions',
        slot_name: 'impressions',
        hasSort: true,
        footerAccessibility: 'sum_impressions',
      },
      {
        header: 'CTR',
        accessibility: 'ctr',
        slot_name: 'ctr',
        hasSort: true,
        footerAccessibility: false,
      },
      {
        header: 'Clicks',
        accessibility: 'clicks',
        slot_name: 'clicks',
        hasSort: true,
        footerAccessibility: 'sum_clicks',
        sort: 'desc',
        sortOrder: 0,
      },
      {
        header: 'Avg. CPC',
        accessibility: 'cpc',
        slot_name: 'cpc',
        hasSort: true,
        footerAccessibility: 'average_cpc',
      },
      {
        header: 'Conv. value',
        accessibility: 'conv_value',
        slot_name: 'conv-value',
        hasSort: true,
        footerAccessibility: 'sum_conv_value',
      },
      {
        header: 'Cost',
        accessibility: 'cost',
        slot_name: 'cost',
        hasSort: true,
        footerAccessibility: 'sum_cost',
      },
      {
        header: 'ROAS',
        accessibility: 'roas',
        slot_name: 'roas',
        hasSort: true,
        footerAccessibility: 'average_roas',
      },
      {
        header: 'Conversions',
        accessibility: 'conversions',
        slot_name: 'conversions',
        hasSort: true,
        footerAccessibility: 'sum_conversions',
      },
      {
        header: 'Profit',
        accessibility: 'profit',
        slot_name: 'profit',
        headerSlot: true,
        hasSort: true,
        footerAccessibility: 'sum_profit',
      },
    ],
    defaultPage: 1,
    defaultLimit: 50,
  },
  getters: {
    overAllLoad(state) {
      return state.overallLoad
    },
    tableLoading(state) {
      return state.loadingTable
    },
    headers(state) {
      return state.headers
    },
    filters(state) {
      return state.filters
    },
    selectedCampaign(state) {
      return state.campaign
    },
    dateRanges(state) {
      return state.dateRanges
    },
    primaryDateRange(state) {
      return state.dateRanges.primary
    },
    searchTerms(state) {
      return state.searchTerms
    },
    defaultPage(state) {
      return state.defaultPage
    },
    defaultLimit(state) {
      return state.defaultLimit
    },
    columnDefs(state) {
      return state.columnDefs
    },
  },
  actions: {
    sort({ commit, dispatch }, { header, index }) {
      commit(TYPE.SET_COUNTER)
      commit(TYPE.SET_SORT, { header, index })
    },
    async getFilters({ commit, dispatch }) {
      const filters = await dispatch('getIndexDBRecords', 'searchTerms-filters', { root: true })
      commit(TYPE.SET_FILTERS, filters)
    },
    async addFilters({ dispatch, getters, commit }, filter) {
      if (filter.id === 0) {
        commit(TYPE.SET_FILTERS, [...getters.filters, filter])
      } else {
        await dispatch('addIndexDBRecord', { record: filter, objectStore: 'searchTerms-filters' }, { root: true })
        await dispatch('getFilters')
      }
    },
    async deleteFilter({ commit, getters, dispatch }, filter) {
      if (filter.id === 0) {
        commit(
          TYPE.SET_FILTERS,
          getters.filters.filter(f => f.id !== filter.id),
        )
      } else {
        await dispatch('deleteIndexDBRecord', { id: filter.id, objectStore: 'searchTerms-filters' }, { root: true })
        await dispatch('getFilters')
      }
    },
    async clearAllFilters({ dispatch }) {
      await dispatch('deleteAllIndexDBRecords', 'searchTerms-filters', { root: true })
      await dispatch('getFilters')
    },
    getRangeDates({ commit, getters }) {
      if (!getters.dateRanges.primary) {
        const defaultPreset = dateRangePresets('Last 30 days')
        commit(TYPE.SET_RANGE_DATES, { dateRange: defaultPreset, propName: 'primary' })
      }
    },
    setCampaign({ commit }, campaignName) {
      commit(TYPE.SET_CAMPAIGN, campaignName)
    },
    setRangeDates({ commit }, { dateRange, propName }) {
      commit(TYPE.SET_RANGE_DATES, { dateRange, propName })
    },
    async exportAllProductsToCSV({ getters, rootGetters }) {
      const store = rootGetters['store/selectedStore']
      const { queryBuilder, sortBuilder } = qb(getters)

      const url = new URL(`${axios.defaults.baseURL}/store/${store.id}/search-terms/overview/export`)

      if (Object.keys(queryBuilder).length) {
        url.searchParams.set('query', JSON.stringify(queryBuilder))
      }

      if (Object.keys(sortBuilder).length) {
        url.searchParams.set('sort', JSON.stringify(sortBuilder))
      }

      const csvData = await axios.get(url.href)

      exportToCsvAsync(`${store.name}-search-terms.csv`, csvData.data)
    },
    async getSearchTerms({ commit, dispatch, getters, rootGetters, state }, { query, addTotal }) {
      const store = rootGetters['store/selectedStore']

      if (getters.overAllLoad) {
        commit(TYPE.SET_TABLE_LOAD, true)
      }

      const url = new URL(`${axios.defaults.baseURL}/store/${store.id}/search-terms/overview`)

      if (query) {
        const { queryBuilder, sortBuilder, totalBuilder } = qb(getters)
        url.searchParams.set('page', query.page ? query.page : getters.defaultPage)
        url.searchParams.set('size', query.limit ? query.limit : getters.defaultLimit)

        if (Object.keys(queryBuilder).length) {
          url.searchParams.set('query', JSON.stringify(queryBuilder))
        }

        if (Object.keys(sortBuilder).length) {
          url.searchParams.set('sort', JSON.stringify(sortBuilder))
        }

        if (addTotal) {
          url.searchParams.set('total', totalBuilder)
        }
      }

      const searchTerms = await axios.get(url.href)
      commit(TYPE.SET_SEARCH_TERMS, searchTerms.data.data)
      commit(TYPE.SET_OVERALL_LOAD, true)
      commit(TYPE.SET_TABLE_LOAD, false)
    },
    setOverallLoad({ commit }, value) {
      commit(TYPE.SET_OVERALL_LOAD, value)
    },
    clearOnChangeStore({ commit }) {
      commit(TYPE.CLEAR_ON_CHANGE_STORE)
    },
  },
  mutations: {
    [TYPE.SET_OVERALL_LOAD](state, value) {
      state.overallLoad = value
    },
    [TYPE.SET_TABLE_LOAD](state, value) {
      state.loadingTable = value
    },
    [TYPE.SET_RANGE_DATES](state, { dateRange, propName }) {
      state.dateRanges[propName] = dateRange
    },
    [TYPE.SET_FILTERS](state, filters) {
      state.filters = filters
    },
    [TYPE.SET_SEARCH_TERMS](state, searchTerms) {
      state.searchTerms = searchTerms
    },
    [TYPE.SET_COUNTER](state) {
      state.counter = state.counter + 1
    },
    [TYPE.SET_CAMPAIGN](state, campaignName) {
      state.campaign = campaignName
    },
    [TYPE.SET_SORT](state, { header, index }) {
      state.headers = state.headers.map((h, i) => {
        if (index === i) {
          if (!h.sort) {
            return { ...h, sort: 'asc', sortOrder: state.counter }
          }
          if (h.sort && h.sort === 'desc') {
            return { ...h, sort: 'asc', sortOrder: state.counter }
          } else {
            return { ...h, sort: 'desc', sortOrder: state.counter }
          }
        }
        return h
      })
    },
    [TYPE.CLEAR_ON_CHANGE_STORE](state) {
      state.campaign = null
      state.dateRanges = {
        primary: null,
      }
    },
  },
}
