<template>
  <div class="linear-gradient"></div>
</template>

<script>
export default {
  name: 'TopGradient',
}
</script>

<style scoped lang="scss">
.linear-gradient {
  @apply sticky top-0;
  z-index: 100000;
  height: 8px;
  background: linear-gradient(270deg, #3e4552 0.01%, #6366fa 35.66%, #84fae4 65.91%, #677fea 103.72%);
}
</style>
