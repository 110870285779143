<template>
  <shape
    :type="type"
    :class="classes"
    class="border"
  >
    <template #default>
      <div class="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:items-center">
        <div class="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:-space-y-5">
          <ic-info
            v-if="type === 'regular'"
            class="text-bb-neon-purple transform my-auto"
            :size="32"
            :class="{ 'rotate-180': type === 'error' }"
          />
          <ic-info
            v-if="type === 'information'"
            class="text-bb-dull-blue transform my-auto"
            :size="32"
            :class="{ 'rotate-180': type === 'error' }"
          />
          <ic-info
            v-if="type === 'warn'"
            class="text-bb-dull-orange transform my-auto"
            :size="32"
            :class="{ 'rotate-180': type === 'error' }"
          />
          <ic-check-outline
            v-else-if="type === 'success'"
            :size="32"
            class="text-bb-dull-green my-auto"
          />
          <ic-cancel
            v-else-if="type === 'error'"
            :size="32"
            class="text-bb-dull-red my-auto"
          />
          <div class="flex flex-col pt-0 sm:pt-5">
            <span class="p1 font-semibold">
              <slot name="title" />
            </span>
            <span class="p1 my-auto">
              <slot name="content" />
            </span>
          </div>
        </div>
        <slot name="action" />
      </div>
    </template>
  </shape>
</template>

<script>
import IcInfo from 'vue-material-design-icons/InformationOutline'
import icCheck from 'vue-material-design-icons/Check'
import icCheckOutline from 'vue-material-design-icons/CheckCircleOutline'
import icClose from 'vue-material-design-icons/Close'
import icCancel from 'vue-material-design-icons/Cancel'

export default {
  name: 'base-alert',
  components: { IcInfo, icCheck, icClose, icCheckOutline, icCancel },
  props: {
    type: {
      type: String,
      default: 'regular',
      validator: val => ['regular', 'success', 'warn', 'error', 'information'].includes(val),
    },
    sharp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'border-bb-dull-orange': this.type === 'warn',
        'border-bb-dull-green': this.type === 'success',
        'border-bb-dull-red': this.type === 'error',
        'border-bb-dull-blue': this.type === 'information',
        'border-bb-dark-grey': this.type === 'regular',
        'rounded-md': !this.sharp,
      }
    },
  },
}
</script>
