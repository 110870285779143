import { render, staticRenderFns } from "./CustomerDropdown.vue?vue&type=template&id=872c7624&scoped=true"
import script from "./CustomerDropdown.vue?vue&type=script&lang=js"
export * from "./CustomerDropdown.vue?vue&type=script&lang=js"
import style0 from "./CustomerDropdown.vue?vue&type=style&index=0&id=872c7624&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "872c7624",
  null
  
)

export default component.exports