<template>
  <div class="page-title flex flex-wrap items-center">
    <div class="flex flex-grow items-center">
      <div
        :class="{ 'mr-4 my-auto': !!$slots.icon }"
        class="text-bb-primary-purple"
      >
        <slot name="icon" />
      </div>
      <p class="h3 flex-grow mt-0 mb-0">
        <slot />
      </p>
    </div>
    <div
      class="flex-grow-0"
      :class="{ 'ml-4': !!$slots.action }"
    >
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
}
</script>
