const Sentry = require('@sentry/vue')

exports.getRuntimeConfig = Vue => ({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 1.0,
  tracesSampleRate: 1.0,
  environment: process.env.VUE_APP_ENVIRONMENT,
})

exports.getBuildConfig = SENTRY_AUTH_TOKEN => ({
  // sentry-cli configuration
  authToken: SENTRY_AUTH_TOKEN,
  org: 'speqta',
  project: 'bb-ui',
  release: `bb-ui@${process.env.VUE_APP_APP_VERSION}`,
  // webpack specific configuration
  include: '.',
  ignore: ['node_modules', 'webpack.config.js'],
})

exports.allowSentry = isBuild => {
  const SENTRY_ENVIRONMENTS_DISABLED = ['dev', 'development']
  const SENTRY_REQUIRED_ENVS = ['VUE_APP_ENVIRONMENT', 'VUE_APP_APP_VERSION']

  isBuild ? SENTRY_REQUIRED_ENVS.push('SENTRY_AUTH_TOKEN') : SENTRY_REQUIRED_ENVS.push('VUE_APP_SENTRY_DSN')

  return (
    SENTRY_REQUIRED_ENVS.reduce((allow, env) => {
      if (!process.env[env]) return false // check if required envs are set
      return allow
    }, true) && !SENTRY_ENVIRONMENTS_DISABLED.includes(process.env.VUE_APP_ENVIRONMENT)
  )
}
