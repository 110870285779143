import dayjs from 'dayjs'
import { KEY, writeDate, readDate, readString, writeString } from '@/utils/storage/session'
import { dateToString, dateToHumanReadable, humanizeDate, dateRangePresets } from '@/utils/date'

const TYPE = {
  SET_ALL_DATE_DATA: 'SET_ALL_DATE_DATA',
}

const dateRangeToHumanReadable = (startDate, endDate) => {
  return `${dateToHumanReadable(startDate)} - ${dateToHumanReadable(endDate)}`
}

const KEY_COMPARE = 'dates_compare'

const LAST_THIRTY_DAYS = dateRangePresets('Last 30 days')
const START = dayjs(LAST_THIRTY_DAYS.from)
const END = dayjs(LAST_THIRTY_DAYS.to)
const DIFF_IN_DAYS = Math.abs(START.diff(END, 'day'))
const PREVIOUS_PERIOD = {
  to: START.subtract(1, 'day').toDate(),
  from: START.subtract(DIFF_IN_DAYS + 1, 'day').toDate(),
}

export default {
  namespaced: true,
  state: {
    startDate: readDate(KEY.START_DATE, LAST_THIRTY_DAYS.from),
    endDate: readDate(KEY.END_DATE, LAST_THIRTY_DAYS.to),
    compare: JSON.parse(sessionStorage.getItem(KEY_COMPARE)) || true,
    compareStartDate: readDate(KEY.COMPARE_START_DATE, PREVIOUS_PERIOD.from),
    compareEndDate: readDate(KEY.COMPARE_END_DATE, PREVIOUS_PERIOD.to),
    preset: readString(KEY.PRESET, 'Last 30 days'),
    comparePreset: readString(KEY.COMPARE_PRESET, 'previous-period'),
  },
  getters: {
    startDate(state) {
      return state.startDate
    },
    endDate(state) {
      return state.endDate
    },
    compare(state) {
      return state.compare
    },
    compareStartDate(state) {
      return state.compareStartDate
    },
    compareEndDate(state) {
      return state.compareEndDate
    },
    preset(state) {
      return state.preset
    },
    comparePreset(state) {
      return state.comparePreset
    },
    startDateAsString(state) {
      return dateToString(state.startDate)
    },
    endDateAsString(state) {
      return dateToString(state.endDate)
    },
    compareStartDateAsString(state) {
      return dateToString(state.compareStartDate)
    },
    compareEndDateAsString(state) {
      return dateToString(state.compareEndDate)
    },
    selectedDateRangeHumanized(state) {
      return dateRangeToHumanReadable(state.startDate, state.endDate)
    },
    selectedCompareDateRangeHumanized(state) {
      return dateRangeToHumanReadable(state.compareStartDate, state.compareEndDate)
    },
    startDateHumanized(state) {
      return humanizeDate(state.startDate)
    },
    endDateHumanized(state) {
      return humanizeDate(state.endDate)
    },
    compareStartDateHumanized(state) {
      return humanizeDate(state.compareStartDate)
    },
    compareEndDateHumanized(state) {
      return humanizeDate(state.compareEndDate)
    },
    allDatesData(state) {
      return {
        startDate: state.startDate,
        endDate: state.endDate,
        compare: state.compare,
        compareStartDate: state.compareStartDate,
        compareEndDate: state.compareEndDate,
      }
    },
    computedGrouping(state) {
      // Google ads >= 4  month range groups on week
      // Google ads >= 13 month range groups on month
      // Google ads >= 2  years range groups on quarter
      const start = dayjs(state.startDate)
      const end = dayjs(state.endDate)

      const diffInYears = end.diff(start, 'year')

      if (diffInYears >= 6) {
        return 'year'
      }
      if (diffInYears >= 2) {
        return 'quarter'
      }

      const diffInMonths = end.diff(start, 'month')

      if (diffInMonths >= 11) {
        return 'month'
      }
      if (diffInMonths >= 4) {
        return 'week'
      }

      return 'day'
    },
  },
  actions: {
    setDatesData({ commit }, datesData) {
      commit(TYPE.SET_ALL_DATE_DATA, datesData)

      sessionStorage.setItem(KEY_COMPARE, JSON.stringify(datesData.compare))
      writeDate(KEY.START_DATE, datesData.startDate)
      writeDate(KEY.END_DATE, datesData.endDate)
      writeDate(KEY.COMPARE_START_DATE, datesData.compareStartDate)
      writeDate(KEY.COMPARE_END_DATE, datesData.compareEndDate)
      writeString(KEY.PRESET, datesData.preset)
      writeString(KEY.COMPARE_PRESET, datesData.comparePreset)
    },
  },
  mutations: {
    [TYPE.SET_ALL_DATE_DATA](state, datesData) {
      state = Object.assign(state, datesData)
    },
  },
}
