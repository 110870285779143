const state = {
  checkDetails: {},
  error: null,
}

const mutations = {
  SET_CHECK_DETAILS(state, check) {
    state.checkDetails = check
  },
  SET_LOADING_ERROR(state, error) {
    state.error = error
  },
}

const actions = {
  async fetchCheckDetails({ commit }, { siteId, checkId, reportId }) {
    try {
      const response = await axios.get(`/audit/site/${siteId}/report/${reportId}/check/${checkId}`)
      commit('SET_CHECK_DETAILS', response.data)
    } catch (error) {
      commit('SET_LOADING_ERROR', error)
    }
  },

  // External audit functions using customerId
  // External audit functions using customerId
  // External audit functions using customerId

  //async fetchExternalCheckDetails({ commit }, { customerId, checkId, reportId }) {
  // try {
  //  const response = await axios.get(`/audit/customer/${customerId}/report/${reportId}/check/${checkId}`)
  //  commit('SET_CHECK_DETAILS', response.data)
  //} catch (error) {
  //  console.error('Error fetching external check details:', error)
  //}
  //},
}

const getters = {
  getCheckDetails: state => state.checkDetails,
  getError: state => state.error,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
