import { normalize } from '@/utils/util'

const TYPE = {
  SET_ERROR: 'SET_ERROR',
  SET_ITEMS: 'SET_BILLINGS',
  SET_ITEM: 'SET_BILLING',
  SET_LOAD_CALL: 'SET_LOAD_CALL',
  SET_UPDATE_ERROR: 'SET_UPDATE_ERROR',
  SET_UPDATE_CALL: 'SET_UPDATE_CALL',
  SET_CREATE_ERROR: 'SET_CREATE_ERROR',
  SET_CREATE_CALL: 'SET_CREATE_CALL',
}

export default {
  namespaced: true,
  state: {
    billings: {},
    loadingBillings: false,
    loadCall: null,
    loadBillingsError: null,
    updateCall: null,
    updateError: null,
    createCall: null,
    createError: null,
  },
  getters: {
    getAccountBillings(state) {
      return Object.values(state.billings)
    },
    getLoadCall(state) {
      return state.loadCall
    },
    billingById: state => billingId => {
      return state.billings[billingId]
    },
    isLoadingAccountBillings(state) {
      return !!state.loadCall
    },
    getAccountLoadBillingsError(state) {
      return state.loadBillingsError
    },
    getUpdateBillingError(state) {
      return state.updateError
    },
    getCreateBillingError(state) {
      return state.createError
    },
  },
  actions: {
    updateBilling({ commit }, { billingId, formData }) {
      commit(TYPE.SET_UPDATE_ERROR, null)

      const call = axios
        .put(`/billing/${billingId}`, formData)
        .then(({ data }) => commit(TYPE.SET_ITEM, data.data))
        .catch(e => commit(TYPE.SET_UPDATE_ERROR, e))
        .finally(() => commit(TYPE.SET_UPDATE_CALL, null))
      commit(TYPE.SET_UPDATE_CALL, call)
      return call
    },
    createBilling({ commit }, formData) {
      commit(TYPE.SET_CREATE_ERROR, null)
      const call = axios
        .post(`/billing`, formData)
        .then(({ data }) => commit(TYPE.SET_ITEM, data.data))
        .catch(e => commit(TYPE.SET_CREATE_ERROR, e))
        .finally(() => commit(TYPE.SET_CREATE_CALL, null))
      commit(TYPE.SET_CREATE_CALL, call)
      return call
    },
    async loadAccountBillings({ commit, dispatch }) {
      const account = await dispatch('account/loadAccountIfNeeded', null, { root: true })
      if (!account.billing_id) {
        return Promise.resolve()
      }

      commit(TYPE.SET_ERROR, null)
      const call = axios
        .get(`/billing`)
        .then(({ data }) => commit(TYPE.SET_ITEMS, normalize(data.data)))
        .catch(e => commit(TYPE.SET_ERROR, e))
        .finally(() => commit(TYPE.SET_LOAD_CALL, null))
      commit(TYPE.SET_LOAD_CALL, call)
      return call
    },
    loadAccountBillingsIfNeeded({ dispatch, getters }) {
      if (!!getters.getLoadCall) return getters.getLoadCall

      if (!!getters.getAccountBillings) return Promise.resolve(getters.getAccountBillings)

      return dispatch('loadAccountBillings')
    },
    clear({ commit }) {
      commit(TYPE.SET_ITEMS, null)
      commit(TYPE.SET_ERROR, null)
      commit(TYPE.SET_LOAD_CALL, null)
    },
  },
  mutations: {
    [TYPE.SET_ITEMS](state, billings) {
      state.billings = billings
    },
    [TYPE.SET_ITEM](state, billing) {
      state.billings = {
        ...state.billings,
        [billing.id]: billing,
      }
    },
    [TYPE.SET_LOAD_CALL](state, loadCall) {
      state.loadCall = loadCall
    },
    [TYPE.SET_ERROR](state, error) {
      state.loadBillingsError = error
    },
    [TYPE.SET_UPDATE_ERROR](state, error) {
      state.updateError = error
    },
    [TYPE.SET_CREATE_ERROR](state, error) {
      state.createError = error
    },
    [TYPE.SET_UPDATE_CALL](state, updateCall) {
      state.updateCall = updateCall
    },
    [TYPE.SET_CREATE_CALL](state, createCall) {
      state.createCall = createCall
    },
  },
}
