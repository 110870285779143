import { pick, omit } from '@/utils/ObjectHelpers'

export const amazonStep = async (rootGetters, dispatch, commit, stepConfigurations, TYPE) => {
  const store = rootGetters['store/selectedStore']
  const account = rootGetters['account/getAccount']

  await Promise.all([
    dispatch('billing/loadAccountBillingsIfNeeded', null, { root: true }),
    dispatch('feed/loadFeedsByStoreIdIfNeeded', store.id, { root: true }),
    dispatch('campaign/loadCampaignsByStoreIdIfNeeded', store.id, { root: true }),
  ])

  const billings = rootGetters['billing/getAccountBillings']
  const campaigns = rootGetters['campaign/getCampaignsByStoreId'](store.id)

  if (!billings.length) {
    localStorage.setItem('GMC_NOT_SET', 'true')
  } else {
    localStorage.removeItem('GMC_NOT_SET')
  }

  const GMC_SET = localStorage.getItem('GMC_NOT_SET')

  let omitConfigs = omit(
    stepConfigurations,
    'subscription',
    'productFeed',
    'contentApi',
    'creditCard',
    'billingInformation',
  )

  const picks = ['storeInfo', 'supplementalFeed', 'success']

  let configuration = pick(omitConfigs, ...picks)

  if (store.country_id) {
    configuration = { ...configuration, storeInfo: { ...configuration.storeInfo, completed: true } }
  }
  if (!GMC_SET) {
    configuration = { ...configuration, supplementalFeed: { ...configuration.supplementalFeed, completed: true } }
  }
  if (campaigns.length) {
    configuration = { ...configuration, success: { ...configuration.success, completed: true } }
  }

  const configurationToArray = Object.keys(configuration).map(c => configuration[c])
  console.log(configurationToArray)
  commit(TYPE.SET_STEPS_CONFIGS, configuration)
  commit(TYPE.SET_STEPS, configurationToArray)
}
