import { pick } from '@/utils/ObjectHelpers'

export const freeSteps = async (rootGetters, dispatch, commit, stepConfigurations, TYPE) => {
  const store = rootGetters['store/selectedStore']

  await dispatch('feed/loadFeedsByStoreIdIfNeeded', store.id, { root: true })

  const feedsByStoreId = rootGetters['feed/getFeedsByStoreId'](store.id)

  const firstStep = localStorage.getItem(store.id)

  const picks = ['storeInfo', 'productFeed']

  let configuration = pick(stepConfigurations, ...picks)

  if (store.country_id) {
    configuration = { ...configuration, storeInfo: { ...configuration.storeInfo, completed: true } }
  }
  if (feedsByStoreId.length) {
    configuration = { ...configuration, productFeed: { ...configuration.productFeed, completed: true } }
  }

  const configurationToArray = Object.keys(configuration).map(c => configuration[c])
  commit(TYPE.SET_STEPS_CONFIGS, configuration)
  commit(TYPE.SET_STEPS, configurationToArray)
}
