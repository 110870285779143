<template>
  <div class="search__wrapper">
    <ic-search
      :size="iconSize"
      :class="{ active: inputFocus }"
      class="search__icon"
    />
    <input
      v-model="filterTerm"
      :class="{ active: inputFocus }"
      class="search__input text-sm font-medium border border-neutral-100 border-1 focus:border-bb-brand-purple focus:border-2"
      :placeholder="placeholder"
      type="text"
      @input="$emit('filter-term-changed', filterTerm)"
      @focusin="handleInputFocus"
    />
    <ic-close
      v-if="filterTerm"
      :size="12"
      class="search__clear"
      @click="clear"
    />
  </div>
</template>

<script>
import IcClose from 'vue-material-design-icons/Close.vue'
import IcSearch from 'vue-material-design-icons/Magnify.vue'

export default {
  name: 'SearchBar',
  components: { IcSearch, IcClose },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    iconSize: {
      type: Number,
      default: 24,
    },
  },
  data() {
    return {
      filterTerm: '',
      inputFocus: false,
    }
  },
  methods: {
    handleInputFocus({ type }) {
      // Just to be able to set color on icon when input has focus.
      // Please refactor this to a pure CSS solution if you can, I couldn't :/
      this.inputFocus = type === 'focusin'
    },
    clear() {
      this.filterTerm = ''
      this.$emit('filter-term-changed', null)
    },
  },
}
</script>

<style scoped lang="scss">
.search {
  $search: &;

  &__wrapper {
    @apply flex flex-1 items-center gap-2 text-bb-cool-grey px-6 py-2;

    &:hover {
      #{$search}__input::placeholder,
      #{$search}__icon {
        @apply text-bb-mid-grey;
      }
    }
  }

  &__icon {
    transition: color 200ms ease-out;
    position: relative;
    left: 35px;
    &.active {
      @apply text-bb-mid-grey;
    }
  }

  &__clear {
    position: absolute;
    right: 40px;
  }

  &__input {
    @apply bg-transparent w-full;
    outline: none;
    transition: color 200ms ease-out;
    border-radius: 8px;
    background: var(--Brand-White, #fff);
    padding: var(--Padding-S, 8px) var(--Padding-M, 20px) var(--Padding-S, 8px) var(--Padding-M, 35px);

    &::placeholder {
      transition: color 200ms ease-out;
    }

    &.active {
      @apply text-bb-mid-grey;
      &::placeholder {
        @apply text-bb-mid-grey;
      }
    }
  }

  &__clear {
    cursor: pointer;
    transition: color 200ms ease-out;

    &:hover {
      @apply text-bb-mid-grey;
    }
  }
}
</style>
