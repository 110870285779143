import { normalize } from '@/utils/util'

const TYPE = {
  SET_CURRENCY_ERROR: 'SET_CURRENCY_ERROR',
  SET_INVOICE_ERROR: 'SET_INVOICE_ERROR',
  SET_CURRENCIES: 'SET_CURRENCIES',
  SET_INVOICES: 'SET_INVOICES',
  SET_LOAD_CURRENCIES_CALL: 'SET_LOAD_CURRENCIES_CALL',
  SET_LOAD_INVOICES_CALL: 'SET_LOAD_INVOICES_CALL',
}

/**
 * These currencies are the currencies available for invoices
 */
export default {
  namespaced: true,
  state: {
    currencies: {},
    invoices: {},
    currenciesError: null,
    invoicesError: null,
    loadInvoicesCall: null,
    loadCurrenciesCall: null,
  },
  getters: {
    getCurrencies(state) {
      return Object.values(state.currencies)
    },
    getCurrencyById: state => currencyId => {
      return state.currencies[currencyId]
    },
    isCurrenciesLoading(state) {
      return !!state.loadCurrenciesCall
    },
    getCurrenciesError(state) {
      return state.currenciesError
    },
    getCurrencyLoadCall(state) {
      return state.loadCurrenciesCall
    },
    getInvoiceLoadCall(state) {
      return state.loadInvoicesCall
    },
    getInvoices(state) {
      return Object.values(state.invoices)
    },
    getInvoicesError(state) {
      return state.invoicesError
    },
    isInvoicesLoading(state) {
      return !!state.loadInvoicesCall
    },
  },
  actions: {
    loadCurrencies({ commit }) {
      commit(TYPE.SET_CURRENCY_ERROR, null)
      const call = axios
        .get('/invoice/currency')
        .then(({ data }) => commit(TYPE.SET_CURRENCIES, normalize(data.data)))
        .catch(e => commit(TYPE.SET_CURRENCY_ERROR, e))
        .finally(() => commit(TYPE.SET_LOAD_CURRENCIES_CALL, null))
      commit(TYPE.SET_LOAD_CURRENCIES_CALL, call)
      return call
    },
    loadInvoices({ commit }) {
      commit(TYPE.SET_INVOICE_ERROR, null)

      const call = axios
        .get(`/invoice`)
        .then(({ data }) => commit(TYPE.SET_INVOICES, normalize(data.data)))
        .catch(e => commit(TYPE.SET_INVOICE_ERROR, e))
        .finally(() => commit(TYPE.SET_LOAD_INVOICES_CALL, null))

      commit(TYPE.SET_LOAD_INVOICES_CALL, call)

      return call
    },
    loadInvoicesIfNeeded({ dispatch, getters }) {
      if (!!getters.getInvoiceLoadCall) return getters.getInvoiceLoadCall

      if (getters.getInvoices.length > 0) return Promise.resolve(getters.getInvoices)

      return dispatch('loadInvoices')
    },
    loadCurrenciesIfNeeded({ dispatch, getters }) {
      if (!!getters.getCurrencyLoadCall) return getters.getCurrencyLoadCall

      if (getters.getCurrencies.length > 0) return Promise.resolve(getters.getCurrencies)

      return dispatch('loadCurrencies')
    },
    clear({ commit }) {
      commit(TYPE.SET_CURRENCIES, {})
      commit(TYPE.SET_INVOICES, {})
      commit(TYPE.SET_CURRENCY_ERROR, null)
      commit(TYPE.SET_INVOICE_ERROR, null)
      commit(TYPE.SET_LOAD_CURRENCIES_CALL, null)
      commit(TYPE.SET_LOAD_INVOICES_CALL, null)
    },
  },
  mutations: {
    [TYPE.SET_LOAD_INVOICES_CALL](state, call) {
      state.loadInvoicesCall = call
    },
    [TYPE.SET_LOAD_CURRENCIES_CALL](state, call) {
      state.loadCurrenciesCall = call
    },
    [TYPE.SET_CURRENCY_ERROR](state, error) {
      state.currenciesError = error
    },
    [TYPE.SET_CURRENCIES](state, currencies) {
      state.currencies = currencies
    },
    [TYPE.SET_INVOICE_ERROR](state, error) {
      state.invoicesError = error
    },
    [TYPE.SET_INVOICES](state, invoices) {
      state.invoices = invoices
    },
  },
}
