import { freeSteps } from './steps/freeSteps'
import { proSteps } from './steps/proSteps'
import { proPlusSteps } from './steps/proPlusSteps'
import { enterpriseSteps } from './steps/enterpriseSteps'
import { amazonStep } from './steps/amazonSteps'

const TYPE = {
  SET_STEPS_CONFIGS: 'SET_STEPS_CONFIGS',
  SET_STEPS: 'SET_STEPS',
  SET_SUBSCRIPTION_SLUG: 'SET_SUBSCRIPTION_SLUG',
  SET_LOADING: 'SET_LOADING',
}

const stepConfigurations = {
  subscription: {
    id: 1,
    title: 'Choose your subscription for this store',
    completed_title: 'Subscription Added',
    component: () => import('@/components/onboard-components/Subscription/Subscription'),
    main_step: true,
    completed: false,
  },
  creditCard: {
    id: 2,
    title: 'Add Credit Card',
    completed_title: 'Credit Card Added',
    component: () => import('@/components/onboard-components/CreditCard'),
    main_step: true,
    completed: false,
  },
  billingInformation: {
    id: 3,
    title: 'Billing Information',
    completed_title: 'Billing Information Added',
    component: () => import('@/components/onboard-components/BillingInformation'),
    main_step: true,
    completed: false,
  },
  storeInfo: {
    id: 4,
    title: 'Store Info',
    completed_title: 'Store Info Added',
    component: () => import('@/components/onboard-components/StoreInfo'),
    main_step: true,
    completed: false,
  },
  contentApi: {
    id: 5,
    title: 'Connect Google Merchant Center',
    completed_title: 'Google Merchant Center connected',
    component: () => import('@/components/onboard-components/ContentApi'),
    main_step: true,
    completed: false,
  },
  productFeed: {
    id: 6,
    title: 'Product Feed',
    completed_title: 'Product Feed Added',
    component: () => import('@/components/onboard-components/ProductFeed'),
    main_step: true,
    completed: false,
  },
  supplementalFeed: {
    id: 7,
    title: 'Add Bidbrain supplemental feed to your Merchant Center',
    completed_title: 'Connected Supplemental feed to Merchant Center account',
    component: () => import('@/components/onboard-components/SupplementalFeed'),
    main_step: true,
    completed: false,
  },
  success: {
    id: 8,
    title: 'Well done! You’ve completed setup  🥳',
    completed_title: 'Well done! You’ve completed setup  🥳',
    component: () => import('@/components/onboard-components/StepCompleted'),
    main_step: false,
    completed: false,
  },
}

export default {
  namespaced: true,
  state: {
    subscription_slug: null,
    stepsConfigs: {},
    steps: [],
    isLoading: false,
  },
  getters: {
    stepsConfigs(state) {
      return state.stepsConfigs
    },
    steps(state) {
      return state.steps
    },
    isLoading(state) {
      return state.isLoading
    },
    subscription_slug(state) {
      return state.subscription_slug
    },
    mainStepCompleted(state) {
      return state.steps.filter(step => step.main_step).every(step => step.completed)
    },
    allStepsCompleted(state) {
      return state.steps.every(step => step.completed)
    },
  },
  actions: {
    async onInit({ commit, dispatch, rootGetters, getters }) {
      commit(TYPE.SET_LOADING, true)
      const store = rootGetters['store/selectedStore']
      const storeType = rootGetters['store/storeType']
      const account = rootGetters['account/getAccount']
      if (!store) {
        return commit(TYPE.SET_LOADING, false)
      }
      if (storeType('AMAZON')) {
        await amazonStep(rootGetters, dispatch, commit, stepConfigurations, TYPE)
      } else if (storeType('BIDBRAIN')) {
        if (account.enterprise) {
          await enterpriseSteps(rootGetters, dispatch, commit, stepConfigurations, TYPE)
        } else if (store.subscription_slug === 'free') {
          await freeSteps(rootGetters, dispatch, commit, stepConfigurations, TYPE)
        } else if (store.subscription_slug === 'pro') {
          await proSteps(rootGetters, dispatch, commit, stepConfigurations, TYPE)
        } else if (store.subscription_slug === 'pro_plus') {
          await proPlusSteps(rootGetters, dispatch, commit, stepConfigurations, TYPE)
        }
      }

      commit(TYPE.SET_LOADING, false)
    },
    changeStepState({ state, commit }, steps) {
      // merge the steps with the stepConfiguration
      const stepsKeys = Object.keys(state.stepsConfigs).map(k => {
        const step = steps.find(s => s.id === state.stepsConfigs[k].id)
        if (step) {
          return { key: k, value: step }
        }
      })
      // create a object from the array
      const stepConfig = stepsKeys.reduce((cpObject, item) => {
        return Object.assign(cpObject, { [item.key]: item.value })
      }, {})

      commit(TYPE.SET_STEPS_CONFIGS, stepConfig)
      commit(TYPE.SET_STEPS, steps)
    },
  },
  mutations: {
    [TYPE.SET_STEPS_CONFIGS](state, configs) {
      state.stepsConfigs = configs
    },
    [TYPE.SET_STEPS](state, steps) {
      state.steps = steps
    },
    [TYPE.SET_SUBSCRIPTION_SLUG](state, subscription_slug) {
      state.subscription_slug = subscription_slug
    },
    [TYPE.SET_LOADING](state, loading) {
      state.isLoading = loading
    },
  },
}
