import { normalize } from '@/utils/util'

const TYPE = {
  SET_CURRENCIES: 'SET_CURRENCIES',
  SET_CURRENCIES_CALL: 'SET_CURRENCIES_CALL',
  SET_CURRENCIES_LOADING_ERROR: 'SET_CURRENCIES_LOADING_ERROR',
}

/**
 * All currencies (for "invoice available" currencies, go to invoice vuex module)
 */
export default {
  namespaced: true,
  state: {
    currencies: {},
    currenciesCall: false,
    currenciesLoadingError: null,
  },
  getters: {
    getCurrencies(state) {
      return Object.values(state.currencies)
    },
    getNormalisedCurrencies(state) {
      return state.currencies
    },
    isCurrenciesLoading(state) {
      return !!state.currenciesCall
    },
    getCurrenciesCall(state) {
      return state.currenciesCall
    },
    hasCurrencies(state) {
      return Object.values(state.currencies).length !== 0
    },
    getCurrenciesError(state) {
      return state.currenciesLoadingError
    },
    hasCurrenciesError(state) {
      return state.currenciesLoadingError !== null
    },
    getCurrencyById: state => currencyId => {
      return state.currencies[currencyId]
    },
    getCurrencyBySelectedStore(state, getters, rootState, rootGetters) {
      const selectedStore = rootGetters['store/selectedStore']
      if (!selectedStore || !selectedStore.currency_id) {
        return null
      }
      return state.currencies[selectedStore.currency_id]
    },
  },
  actions: {
    loadCurrenciesIfNeeded({ dispatch, getters }) {
      if (getters.hasCurrencies) return Promise.resolve(getters.hasCurrencies)

      if (!!getters.getCurrenciesCall) return getters.getCurrenciesCall

      return dispatch('loadCurrencies')
    },
    loadCurrencies({ commit }) {
      commit(TYPE.SET_CURRENCIES_LOADING_ERROR, null)

      const call = axios
        .get('/currency')
        .then(res => commit(TYPE.SET_CURRENCIES, normalize(res.data.data)))
        .catch(e => commit(TYPE.SET_CURRENCIES_LOADING_ERROR, e))
        .finally(() => commit(TYPE.SET_CURRENCIES_CALL, null))

      commit(TYPE.SET_CURRENCIES_CALL, call)

      return call
    },
  },
  mutations: {
    [TYPE.SET_CURRENCIES_CALL](state, call) {
      state.currenciesCall = call
    },
    [TYPE.SET_CURRENCIES_LOADING_ERROR](state, error) {
      state.currenciesLoadingError = error
    },
    [TYPE.SET_CURRENCIES](state, currencies) {
      state.currencies = currencies
    },
  },
}
