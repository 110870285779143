<template>
  <component
    v-else
    :is="getIcon"
    :size="size"
    :color="color"
  />
</template>

<script>
export default {
  name: 'bbIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 26,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
  computed: {
    /*
     * the icon props should be just the name of the file ex: 'ic-hands'
     * */
    getIcon() {
      return () => import(`@/components/icon/${this.icon}.vue`)
    },
  },
}
</script>
