const TYPE = {
  SET_SHOW_BOOK_DEMO_MODAL: 'SET_SHOW_BOOK_DEMO_MODAL',
}

/**
 * Delete this when we have a new hub spot form design.
 * Also delete the transparent modal stuff and refactor "book a demo" modal to be just like the other modals!
 */
export default {
  namespaced: true,
  state: {
    showBookDemoModal: false,
  },
  getters: {
    showBookDemoModal(state) {
      return state.showBookDemoModal
    },
  },
  actions: {
    setShowBookDemoModal({ commit }, show) {
      commit(TYPE.SET_SHOW_BOOK_DEMO_MODAL, show)
    },
  },
  mutations: {
    [TYPE.SET_SHOW_BOOK_DEMO_MODAL](state, show) {
      state.showBookDemoModal = show
    },
  },
}
