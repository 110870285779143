<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 130 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M107.445 27.0455H83.6473V30H107.445V27.0455Z"
      :fill="underlineFill"
    />
    <path
      d="M15.5991 11.6301V11.5387C17.9679 10.553 19.4431 8.73911 19.4431 6.36888C19.4431 4.73792 18.92 3.35338 17.8435 2.24575C16.3684 0.739267 14.3398 0 11.7552 0H1.33293H0C0 0 1.33293 1.16352 1.33293 3.27208V24.091H11.9101C14.3398 24.091 16.3684 23.5067 17.9984 22.3686C19.6589 21.2304 20.4891 19.5385 20.4891 17.2928C20.4891 14.3382 18.6433 12.123 15.5991 11.6301ZM7.20546 5.20028H10.6482C12.494 5.20028 13.4157 5.90906 13.4157 7.35457C13.4157 8.70862 12.4306 9.53934 10.7092 9.53934H7.20546V5.20028ZM11.6943 18.8602H7.20546V14.3382H11.6638C13.2633 14.3382 14.4312 15.108 14.4312 16.584C14.4312 18.06 13.2938 18.8602 11.6943 18.8602Z"
      fill="currentColor"
    />
    <path
      d="M25.3232 0C23.7008 0 22.3704 1.33119 22.3704 2.95453C22.3704 3.75223 22.6624 4.44577 23.2489 5.03007C23.8328 5.58896 24.526 5.88111 25.3232 5.88111C26.1483 5.88111 26.8389 5.58896 27.3975 5.03007C27.9814 4.44577 28.2759 3.75223 28.2759 2.95453C28.2759 2.12889 27.984 1.43789 27.4254 0.878991C26.8668 0.29215 26.1483 0 25.3232 0Z"
      fill="currentColor"
    />
    <path
      d="M28.2759 7.66196H22.373V24.091H28.2759V7.66196Z"
      fill="currentColor"
    />
    <path
      d="M42.4635 9.5368H42.3416C41.2346 8.09129 39.729 7.35203 37.7918 7.35203C35.6693 7.35203 33.8565 8.15226 32.2875 9.78322C30.7489 11.3837 29.9821 13.4135 29.9821 15.8447C29.9821 18.2149 30.7209 20.2447 32.1656 21.9367C33.6407 23.5981 35.4865 24.4288 37.67 24.4288C39.6072 24.4288 41.1762 23.6591 42.3746 22.0916H42.4965V24.091H48.3081V0H42.466V9.5368H42.4635ZM41.5393 18.1845C40.28 19.508 38.1879 19.508 36.9261 18.1845C35.6642 16.8609 35.6642 14.8311 36.9261 13.5075C38.1879 12.1839 40.2774 12.1839 41.5393 13.5075C42.8011 14.8285 42.8011 16.8609 41.5393 18.1845Z"
      fill="currentColor"
    />
    <path
      d="M61.6476 7.35457C59.6799 7.35457 58.1108 8.12432 56.9429 9.6308H56.882V0H51.0095V24.091H56.8211V22.1221H56.9429C58.1108 23.6616 59.6494 24.4288 61.5561 24.4288C63.831 24.4288 65.7073 23.5676 67.1519 21.8452C68.5966 20.1228 69.3354 18.1235 69.3354 15.8752C69.3354 13.5685 68.5966 11.5666 67.1519 9.87468C65.7073 8.18275 63.8615 7.35457 61.6476 7.35457ZM60.081 19.1981C59.1899 19.1981 58.4206 18.8602 57.7757 18.2124C57.1308 17.5671 56.8236 16.7669 56.8236 15.8422C56.8236 14.9505 57.1308 14.1502 57.7757 13.505C58.4206 12.8597 59.1899 12.5193 60.081 12.5193C61.9268 12.5193 63.3715 13.9953 63.3715 15.8422C63.3689 17.6916 61.9243 19.1981 60.081 19.1981Z"
      fill="currentColor"
    />
    <path
      d="M77.0055 10.2456H76.975V7.66195H71.1634V24.091H77.0359V17.3232C77.0359 14.5542 78.4501 13.1696 81.248 13.1696C81.771 13.1696 82.2636 13.2001 82.6927 13.2941V7.63147C82.3854 7.54001 82.0148 7.50953 81.6161 7.50953C79.9252 7.50699 77.9576 8.27674 77.0055 10.2456Z"
      fill="currentColor"
    />
    <path
      d="M92.1019 14.3992H91.2412C87.7349 14.3992 83.4619 15.5983 83.4619 19.569C83.4619 20.9535 83.9545 22.0917 84.9066 23.0138C85.8917 23.936 87.0596 24.3984 88.4433 24.3984C90.4719 24.3984 92.1324 23.6591 93.4247 22.1526L93.4856 22.1831V24.091H99.0509V14.0613C99.0509 9.63081 96.1616 7.29361 91.5179 7.29361C87.7349 7.29361 85.1224 8.61718 83.6473 11.2313L87.5521 13.632C88.4128 12.3694 89.5807 11.7546 91.0889 11.7546C92.4726 11.7546 93.2723 12.4939 93.2723 13.3856C93.2698 14.0613 92.8712 14.3992 92.1019 14.3992ZM93.2698 18.0295C93.2698 19.475 92.2542 20.3362 90.7791 20.3362C90.0098 20.3362 89.304 19.7214 89.304 18.9517C89.304 17.999 90.2256 17.5367 91.2412 17.5367H93.2698V18.0295Z"
      fill="currentColor"
    />
    <path
      d="M120.688 7.32153C118.997 7.32153 117.181 8.12177 115.983 9.72225H115.922V7.66195H110.11V24.091H115.953V15.1994C115.953 13.599 116.905 12.6768 118.352 12.6768C119.705 12.6768 120.596 13.8149 120.596 15.1994V24.091H126.438V14.1528C126.438 10.9518 125.024 7.32153 120.688 7.32153Z"
      fill="currentColor"
    />
    <path
      d="M104.492 5.88111C105.317 5.88111 106.008 5.58896 106.566 5.03007C107.15 4.44577 107.445 3.75223 107.445 2.95453C107.445 2.12889 107.153 1.43789 106.594 0.878991C106.035 0.294691 105.317 0 104.492 0C102.869 0 101.539 1.33119 101.539 2.95453C101.539 3.75223 101.831 4.44577 102.418 5.03007C103.004 5.58896 103.695 5.88111 104.492 5.88111Z"
      fill="currentColor"
    />
    <path
      d="M107.445 7.66196H101.542V24.091H107.445V7.66196Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'the-logo',
  props: {
    size: {
      type: String,
      default: 'md',
      validator: val => ['lg', 'md', 'sm'].includes(val),
    },
    underlineFill: {
      type: String,
      default: '#F04BA5',
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    width() {
      switch (this.size) {
        case 'lg':
          return '220'
        case 'sm':
          return '120'
        default:
          return '130'
      }
    },
    height() {
      switch (this.size) {
        case 'lg':
          return '50'
        case 'sm':
          return '26'
        default:
          return '30'
      }
    },
  },
}
</script>
