function changeStatus(current, compare) {
  if (!current || !compare || compare === 0) {
    return 'neutral'
  }
  const change = ((current - compare) / compare) * 100
  if (change > 0) {
    return 'positive'
  } else if (change < 0) {
    return 'negative'
  } else {
    return 'neutral'
  }
}

function getClassBasedOnChange(current, compare, metricType) {
  const status = changeStatus(current, compare)
  let colorClass = 'text-bb-disabled-buttons'
  let rotateClass = 'hidden'
  if (status === 'positive') {
    if (metricType === 'cost') {
      colorClass = 'text-bb-error'
    }
    if (!metricType) {
      colorClass = 'text-bb-success-text'
    }
    rotateClass = 'rotate-180'
  } else if (status === 'negative') {
    if (metricType === 'cost') {
      colorClass = 'text-bb-success-text'
    }
    if (!metricType) {
      colorClass = 'text-bb-error'
    }
    rotateClass = 'rotate-0'
  }

  return { colorClass, rotateClass }
}

function readableMetric(value, currency) {
  if (value === null || value === undefined || isNaN(value)) {
    return '-'
  }
  let formattedValue
  if (Math.floor(value) === value) {
    formattedValue = value
  } else {
    formattedValue = parseFloat(value.toFixed(1))
  }
  return currency ? `${formattedValue} ${currency}` : formattedValue.toString()
}

function calculateCostPerConversion(cost, conversions, currency) {
  if (!conversions || conversions === 0 || !cost) {
    return '-'
  }
  return readableMetric(cost / conversions, currency)
}

function calculateConversionCostPercentageChange(currentCost, currentConversions, compareCost, compareConversions) {
  if (
    !currentConversions ||
    currentConversions === 0 ||
    !currentCost ||
    !compareConversions ||
    compareConversions === 0 ||
    !compareCost
  ) {
    return '-'
  }

  const currentAverage = currentCost / currentConversions
  const compareAverage = compareCost / compareConversions

  if (compareAverage === 0) {
    return '-'
  }

  const percentageChange = ((currentAverage - compareAverage) / compareAverage) * 100
  return (percentageChange > 0 ? '+' : '') + readableMetric(percentageChange)
}

function calculatePercentageChange(current, compare) {
  if (!current || !compare || compare === 0) {
    return '-'
  }
  const change = ((current - compare) / compare) * 100
  return (change > 0 ? '+' : '') + readableMetric(change)
}

function nanSafeDivision(a, b) {
  return b > 0 ? a / b : 0
}

export {
  getClassBasedOnChange,
  changeStatus,
  readableMetric,
  calculateCostPerConversion,
  calculateConversionCostPercentageChange,
  calculatePercentageChange,
  nanSafeDivision,
}
