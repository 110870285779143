export default async function auth({ to, next, store }) {
  const storageUser = localStorage.getItem('user')
  if (store.getters['auth/user'] === null && storageUser !== null) {
    await store.dispatch('auth/SET_USER', JSON.parse(storageUser))
  }
  const user = store.getters['auth/user']

  const nextParams = {
    name: 'signin',
    params: {
      nextUrl: to.fullPath,
    },
  }

  if (user === null) {
    return next(nextParams)
  }

  try {
    const { data: validUser } = await axios.get(`/common/user/me`)
    if (!validUser) {
      return next(nextParams)
    }
  } catch (e) {
    return next(nextParams)
  }

  return next()
}
