const TYPE = {
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_ITEM: 'SET_MANAGER',
}

export default {
  namespaced: true,
  state: {
    manager: null,
    loadingManager: false,
    managerError: null,
  },
  getters: {
    getAccountManager(state) {
      return state.manager
    },
    isLoadingAccountManager(state) {
      return state.loadingManager
    },
    getAccountManagerError(state) {
      return state.managerError
    },
  },
  actions: {
    loadAccountManager({ commit, rootGetters }) {
      const { account } = rootGetters['auth/user']
      if (!account.manager_id) {
        return Promise.resolve(null)
      }

      commit(TYPE.SET_LOADING, true)
      commit(TYPE.SET_ERROR, null)
      return axios
        .get(`/manager/${account.manager_id}`)
        .then(({ data }) => commit(TYPE.SET_ITEM, data.data))
        .catch(e => commit(TYPE.SET_ERROR, e))
        .finally(() => commit(TYPE.SET_LOADING, false))
    },
    loadAccountManagerIfNeeded({ dispatch, getters }) {
      if (getters.isLoadingAccountManager || getters.getAccountManager) {
        return
      }
      return dispatch('loadAccountManager')
    },
    clear({ commit }) {
      commit(TYPE.SET_ITEM, null)
      commit(TYPE.SET_ERROR, null)
      commit(TYPE.SET_LOADING, false)
    },
  },
  mutations: {
    [TYPE.SET_ITEM](state, currencies) {
      state.manager = currencies
    },
    [TYPE.SET_LOADING](state, loading) {
      state.loadingManager = loading
    },
    [TYPE.SET_ERROR](state, error) {
      state.managerError = error
    },
  },
}
