const state = {
  languages: null,
  loading: false,
  error: null,
}

const getters = {
  allLanguages: state => state.languages,
  languageLoadingStatus: state => state.loading,
  languageErrorStatus: state => state.error,
}

const MUTATION_TYPES = {
  SET_LANGUAGES: 'SET_LANGUAGES',
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
}

const actions = {
  async fetchLanguages({ commit, state }) {
    if (state.languages === null) {
      commit(MUTATION_TYPES.SET_LOADING, true)
      try {
        const response = await axios.get('common/language')
        const languages = response.data.map(language => ({
          value: language.code,
          label: language.name,
        }))
        commit(MUTATION_TYPES.SET_LANGUAGES, languages)
      } catch (error) {
        commit(MUTATION_TYPES.SET_ERROR, error.response?.data?.message || error.message)
        commit(MUTATION_TYPES.SET_LANGUAGES, null)
      } finally {
        commit(MUTATION_TYPES.SET_ERROR, null)
        commit(MUTATION_TYPES.SET_LOADING, false)
      }
    }
  },
}

const mutations = {
  [MUTATION_TYPES.SET_LANGUAGES](state, languages) {
    state.languages = languages
  },
  [MUTATION_TYPES.SET_LOADING](state, loading) {
    state.loading = loading
  },
  [MUTATION_TYPES.SET_ERROR](state, error) {
    state.error = error
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
