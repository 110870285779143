<template>
  <div
    :class="{ hover: dropDown }"
    class="dropdown-container"
  >
    <div
      @click="accountSidebar ? $emit('open', !dropDown) : null"
      @mouseenter="!accountSidebar ? $emit('open', true) : null"
      @mouseleave="!accountSidebar ? $emit('open', false) : null"
      class="dropdown px-4 md:pr-8"
    >
      <div
        v-if="dropDown && accountSidebar"
        class="w-8 flex h-full items-center"
      >
        <ic-close />
      </div>
      <div
        v-else
        class="dropdown-img-container"
      >
        <img
          src="/content/images/user-profile-icon.png"
          alt="Account settings"
        />
        <div class="hidden md:inline-block">
          <div class="text-sm font-bold truncate">
            {{ userName }}
          </div>
          <div
            class="text-xs truncate"
            style="max-width: 160px"
          >
            {{ user && user.email }}
          </div>
        </div>
      </div>

      <div
        v-if="dropDown && !accountSidebar"
        :class="{ hover: dropDown }"
        class="dropdown-content"
      >
        <drop-down-account-menu />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DropDownAccountMenu from '@/components/layout/Navbar/AccountMenu/DropDownAccountMenu.vue'
import SideBar from '@/components/shared/Sidebar.vue'
import { isBreakpointLargerThanViewPort } from '@/utils/breakpoints'
import IcClose from 'vue-material-design-icons/Close'

export default {
  name: 'account-dropdown',
  components: { SideBar, DropDownAccountMenu, IcClose },
  props: {
    dropDown: true,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      userName: 'auth/getUserName',
    }),
    accountSidebar() {
      return isBreakpointLargerThanViewPort('s')
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-container {
  @apply h-full flex justify-end items-center  relative;
}

.dropdown {
  @apply w-full h-full cursor-pointer;
  display: inline-block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-img-container {
  @apply h-full flex items-center gap-2;
  width: max-content;
  @screen md {
    @apply mr-2;
  }
}

.dropdown-content {
  @apply absolute right-0 bg-neutral-0;
  width: unset;
  top: $top-bar-height--lg;
  box-shadow: 0 0 0 1px rgba(201, 205, 214, 1);

  @screen md {
    left: 0;
    right: unset;
    width: inherit;
  }
}
</style>
