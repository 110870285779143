import { Idb } from '@/utils/storage/idb/idb'
import { dateRangePresets } from '@/utils/date'
import { exportToCsvAsync } from '@/utils/util'

const dayjs = require('dayjs')

const TYPE = {
  SET_PRODUCTS: 'SET_PRODUCTS',
  DISPLAY_BTN: 'DISPLAY_BTN',
  SET_FILTERS: 'SET_FILTERS',
  SET_RANGE_DATES: 'SET_RANGE_DATES',
  SET_COMPARE_WITH: 'SET_COMPARE_WITH',
  SET_OVERALL_LOAD: 'SET_OVERALL_LOAD',
  SET_TABLE_LOAD: 'SET_TABLE_LOAD',
  SET_SORT: 'SET_SORT',
  SET_CAMPAIGN: 'SET_CAMPAIGN',
  CLEAR_ON_CHANGE_STORE: 'CLEAR_ON_CHANGE_STORE',
  SET_COUNTER: 'SET_COUNTER',
  SET_COLUMN_VISIBLE: 'SET_COLUMN_VISIBLE',
}

const qb = getters => {
  let queryBuilder = {}
  let sortBuilder = {}
  let totalBuilder = null

  if (getters.filters.length) {
    const filters = getters.filters.filter(filter => filter.id !== 0)

    for (const filter of filters) {
      queryBuilder[filter.metric.value] = {
        [filter.modifier.value]: typeof filter.value === 'string' ? filter.value : filter.value.value,
      }
    }
  }

  if (getters.products) {
    totalBuilder = getters.products.pagination.total_products
  }

  if (getters.primaryDateRange) {
    const from = dayjs(getters.primaryDateRange.from).format('YYYY-MM-DD')
    const to = dayjs(getters.primaryDateRange.to).format('YYYY-MM-DD')
    queryBuilder = { ...queryBuilder, date: { greater_than: from, less_than: to } }
  }

  if (getters.selectedCampaign) {
    queryBuilder = { ...queryBuilder, campaign_name: { exact_match: getters.selectedCampaign } }
  }

  const hasSortProperty = getters.headers.some(header => header.sort)
  if (hasSortProperty) {
    const filtered = getters.headers.filter(h => h.sort).sort((a, b) => b.sortOrder - a.sortOrder)
    for (const filteredElement of filtered) {
      sortBuilder = {
        ...sortBuilder,
        [filteredElement.accessibility]: filteredElement.sort,
      }
    }
  }

  return { queryBuilder, sortBuilder, totalBuilder }
}

export default {
  namespaced: true,
  state: {
    overallLoad: false,
    loadingTable: false,
    filters: [],
    dateRanges: {
      primary: null,
    },
    campaign: null,
    counter: null,
    headers: [
      {
        header: 'Product title',
        accessibility: 'product_title',
        slot_name: 'product-title',
        hasSort: false,
        footerAccessibility: true,
      },
      {
        header: 'Title length',
        accessibility: 'product_title_length',
        slot_name: 'product-title-length',
        hasSort: false,
        footerAccessibility: true,
      },
      {
        header: 'Performance label',
        accessibility: 'performance_label',
        slot_name: 'performance-label',
        hasSort: false,
        visible: false,
      },
      { header: 'Item ID', accessibility: 'item_id', slot_name: 'item-id', hasSort: false },
      { header: 'In stock', accessibility: 'availability', slot_name: 'availability', hasSort: false },
      {
        header: 'Bench price',
        accessibility: 'bench_price',
        slot_name: 'bench-price',
        headerSlot: true,
        hasSort: false,
      },
      {
        header: 'Impressions',
        accessibility: 'impressions',
        slot_name: 'impressions',
        hasSort: true,
        footerAccessibility: 'sum_impressions',
      },
      {
        header: 'Avg. CPC',
        accessibility: 'cpc',
        slot_name: 'cpc',
        hasSort: true,
        footerAccessibility: 'average_cpc',
      },
      {
        header: 'Clicks',
        accessibility: 'clicks',
        slot_name: 'clicks',
        hasSort: true,
        footerAccessibility: 'sum_clicks',
        sort: 'desc',
        sortOrder: 0,
      },
      {
        header: 'Conv. value',
        accessibility: 'conv_value',
        slot_name: 'conv-value',
        hasSort: true,
        footerAccessibility: 'sum_conv_value',
      },
      { header: 'Cost', accessibility: 'cost', slot_name: 'cost', hasSort: true, footerAccessibility: 'sum_cost' },
      {
        header: 'ROAS',
        accessibility: 'roas',
        slot_name: 'roas',
        hasSort: true,
        footerAccessibility: 'average_roas',
      },
      {
        header: 'Conversions',
        accessibility: 'conversions',
        slot_name: 'conversions',
        hasSort: true,
        footerAccessibility: 'sum_conversions',
      },
      {
        header: 'Profit',
        accessibility: 'profit',
        slot_name: 'profit',
        headerSlot: true,
        hasSort: true,
        footerAccessibility: 'sum_profit',
      },
    ],
    columnDefs: [
      { 'min-width': '30px' },
      { 'min-width': '250px' },
      { 'min-width': '100px' },
      { 'min-width': '150px' },
      { 'min-width': '100px' },
    ],
    columnVisible: [],
    products: null,
    defaultPage: 1,
    defaultLimit: 50,
  },
  getters: {
    overAllLoad(state) {
      return state.overallLoad
    },
    tableLoading(state) {
      return state.loadingTable
    },
    filters(state) {
      return state.filters
    },
    dateRanges(state) {
      return state.dateRanges
    },
    selectedCampaign(state) {
      return state.campaign
    },
    primaryDateRange(state) {
      return state.dateRanges.primary
    },
    compareWith(state) {
      return state.dateRanges.compareWith
    },
    secondaryDateRange(state) {
      return state.dateRanges.secondary
    },
    headers(state) {
      return state.headers
    },
    products(state) {
      return state.products
    },
    defaultPage(state) {
      return state.defaultPage
    },
    defaultLimit(state) {
      return state.defaultLimit
    },
    columnDefs(state) {
      return state.columnDefs
    },
  },
  actions: {
    async getFilters({ commit, dispatch, getters }) {
      const filters = await dispatch('getIndexDBRecords', 'product-filters', { root: true })
      commit(TYPE.SET_FILTERS, filters)
    },
    async addFilters({ getters, commit, dispatch, rootGetters }, filter) {
      if (filter.id === 0) {
        commit(TYPE.SET_FILTERS, [...getters.filters, filter])
      } else {
        await dispatch('addIndexDBRecord', { record: filter, objectStore: 'product-filters' }, { root: true })
        await dispatch('getFilters')
      }
    },
    async deleteFilter({ getters, commit, dispatch, rootGetters }, filter) {
      if (filter.id === 0) {
        commit(
          TYPE.SET_FILTERS,
          getters.filters.filter(f => f.id !== filter.id),
        )
      } else {
        await dispatch('deleteIndexDBRecord', { id: filter.id, objectStore: 'product-filters' }, { root: true })
        await dispatch('getFilters')
      }
    },
    async clearAllFilters({ dispatch, rootGetters }) {
      await dispatch('deleteAllIndexDBRecords', 'product-filters', { root: true })
      await dispatch('getFilters')
    },
    async getRangeDate({ commit, getters, rootGetters }) {
      if (!getters.dateRanges.primary) {
        const defaultPreset = dateRangePresets('Last 30 days')
        commit(TYPE.SET_RANGE_DATES, { dateRange: defaultPreset, propName: 'primary' })
      }
    },
    async setRangeDates({ getters, commit }, { dateRange, propName }) {
      commit(TYPE.SET_RANGE_DATES, { dateRange, propName })
    },
    toggleCompareWith({ commit }, value) {
      commit(TYPE.SET_COMPARE_WITH, value)
    },
    async getProducts({ commit, dispatch, getters, rootGetters, state }, { query, total }) {
      const store = rootGetters['store/selectedStore']
      const url = new URL(`${axios.defaults.baseURL}/store/${store.id}/product/overview`)

      if (getters.overAllLoad) {
        commit(TYPE.SET_TABLE_LOAD, true)
      }
      if (query) {
        const { queryBuilder, sortBuilder, totalBuilder } = qb(getters)
        url.searchParams.set('page', query.page ? query.page : getters.defaultPage)
        url.searchParams.set('size', query.limit ? query.limit : getters.defaultLimit)

        if (Object.keys(queryBuilder).length) {
          url.searchParams.set('query', JSON.stringify(queryBuilder))
        }

        if (Object.keys(sortBuilder).length) {
          url.searchParams.set('sort', JSON.stringify(sortBuilder))
        }

        if (total) {
          url.searchParams.set('total', JSON.stringify(totalBuilder))
        }
      }
      try {
        const products = await axios.get(url.href)
        commit(TYPE.SET_PRODUCTS, products.data.data)
        commit(TYPE.SET_OVERALL_LOAD, true)
        commit(TYPE.SET_TABLE_LOAD, false)
      } catch (e) {
        commit(TYPE.SET_OVERALL_LOAD, true)
        commit(TYPE.SET_TABLE_LOAD, true)
      }
    },
    async exportAllProductsToCSV({ getters, rootGetters }) {
      const store = rootGetters['store/selectedStore']
      const { queryBuilder, sortBuilder } = qb(getters)
      const url = new URL(`${axios.defaults.baseURL}/store/${store.id}/product/overview`)

      const queryExists = Object.keys(queryBuilder).length
      const sortExists = Object.keys(sortBuilder).length

      if (queryExists) {
        url.searchParams.set('query', JSON.stringify(queryBuilder))
      }

      if (sortExists) {
        url.searchParams.set('sort', JSON.stringify(sortBuilder))
      }

      const csvData = await axios.get(url.href)
      const file = csvData.data

      exportToCsvAsync(`${store.name}-products.csv`, file)
    },
    setDisplayTableBtn({ commit }, btn) {
      commit(TYPE.DISPLAY_BTN, btn)
    },
    sort({ commit, dispatch }, { header, index }) {
      commit(TYPE.SET_COUNTER)
      commit(TYPE.SET_SORT, { header, index })
    },
    setCampaign({ commit }, campaignName) {
      commit(TYPE.SET_CAMPAIGN, campaignName)
    },
    setOverallLoad({ commit }, value) {
      commit(TYPE.SET_OVERALL_LOAD, value)
    },
    clearOnChangeStore({ commit }) {
      commit(TYPE.CLEAR_ON_CHANGE_STORE)
    },
    setColumnsVisibility({ commit }, header) {
      commit(TYPE.SET_COLUMN_VISIBLE, header)
    },
  },
  mutations: {
    [TYPE.SET_PRODUCTS](state, products) {
      state.products = products
    },
    [TYPE.SET_FILTERS](state, filters) {
      state.filters = filters
    },
    [TYPE.SET_RANGE_DATES](state, { dateRange, propName }) {
      state.dateRanges[propName] = dateRange
    },
    [TYPE.SET_COMPARE_WITH](state, value) {
      state.dateRanges.compareWith = value
    },
    [TYPE.SET_OVERALL_LOAD](state, value) {
      state.overallLoad = value
    },
    [TYPE.SET_TABLE_LOAD](state, value) {
      state.loadingTable = value
    },
    [TYPE.SET_SORT](state, { header, index }) {
      state.headers = state.headers.map((h, i) => {
        if (index === i) {
          if (!h.sort) {
            return { ...h, sort: 'asc', sortOrder: state.counter }
          }
          if (h.sort && h.sort === 'desc') {
            return { ...h, sort: 'asc', sortOrder: state.counter }
          } else {
            return { ...h, sort: 'desc', sortOrder: state.counter }
          }
        }
        return h
      })
    },
    [TYPE.SET_COUNTER](state) {
      state.counter = state.counter + 1
    },
    [TYPE.SET_CAMPAIGN](state, campaignName) {
      state.campaign = campaignName
    },
    [TYPE.CLEAR_ON_CHANGE_STORE](state) {
      state.campaign = null
      state.dateRanges = {
        primary: null,
      }
    },
    [TYPE.SET_COLUMN_VISIBLE](state, header) {
      state.headers = state.headers.map(h => {
        if (h.accessibility === header.accessibility) {
          return { ...h, ...header }
        }
        return h
      })
    },
  },
}
