const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

exports.isEmail = text => emailRegex.test(String(text).toLowerCase())

exports.capitalize = text => {
  if (typeof text !== 'string') return text

  return text.charAt(0).toUpperCase() + text.slice(1)
}

exports.isImgUrl = url => {
  const img = new Image()
  img.src = url
  return new Promise(resolve => {
    img.onerror = () => resolve(false)
    img.onload = () => resolve(true)
  })
}

exports.formatImage = image => {
  let url = image.replaceAll(/[["\]\\]+/g, '')
  url = url.split(',')
  if (url.length > 0) {
    url = url[0]
  }
  return url
}

exports.truncate = (str, max_length, suffix, margin) => {
  max_length = max_length | 0
  margin = margin | 0
  if (max_length <= 0 || str.length <= max_length + margin) return str
  if (undefined === suffix || null === suffix) suffix = ''
  return str.slice(0, max_length) + suffix
}
