// eslint-disable-next-line no-undef
module.exports = {
  colors: {
    primary: '#7700ff',
    success: '#18B070',
    danger: '#F04BA5',
    warn: '#FFEEBB',
    dark: '#1e1e1e',
    'bb-purple-darkest': '#161028', // Change to 1C0531 ?
    'purple-0': '#F9F8FF',
    'neutral-0': '#F9FAFB',
    'neutral-100': '#E5E7EB',
    'neutral-50': '#F0F1F3',
    'bb-platinum': '#E0E1E4',
    'bb-light-grey': '#F7F7F7',
    'bb-pale-grey': '#E9EBED',
    'bb-cool-grey': '#C9CDD6',
    'bb-mid-grey': '#878CA0',
    'bb-dark-grey': '#3D3A5C',
    'bb-mustard': '#E09400',

    'bb-light-purple': '#e2d1fc',
    'bb-pale-purple': '#CFB0FF',
    'bb-neon-purple': '#6E19F0',
    'bb-dark-purple': '#392C59',
    'bb-blueberry': '#4F8FF3',
    'bb-blue': '#258AFF',
    'bb-light-blue': '#EFF7FF',
    'bb-blue-1': '#2C8EEF',

    'bb-neon-green': '#3AE995',
    'bb-dull-green': '#39B579',
    'bb-fuchsia-pink': '#C74288',
    'bb-blush-pink': '#FFEFF5',
    'bb-neon-pink': '#F04BA5',
    'bb-dull-red': '#E12166',
    'bb-light-orange': '#f5ddc0',
    'bb-dull-orange': '#DC7828',
    'bb-dull-blue': '#1082A9',
    'bb-dull-yellow': '#FFF3D0',
    'bb-dull-purple': '#F1EEFF',

    'bb-grid-lines': '#D1D1D1',
    'bb-test-red': '#E12160',

    'bb-custom-red': '#F9C7C6',
    'bb-danger-red': '#F56C6F',
    'bb-custom-orange': '#FFEEBB',
    'bb-custom-green': '#DFF1DA',
    'bb-custom-blue': '#CBEBFB',
    'bb-custom-purple': '#8E66C1',

    'google-blue': '#4285F4',

    'bb-brand-purple': '#6366FA',
    'bb-brand-green': '#84FAE4',
    'bb-background-purple-0': '#F9F8FF',
    'bb-purple-600': '#474EC5',
    'bb-purple-10': '#ECEBFF',
    'bb-purple-50': '#EFECFD',
    'bb-purple-100': '#D0CFFE',
    'bb-purple-200': '#ACACFD',
    'bb-purple-300': '#8789FB',
    'bb-secondary-purple': '#F4F2FF',

    'bb-disabled-gray': '#ABADB3',
    'bb-disabled-buttons': '#B7BDC4',
    'bb-neutral-gray': '#6D7883',

    'bb-text-secondary': '#737F8A',
    'bb-text-default': '#3E4552',
    'bb-text-headers': '#0B0B0B',
    'bb-error': '#D7363C',
    'bb-warning': '#FFA800',
    'bb-success-bg': '#D9FFED',
    'bb-success-background': '#EFFFF4',
    'bb-success-text': '#118653',

    'bb-green-accepted': '#0E7144',
    'bb-green-50': '#BDFFF3',
    'bb-red-50': '#FFF1EE',
    'bb-red-100': '#FFDDDC',
    'bb-red-error': '#F23F44',
    'bb-warning-100': '#FFFBDC',
    'bb-warning-200': '#AF6F00',
    'bb-primary-purple': '#6366FA',
    'bb-decorative-yellow': '#FFDB76',
    'bb-decorative-red': '#FFF1EE',
    'bb-yellow-100': '#FFF0C7',
    'bb-yellow-50': '#FFF8EE',

    'bb-transactional': '#7EBBFF',
    'bb-commercial': '#8DECCC',
    'bb-navigational': '#FFDB76',
    'bb-informational': '#FF7FB2',
    'bb-graph-grey': '#AFAFAF',
    'bb-decorative-green': '#0E7144',
    'bb-cyan': '#6EA7E3',
    'bb-decorative-cyan': '#EEF4FF',
    'bb-solid-blue': '#0081FF',
    'bb-yellow-brown': '#DC7700',
    'bb-light-yellow-brown': '#FFF9E9',
    'bb-solid-red': '#C74242',
    'bb-solid-green': '#0FA463',
    'bb-light-green': '#EEFFFA',
    'bb-violet': '#8C32FF',
    'bb-yellow-500': '#FF9900',
  },
}
