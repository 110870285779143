<template>
  <button
    :class="buttonClasses"
    :disabled="disabled"
    @click.prevent="handleClick"
  >
    <slot name="left-icon" />
    <!-- Slot for the left icon -->
    <span>
      <slot>Default Text</slot>
      <!-- Slot for the text in the center -->
    </span>
    <slot name="right-icon" />
    <!-- Slot for the right icon -->
  </button>
</template>

<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: 'primary', // primary, secondary, tertiary
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      const baseClasses = [
        'inline-flex gap-2 items-center justify-center',
        'min-w-30',
        'min-h-10',
        'py-2 px-6',
        'rounded-full',
        'text-sm font-medium',
        'transition delay-100 duration-200 ease-in-out focus:outline-none outline-none',
      ]

      let typeBasedClasses = []
      let stateBasedClasses = []

      switch (this.buttonType) {
        case 'primary':
          if (this.disabled) {
            stateBasedClasses = ['text-white bg-bb-disabled-buttons']
          } else {
            typeBasedClasses = ['text-white bg-bb-text-default hover:bg-bb-brand-purple active:bg-black']
          }
          break
        case 'secondary':
          if (this.disabled) {
            stateBasedClasses = ['text-bb-disabled-buttons bg-white border border-bb-disabled-buttons']
          } else {
            typeBasedClasses = [
              'text-bb-text-default hover:text-black bg-white border border-neutral-50 hover:bg-neutral-50 active:text-bb-brand-purple',
            ]
          }
          break
        case 'tertiary':
          if (this.disabled) {
            stateBasedClasses = ['text-bb-disabled-buttons']
          } else {
            typeBasedClasses = [
              'text-bb-brand-purple hover:bg-neutral-50 hover:text-black active:text-bb-brand-purple active:bg-neutral-50',
            ]
          }
          break
        case 'brand-purple': {
          if (this.disabled) {
            stateBasedClasses = ['text-white bg-bb-disabled-buttons']
          } else {
            typeBasedClasses = ['text-white bg-bb-brand-purple hover:bg-bb-brand-purple-dark active:bg-black']
          }
          break
        }
      }

      return [...baseClasses, ...typeBasedClasses, ...stateBasedClasses]
    },
  },

  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
  },
}
</script>
