<template>
  <div class="sidebar">
    <div
      class="sidebar__main"
      :class="{ active: value, [position]: true }"
    >
      <slot :close="close" />
    </div>
  </div>
</template>

<script>
import { isBreakpointLargerThanViewPort } from '@/utils/breakpoints'

export default {
  name: 'SideBar',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
    },
  },
  methods: {
    close() {
      if (isBreakpointLargerThanViewPort('lg')) {
        this.$emit('input', false)
      }
    },
  },
}
</script>

<style lang="scss">
.sidebar {
  @apply relative;
  &__main {
    @apply bg-white border-r border-neutral-100 fixed bottom-0 text-white overflow-y-auto w-full text-sm;
    top: 103px;
    transition: transform 0.3s;
    z-index: 999;

    &.left {
      @apply left-0;
      transform: translateX(-100%);
    }

    &.right {
      @apply right-0;
      transform: translateX(100%);
    }

    @screen s {
      width: 80%;
      max-width: $sidebar__width;
    }

    @screen lg {
      z-index: 1001;
    }

    &.active {
      transform: translateX(0);
    }
  }
}
</style>
