const TOAST_DURATION = 4000

const TYPE = {
  LOAD_TOAST: 'LOAD_TOAST',
}

const TOASTS = {
  success: 'successToast',
  error: 'errorToast',
  warning: 'warningToast',
}

export default {
  namespaced: true,
  state: {
    // toast: {title: '', message: ''}
    errorToast: null,
    warningToast: null,
    successToast: null,
  },
  getters: {},
  actions: {
    loadToast({ commit }, toast) {
      const toastType = TOASTS[toast.type]
      commit(TYPE.LOAD_TOAST, { toast, type: toastType })
      setTimeout(() => {
        commit(TYPE.LOAD_TOAST, { toast: null, type: toastType })
      }, TOAST_DURATION)
    },
  },
  mutations: {
    [TYPE.LOAD_TOAST](state, { toast, type }) {
      state[type] = toast
    },
  },
}
