<template>
  <div>
    <loader
      v-if="loading"
      :loading="true"
      :spinner="true"
      :container="true"
      :fullpage="true"
      :ignore-header="true"
      text="Loading dashboard"
    />

    <div
      v-else
      class="l-console"
    >
      <top-gradient />
      <navbar
        :sidebar-active="!sidebarActive"
        @toggleMenu="toggleSidebar"
      />
      <sidebar v-model="sidebarActive">
        <sidebar-menu :is-menu-opened="sidebarActive" />
      </sidebar>

      <main
        class="l-console__main"
        :class="{ 'sidebar-padding': sidebarActive }"
      >
        <div class="w-full">
          <console-alerts />
          <transition
            name="page-fade"
            mode="out-in"
            tag="div"
            @before-enter="scrollToTop"
          >
            <router-view />
          </transition>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import * as Sentry from '@sentry/vue'
import Loader from '@/components/loader/Loader'
import Toast from '@/components/shared/Toast'
import { isBreakpointLargerThanViewPort } from '@/utils/breakpoints'
import ConsoleAlerts from '@/components/shared/ConsoleAlerts'
import Sidebar from '@/components/shared/Sidebar.vue'
import SidebarMenu from '@/components/layout/Sidebar/SidebarMenu.vue'
import Navbar from '@/components/layout/Navbar/Navbar.vue'
import TopGradient from '@/components/layout/TopGradient.vue'

export default {
  name: 'ConsoleDefault',
  components: {
    TopGradient,
    SidebarMenu,
    Navbar,
    Sidebar,
    ConsoleAlerts,
    Loader,
  },
  data() {
    return {
      sidebarActive: this.sidebarPrerequisites(),
      accountSidebarActive: this.accountSidebar,
      loading: true,
      isAccountLevel: false,
      // Add more routes here to disable sidebar
      routesWidthDisabledSidebar: ['portfolio'],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      userName: 'auth/getUserName',
      store: 'store/selectedStore',
      selectedStoreId: 'store/selectedStoreId',
      stores: 'store/stores',
      initStoresFetchCompleted: 'store/initialFetchDone',
      displayMeetingBooker: 'modal/showBookDemoModal',
      httpError: 'feedback/getApiErrorMessage',
      account: 'account/getAccount',
    }),
    ...mapState('toast', ['successToast', 'warningToast', 'errorToast']),
    isProduction() {
      return window.location.host.startsWith('app.brightbid')
    },
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (isBreakpointLargerThanViewPort('lg') && to?.name !== from?.name) {
          this.sidebarActive = false
          this.accountSidebarActive = false
        }
        this.sidebarActive = !this.routesWidthDisabledSidebar.some(route => to?.path.includes(route))
      },
      deep: true,
      immediate: true,
    },
    selectedStoreId(storeId) {
      Sentry.setContext('store', { id: storeId })
    },
    httpError(errorMessage) {
      if (errorMessage) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: errorMessage,
            type: 'error',
          },
        })
      }
    },
    successToast(toast) {
      if (toast) {
        this.$toast.success({
          component: Toast,
          props: {
            title: toast.title || 'Success',
            message: toast.message,
            type: 'success',
          },
        })
      }
    },
    warningToast(toast) {
      if (toast) {
        this.$toast.warning({
          component: Toast,
          props: {
            title: toast.title || 'Warning',
            message: toast.message,
            type: 'warning',
          },
        })
      }
    },
    errorToast(toast) {
      if (toast) {
        this.$toast.error({
          component: Toast,
          props: {
            title: toast.title || 'Error',
            message: toast.message,
            type: 'error',
          },
        })
      }
    },
  },
  async beforeMount() {
    /** Quickfix for transition background issue */
    document.body.classList.add('console')

    Sentry.setUser({ user_id: this.user?.id })
    await this.setHubEnvironment()

    /** Preload data */
    await this.getUserOrganizations()

    // await this.fetchInitialConsoleData()
    this.loading = false
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (!this.routesWidthDisabledSidebar.some(route => this.$route?.path.includes(route))) {
        this.sidebarActive = this.sidebarPrerequisites()
      }
    })

    this.$gtm.enable(this.isProduction)

    window.addEventListener('message', this.demoBookedListener)
    this.removeAdminQueryParams()
  },
  beforeDestroy() {
    window.removeEventListener('message', this.demoBookedListener)
    /**
     * Quickfix for transition background issue
     */
    document.body.classList.remove('console')
  },
  methods: {
    ...mapActions({
      setShowMeetingBooker: 'modal/setShowBookDemoModal',
      fetchInitialConsoleData: 'fetchInitialConsoleData',
      loadUserOrganizationList: 'organization/loadUserOrganizationList',
      setHubEnvironment: 'hub/setHubEnvironment',
    }),
    scrollToTop() {
      // eslint-disable-next-line no-undef
      seamless.scrollIntoView(document.querySelector('.l-console'))
    },
    sidebarPrerequisites() {
      return !isBreakpointLargerThanViewPort('lg')
    },
    toggleSidebar() {
      this.sidebarActive = !this.sidebarActive
      if (isBreakpointLargerThanViewPort('s')) {
        this.accountSidebarActive = false
      }
    },
    demoBookedListener(event) {
      if (event.origin != 'https://meetings.hubspot.com') return false
      if (event.data.meetingBookSucceeded) {
        this.$gtm.trackEvent({
          event: 'book_meeting_success',
          event_source: 'book_meeting_form',
          project: 'console',
        })
      }
    },
    removeAdminQueryParams() {
      if (!this.$route.query.store_id && !this.$route.query.mtoken) {
        // nothing to clean
        return
      }
      const query = Object.assign({}, this.$route.query)
      delete query.store_id
      delete query.mtoken
      this.$router.replace({ query })
    },
    async getUserOrganizations() {
      try {
        await this.loadUserOrganizationList(this.user.id)
      } catch (error) {
        console.log(error)
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch the organizations',
            type: 'error',
          },
        })
        return [] // return blank array
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.l-console {
  max-width: 100%;
  width: 100%;
  position: absolute;
  overflow-x: hidden;

  &__main {
    @apply flex flex-col h-full justify-between;
    @screen lg {
      &.sidebar-padding {
        margin-left: 224px;
      }
    }
  }
}
</style>
