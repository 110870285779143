export class BaseIdb {
  async getIndexResults(db, objectStore) {
    return new Promise(resolve => {
      const transaction = db.transaction([objectStore], 'readonly')
      const index = transaction.objectStore(objectStore)
      const query = index.getAll()

      query.onsuccess = function () {
        resolve(query.result)
      }
    })
  }
  async save(db, objectStore, value) {
    return new Promise(resolve => {
      const transaction = db.transaction(objectStore, 'readwrite')
      const store = transaction.objectStore(objectStore)
      store.put(value)

      transaction.oncomplete = () => {
        resolve()
      }
    })
  }
  async delete(db, objectStore, id) {
    return new Promise(resolve => {
      let trans = db.transaction(objectStore, 'readwrite')
      trans.oncomplete = () => {
        resolve()
      }

      let store = trans.objectStore(objectStore)
      store.delete(id)
    })
  }
  async clearAll(db, objectStore) {
    return new Promise((resolve, reject) => {
      let trans = db.transaction(objectStore, 'readwrite')
      trans.oncomplete = () => {
        resolve(true)
      }
      trans.onerror = () => {
        reject('Transaction not opened due to error')
      }

      let store = trans.objectStore(objectStore)
      store.clear()
    })
  }
}
