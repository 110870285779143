const TYPE = {
  SET_LAST_ERROR: 'SET_LAST_ERROR',
  SET_LAST: 'SET_LAST',
  SET_LOADING_PENDING: 'SET_LOADING_PENDING',
  SET_PENDING_ERROR: 'SET_PENDING_ERROR',
  SET_PENDING: 'SET_PENDING',
  SET_LOAD_CALL: 'SET_LOAD_CALL',
  SET_ERROR: 'SET_ERROR',
  SET_ITEMS: 'SET_ITEMS',
}

export default {
  namespaced: true,
  state: {
    items: {},
    loadCalls: {},
    loadErrors: {},
    pendingChecks: {},
    lastChecks: {},
    loadingPendingChecks: {},
    errorsPendingChecks: {},
    errorsLastChecks: {},
  },
  getters: {
    loadingPendingByFeedId: state => feedId => {
      return state.loadingPendingChecks[feedId]
    },
    loadingLastByFeedId: state => feedId => {
      return !!state.loadCalls[feedId]
    },
    errorPendingByFeedId: state => feedId => {
      return state.errorsPendingChecks[feedId]
    },
    errorLastByFeedId: state => feedId => {
      return state.errorsLastChecks[feedId]
    },
    pendingByFeedId: state => feedId => {
      return state.pendingChecks[feedId]
    },
    lastByFeedId: state => feedId => {
      return state.lastChecks[feedId]
    },
  },
  actions: {
    loadLastIfNeeded({ getters, dispatch }, feedId) {
      if (!getters.loadingLastByFeedId(feedId) && !getters.lastByFeedId(feedId))
        return dispatch('loadLastByFeedId', feedId)
    },

    loadLastByFeedId({ commit }, feedId) {
      commit(TYPE.SET_LAST_ERROR, { feedId, e: null })

      const call = axios
        .get(`/feed/${feedId}/check/latest`)
        .then(({ data }) => {
          let latestFeedCheck = data.data

          latestFeedCheck.feed_check_items = latestFeedCheck.feed_check_items.map(item => {
            let diff
            if (item.result.latest && item.result.previous) {
              if (typeof item.result.latest.value === 'number') {
                diff = item.result.latest.value - item.result.previous.value
              } else {
                diff = item.result.latest.value !== item.result.previous.value ? 'Changed' : 'No change'
              }
            } else {
              diff = 'No previous value'
            }
            return {
              ...item,
              diff: diff,
            }
          })
          commit(TYPE.SET_LAST, { feedId, check: data.data })
        })
        .catch(e => commit(TYPE.SET_LAST_ERROR, { feedId, e }))
        .finally(() => commit(TYPE.SET_LOAD_CALL, { feedId, call: null }))

      commit(TYPE.SET_LOAD_CALL, { feedId, call })
      return call
    },

    loadPendingIfNeeded({ getters, dispatch }, feedId) {
      if (!getters.loadingPendingByFeedId(feedId) && !getters.pendingByFeedId(feedId))
        return dispatch('loadPendingByFeedId', feedId)
    },

    loadPendingByFeedId({ commit }, feedId) {
      commit(TYPE.SET_LOADING_PENDING, { feedId, loading: true })
      commit(TYPE.SET_PENDING_ERROR, { feedId, e: null })

      const call = axios
        .get(`/feed/${feedId}/check/pending`)
        .then(({ data }) =>
          commit(TYPE.SET_PENDING, { feedId, check: data }, TYPE.SET_LOADING_PENDING, { feedId, loading: false }),
        )
        .catch(e => commit(TYPE.SET_PENDING_ERROR, { feedId, e }, TYPE.SET_LOADING_PENDING, { feedId, loading: false }))
        .finally(() => commit(TYPE.SET_LOAD_CALL, { feedId, call: null }))

      commit(TYPE.SET_LOAD_CALL, { feedId, call })

      return call
    },
  },
  mutations: {
    [TYPE.SET_LAST_ERROR](state, { feedId, e }) {
      state.errorsLastChecks = {
        ...state.errorsLastChecks,
        [feedId]: e,
      }
    },
    [TYPE.SET_LAST](state, { feedId, check }) {
      state.lastChecks = {
        ...state.lastChecks,
        [feedId]: check,
      }
    },
    [TYPE.SET_LOADING_PENDING](state, { feedId, loading }) {
      state.loadingPendingChecks = {
        ...state.loadingPendingChecks,
        [feedId]: loading,
      }
    },
    [TYPE.SET_PENDING_ERROR](state, { feedId, e }) {
      state.errorsPendingChecks = {
        ...state.errorsPendingChecks,
        [feedId]: e,
      }
    },
    [TYPE.SET_PENDING](state, { feedId, check }) {
      state.pendingChecks = {
        ...state.pendingChecks,
        [feedId]: check,
      }
    },
    [TYPE.SET_LOAD_CALL](state, { feedId, call }) {
      state.loadCalls = {
        ...state.loadCalls,
        [feedId]: call,
      }
    },
    [TYPE.SET_ERROR](state, { feedId, e }) {
      state.loadErrors = {
        ...state.loadErrors,
        [feedId]: e,
      }
    },
    [TYPE.SET_ITEMS](state, { feedId, data }) {
      state.items = {
        ...state.items,
        [feedId]: data,
      }
    },
  },
}
