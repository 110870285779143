import axios from 'axios'
import router from '../../router'
import store from '../../store'
import * as Sentry from '@sentry/vue'

const instanceWithErrorInterceptor = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BFF_URL,
})

export const rawAxios = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BFF_URL,
})

/**
 * Handle unauthenticated requests and forbidden requests
 */
rawAxios.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      await store.dispatch('auth/signOut', true)
      if (router.history.current.name !== 'signin') {
        await router.push({
          name: 'signin',
        })
      }
    } else if (error.response && error.response.status === 403) {
      await store.dispatch('feedback/setApiErrorMessage', 'You do not have permission to perform this action')
    }
    return Promise.reject(error)
  },
)

/**
 * Handle response
 */
instanceWithErrorInterceptor.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      await store.dispatch('auth/signOut', true)
      if (router.history.current.name !== 'signin') {
        await router.push({
          name: 'signin',
        })
      }
    } else if (error.toJSON().message === 'Network Error') {
      await store.dispatch('feedback/setApiErrorMessage', 'No internet connection')
    } else if (error.response && error.response.status === 403) {
      await store.dispatch('feedback/setApiErrorMessage', 'You do not have permission to perform this action')
    } else if (
      !(error.response.status === 400 && error.response.data && error.response.data.error) &&
      !!error.response
    ) {
      Sentry.captureException(error)
      // await store.dispatch('feedback/setApiErrorMessage', 'Something went wrong')
    }
    return Promise.reject(error)
  },
)

export default instanceWithErrorInterceptor
