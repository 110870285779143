var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"site-select__wrapper mt-4 rounded-b"},[_c('div',[_c('search-bar',{ref:"siteSearch",attrs:{"placeholder":"","icon-size":16},on:{"filter-term-changed":_vm.filterTerm}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectedOrganization),expression:"!selectedOrganization"}],staticClass:"divide-y"},[_c('router-link',{attrs:{"to":{ name: 'portfolio' }}},[_c('p',{staticClass:"text-bb-text-default my-4 px-6"},[_vm._v("Portfolio")])]),_c('div',{staticClass:"w-full text-center py-4 px-6"},[_c('div',{staticClass:"w-full p-1 bg-neutral-50 rounded-full my-auto justify-start items-center inline-flex"},[_c('div',{class:{
              'w-1/2 py-1 rounded-full justify-center items-center gap-3 flex cursor-pointer': true,
              'text-bb-text-secondary font-bold ': _vm.portfolioViewMode !== 'site',
              'text-bb-brand-purple bg-white': _vm.portfolioViewMode == 'site',
            },on:{"click":function($event){return _vm.togglePortfolioViewMode('site')}}},[_c('div',{staticClass:"p2 font-bold"},[_vm._v("Sites")])]),_c('div',{class:{
              'w-1/2 py-1 rounded-full justify-center items-center gap-3 flex cursor-pointer': true,
              'text-bb-text-secondary ': _vm.portfolioViewMode !== 'organization',
              'text-bb-brand-purple  bg-white': _vm.portfolioViewMode == 'organization',
            },on:{"click":function($event){return _vm.togglePortfolioViewMode('organization')}}},[_c('div',{staticClass:"p2 font-bold"},[_vm._v("Organizations")])])])])],1),_c('div',[_c('OrganizationDropdownMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.portfolioViewMode === 'organization'),expression:"portfolioViewMode === 'organization'"}],attrs:{"organizations":_vm.filteredOrganizationList},on:{"close":_vm.close}}),_c('SiteDropdownMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.portfolioViewMode === 'site'),expression:"portfolioViewMode === 'site'"}],attrs:{"sites":_vm.filteredSites},on:{"close":_vm.close}})],1),(!_vm.standardUser)?_c('div',{staticClass:"py-5 flex justify-center border border-t-2 border-gray-200 rounded-b",on:{"click":_vm.addSiteOrOrganization}},[_vm._m(0)]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"text-lg mr-2"},[_vm._v("+")]),_vm._v(" Add Organization")])
}]

export { render, staticRenderFns }