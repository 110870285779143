import { BaseIdb } from '@/utils/storage/idb/base-idb'

export class Idb extends BaseIdb {
  DB_VERSION = 1
  DB
  name

  constructor(name) {
    super()

    if (name) {
      this.name = name
    }
  }

  async getDB() {
    return new Promise((resolve, reject) => {
      const indexedDB =
        window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB

      if (!indexedDB) {
        reject('IndexedDb not supported on this browser')
      }

      const request = indexedDB.open(this.name, this.DB_VERSION)

      request.onerror = e => {
        console.error('Error opening db', e)
        reject('Error')
      }

      request.onsuccess = e => {
        this.DB = e.target.result
        resolve(this.DB)
      }

      request.onupgradeneeded = e => {
        indexedDB.databases().then(dbs => {
          const filterDbs = dbs.filter(db => db.name.includes('product/overview/') || db.name.includes('searchTerms/'))
          for (const filterDb of filterDbs) {
            indexedDB.deleteDatabase(filterDb.name)
          }
        })
        const db = e.target.result
        const productOverviewfilterStore = db.createObjectStore('product-filters', {
          autoIncrement: true,
          keyPath: 'id',
        })
        productOverviewfilterStore.createIndex('filter', ['metric', 'modifier'], { unique: true })

        const searchTermsOverviewfilterStore = db.createObjectStore('searchTerms-filters', {
          autoIncrement: true,
          keyPath: 'id',
        })
        searchTermsOverviewfilterStore.createIndex('filter', ['metric', 'modifier'], { unique: true })
      }
    })
  }
}
