import { PERMISSIONS, RESOURCES } from '../../../core/constants'

export const MODULE_NAME = 'user'

const MUTATIONS = {
  SET_USERS: 'SET_USERS',
  SET_IS_INVITING_USER: 'SET_IS_INVITING_USER',
  SET_USERS_IS_LOADING: 'SET_USERS_IS_LOADING',
  SET_USERS_LOADING_ERROR: 'SET_USERS_LOADING_ERROR',
  SET_INVITE_USER_ERROR: 'SET_INVITE_USER_ERROR',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_PERMISSIONS_IS_LOADING: 'SET_PERMISSIONS_IS_LOADING',
  SET_PERMISSIONS_LOADING_ERROR: 'SET_PERMISSIONS_LOADING_ERROR',
}

export const ACTIONS = {
  INVITE_USER: 'inviteUser',
  LOAD_USERS: 'loadUsers',
  CLEAR: 'clear',
  LOAD_PERMISSIONS: 'loadPermissions',
  IS_ALLOWED: 'isAllowed',
}

export default {
  namespaced: true,
  state: {
    users: [],
    permissions: [],
    isLoadingUsers: false,
    isInvitingUser: false,
    isPermissionsLoading: false,
    usersLoadingError: null,
    inviteUserError: null,
    permissionsLoadingError: null,
  },
  getters: {
    users(state) {
      return state.users
    },
    permissions(state) {
      return state.permissions
    },
    isUsersLoading(state) {
      return state.isLoadingUsers
    },
    isInvitingUser(state) {
      return state.isInvitingUser
    },
    usersLoadingError(state) {
      return state.usersLoadingError
    },
    inviteUserError(state) {
      return state.inviteUserError
    },
    isPermissionsLoading(state) {
      return state.isPermissionsLoading
    },
    isPermissionsLoadingError(state) {
      return state.permissionsLoadingError
    },
    userOptions(state) {
      return state.users.map(user => ({
        label: user.name,
        value: user.email,
        id: user.id,
      }))
    },
  },
  actions: {
    async [ACTIONS.INVITE_USER]({ commit, dispatch }, userData) {
      commit(MUTATIONS.SET_IS_INVITING_USER, true)
      commit(MUTATIONS.SET_INVITE_USER_ERROR, null)

      try {
        await axios.post('/user', userData)
        dispatch('loadUsers')
      } catch (e) {
        if (e.response && e.response.data && e.response.data.error === 'Email already exists!') {
          commit(MUTATIONS.SET_INVITE_USER_ERROR, 'This user is already connected to an account!')
        } else {
          commit(MUTATIONS.SET_INVITE_USER_ERROR, e)
        }
      }

      commit(MUTATIONS.SET_IS_INVITING_USER, false)
    },
    async [ACTIONS.LOAD_USERS]({ commit }) {
      commit(MUTATIONS.SET_USERS_IS_LOADING, true)
      commit(MUTATIONS.SET_USERS_LOADING_ERROR, null)

      try {
        const { data: users } = await axios.get('/common/user')
        commit(MUTATIONS.SET_USERS, users)
      } catch (error) {
        commit(MUTATIONS.SET_USERS_LOADING_ERROR, error)
      } finally {
        commit(MUTATIONS.SET_USERS_IS_LOADING, false)
      }
    },
    async [ACTIONS.LOAD_PERMISSIONS]({ commit }) {
      commit(MUTATIONS.SET_PERMISSIONS_IS_LOADING, true)
      commit(MUTATIONS.SET_PERMISSIONS_LOADING_ERROR, null)

      try {
        const { data } = await axios.post('/common/user/allowed_actions')
        commit(MUTATIONS.SET_PERMISSIONS, data)
      } catch (error) {
        commit(MUTATIONS.SET_PERMISSIONS_LOADING_ERROR, error)
      } finally {
        commit(MUTATIONS.SET_PERMISSIONS_IS_LOADING, false)
      }
    },
    async [ACTIONS.IS_ALLOWED]({ state, dispatch }, { resourceSlug, permissionSlug }) {
      if (!Object.values(RESOURCES).includes(resourceSlug)) {
        throw `${resourceSlug} resource not known!`
      }

      if (!Object.values(PERMISSIONS).includes(permissionSlug)) {
        throw `${permissionSlug} permission not known!`
      }

      if (state.permissions.length === 0) {
        await dispatch('loadPermissions')
      }

      if (state.permissions[resourceSlug] !== undefined && state.permissions[resourceSlug].includes(permissionSlug)) {
        return true
      }

      return false
    },
    [ACTIONS.CLEAR]({ commit }) {
      commit(MUTATIONS.SET_USERS, [])
      commit(MUTATIONS.SET_USERS_LOADING_ERROR, null)
      commit(MUTATIONS.SET_USERS_IS_LOADING, false)
      commit(MUTATIONS.SET_IS_INVITING_USER, false)
      commit(MUTATIONS.SET_INVITE_USER_ERROR, null)
    },
  },
  mutations: {
    [MUTATIONS.SET_USERS](state, users) {
      state.users = users
    },
    [MUTATIONS.SET_PERMISSIONS](state, permissions) {
      state.permissions = permissions
    },
    [MUTATIONS.SET_USERS_IS_LOADING](state, isLoading) {
      state.isLoadingUsers = isLoading
    },
    [MUTATIONS.SET_IS_INVITING_USER](state, isInviting) {
      state.isInvitingUser = isInviting
    },
    [MUTATIONS.SET_USERS_LOADING_ERROR](state, error) {
      state.usersLoadingError = error
    },
    [MUTATIONS.SET_INVITE_USER_ERROR](state, error) {
      state.inviteUserError = error
    },
  },
}
