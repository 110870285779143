const dayjs = require('dayjs')

/**
 * Adds some aggregated fields to the campaign.
 * @param campaign
 * @returns {{status}|*}
 */
exports.campaignTransformer = campaign => {
  campaign.pretty_status = campaign.status ? campaign.status.replace('_', ' ') : 'N/A'
  campaign.readable_created_at = dayjs(campaign.created_at).format('YYYY-MM-DD HH:mm')
  campaign.alt_text = `Campaign id #${campaign.id}, created ${campaign.readable_created_at}`

  switch (true) {
    case campaign.status === 'draft':
      campaign.pretty_product_count = 'Draft (No products live)'
      break
    case campaign.product_count_status_slug === 'updating':
      campaign.pretty_product_count = 'Updating'
      break
    case campaign.product_count !== null:
      campaign.pretty_product_count = new Intl.NumberFormat().format(campaign.product_count)
      break
    default:
      campaign.pretty_product_count = 'N/A'
  }

  return campaign
}
