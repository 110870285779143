<template>
  <button
    :class="buttonClasses"
    :disabled="disabled"
    @click="handleClick"
  >
    <slot name="left-icon"></slot>
    <!-- Slot for the left icon -->
    <span>
      <slot>Default Text</slot>
      <!-- Slot for the text in the center -->
    </span>
    <slot name="right-icon"></slot>
    <!-- Slot for the right icon -->
  </button>
</template>

<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: 'primary', // primary, secondary, tertiary
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      const baseClasses = [
        'inline-flex',
        'min-w-30',
        'h-10',
        'py-2',
        'px-6',
        'gap-2',
        'justify-center',
        'items-center',
        'rounded-sm',
        'font-medium',
        'text-sm',
        'transition delay-100 duration-200 ease-in-out focus:outline-none outline-none',
      ]

      let typeBasedClasses = []
      let stateBasedClasses = []

      switch (this.buttonType) {
        case 'primary':
          if (this.disabled) {
            stateBasedClasses = ['text-white cursor-not-allowed bg-bb-disabled-buttons']
          } else {
            typeBasedClasses = ['text-white bg-bb-text-default hover:bg-bb-brand-purple active:bg-black']
          }
          break
        case 'secondary':
          if (this.disabled) {
            stateBasedClasses = [
              'text-bb-disabled-buttons cursor-not-allowed bg-white border border-bb-disabled-buttons',
            ]
          } else {
            typeBasedClasses = [
              'text-black bg-white border border-neutral-50 hover:bg-neutral-50 active:text-bb-brand-purple',
            ]
          }
          break
        case 'tertiary':
          if (this.disabled) {
            stateBasedClasses = ['text-bb-disabled-buttons cursor-not-allowed']
          } else {
            typeBasedClasses = [
              'text-bb-brand-purple hover:bg-neutral-50 hover:text-black active:text-bb-brand-purple active:bg-neutral-50',
            ]
          }
          break
        case 'google':
          if (this.disabled) {
            stateBasedClasses = ['text-bb-disabled-buttons cursor-not-allowed']
          } else {
            typeBasedClasses = ['text-white bg-google-blue']
          }
      }

      return [...baseClasses, ...typeBasedClasses, ...stateBasedClasses]
    },
  },

  methods: {
    handleClick(e) {
      e.preventDefault()
      if (!this.disabled) {
        this.$emit('click')
      }
    },
  },
}
</script>
