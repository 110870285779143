const dayjs = require('dayjs')

/**
 * Adds some aggregated fields to the feed
 * @param feed
 * @returns {{status}|*}
 */
exports.feedTransformer = feed => {
  const readable_created_at = dayjs(feed.created_at).format('YYYY-MM-DD HH:mm')
  const alt_text = `Campaign id #${feed.id}, created ${readable_created_at}`
  return {
    ...feed,
    readable_created_at,
    alt_text,
  }
}
