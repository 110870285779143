export default async function productionDisabled({ to, next, store }) {
  if (process.env.VUE_APP_ENVIRONMENT === 'production') {
    console.log('Production is disabled')
    return next({
      name: 'dashboard',
      params: {
        nextUrl: to.fullPath,
      },
    })
  }
  return next()
}
