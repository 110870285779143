import { isAllChartsAvailable } from '@/core/pending'
import { normalize } from '@/utils/util'
import { readRecentStoreIds, writeRecentStoreIds, writeSelectedStoreId } from '@/utils/storage/local'
import * as Sentry from '@sentry/vue'
import dayjs from 'dayjs'

const TYPE = {
  SET_STORES: 'SET_STORES',
  SET_RECENT_STORE: 'SET_RECENT_STORE',
  SET_RECENT_STORES: 'SET_RECENT_STORES',
  SET_IS_FETCHING_STORES: 'SET_IS_FETCHING_STORES',
  SET_FETCHING_STORES_ERROR: 'SET_FETCHING_STORES_ERROR',
  SET_SELECTED_STORE_ID: 'SET_SELECTED_STORE_ID',
  SET_IS_LOADING_STORE: 'SET_IS_LOADING_STORE',
  SET_FETCHING_STORE_DETAILS_ERROR: 'SET_FETCHING_STORE_ERROR',
  SET_STORE: 'SET_STORE',
  SET_INITIAL_FETCH_DONE: 'SET_INITIAL_FETCH_DONE',
  SET_UPDATE_CALL: 'SET_UPDATE_CALL',
  SET_UPDATE_ERROR: 'SET_UPDATE_ERROR',
}

/**
 * This is not perfect, but it is what we got.
 *
 * "Store details" is fetched from multiple endpoints and merged into one store object which has a prop 'meta' containing
 * all the merged data. That meta prop is updated separately from the other props on our Store object.
 *
 * These detailed Stores is stored in the listing of stores.
 * (Wanna update the listing? Dont forget to fetch the detailed version of the currently selected store)
 *
 * Also, selecting a store automatically fetches the 'detailed version' and replaces the 'list version' with it.
 *
 * This is slowly being refactored away. The goal is to have NO meta. Also, we want to remove the "store details" from
 * the "store list", making it possible to refresh the list without losing any details.
 *
 * Have fun!
 */
export default {
  namespaced: true,
  state: {
    selectedStoreId: null,
    fetchingStoreDetails: false,
    fetchingStoreError: null,
    stores: {},
    initialFetchDone: false,
    fetchingStores: false,
    fetchingStoresError: null,
    updateCalls: {},
    updateError: {},
    recentStores: [],
  },
  getters: {
    stores(state) {
      return Object.values(state.stores)
    },
    recentStores(state) {
      return state.recentStores
    },
    storesGoogleAdsCustomerIds(state) {
      return Object.values(state.stores).map(item => item.google_ads_customer_id.replaceAll('-', ''))
    },
    storeById: state => storeId => {
      return state.stores[storeId]
    },
    isUpdatingStoreById: state => storeId => {
      return !!state.updateCalls[storeId]
    },
    getUpdateStoreErrorById: state => storeId => {
      return state.updateError[storeId]
    },
    selectedStoreId(state) {
      return state.selectedStoreId
    },
    initialFetchDone(state) {
      return state.initialFetchDone
    },
    storeSettings: state => setting => {
      // SUPPLEMENTAL_FEED
      // REWRITE_ITEM_IDS
      // KEEP_UTM_PARAMETERS
      // ENABLE_PERFORMANCE_LABELS
      // DISABLE_TITLE_REWRITES
      // DISABLE_NOTIFICATIONS
      const store = state.stores[state.selectedStoreId]
      return store && store.settings[setting].value
    },
    selectedStore(state) {
      if (state.selectedStoreId === null) {
        return null
      }

      return state.stores[state.selectedStoreId]
    },
    isFetchingStores(state) {
      return state.fetchingStores
    },
    trialDaysLeft(state, getters) {
      if (!state.selectedStoreId) return 0

      return !getters.storeById(state.selectedStoreId).states?.trial?.data
        ? 0
        : dayjs(getters.storeById(state.selectedStoreId).states?.trial?.data?.end_date).diff(dayjs(), 'day')
    },
    isLoadingStore: state => storeId => {
      return state.fetchingStores || state.loadingStore[storeId]
    },
    storeType: state => type => {
      const store = state.stores[state.selectedStoreId]
      return store.type === type
    },
    isProStore(state, getters) {
      return (
        getters.storeById(state.selectedStoreId).subscription_slug === 'pro' ||
        getters.storeById(state.selectedStoreId).subscription_slug === 'pro_plus'
      )
    },
  },
  actions: {
    async selectStore({ commit, dispatch, rootGetters }, storeId) {
      let store = await dispatch('loadStoreDetails', storeId)
      if (!!store) dispatch('addStoreToRecents', store)
      await dispatch('initIndexDB', store.name, { root: true })
      commit(TYPE.SET_SELECTED_STORE_ID, storeId)
      writeSelectedStoreId({ storeId, userId: rootGetters['auth/user'].id })
      return store
    },
    async loadAllStores({ commit, getters, dispatch }) {
      commit(TYPE.SET_IS_FETCHING_STORES, true)

      try {
        const { data } = await axios.get('site')

        commit(TYPE.SET_STORES, normalize(data.data))
        dispatch('loadRecentStores')
        commit(TYPE.SET_IS_FETCHING_STORES, false)

        if (getters.selectedStoreId) {
          await dispatch('loadStoreDetails', getters.selectedStoreId)
        }
        if (!getters.initialFetchDone) {
          commit(TYPE.SET_INITIAL_FETCH_DONE, true)
        }
      } catch (e) {
        commit(TYPE.SET_FETCHING_STORES_ERROR, e)
        commit(TYPE.SET_IS_FETCHING_STORES, false)
      }
    },
    async loadStoreDetails({ commit }, storeId) {
      commit(TYPE.SET_IS_LOADING_STORE, { storeId, loading: true })
      commit(TYPE.SET_FETCHING_STORE_DETAILS_ERROR, null)
      let store = null
      try {
        const { data } = await axios.get(`store/${storeId}`)
        store = data.data
        commit(TYPE.SET_STORE, store)
      } catch (e) {
        Sentry.captureException(e)
        commit(TYPE.SET_FETCHING_STORE_DETAILS_ERROR, e)
      }
      commit(TYPE.SET_IS_LOADING_STORE, { storeId, loading: false })
      return store
    },
    // Need to create new VueX store for sites?
    async createStore({ dispatch }, payload) {
      const resp = await axios.post('common/site', payload)
      // .then(({data}) => dispatch('selectStore', data.data.id))
      return resp
    },
    removeUpdateError({ commit }, storeId) {
      commit(TYPE.SET_UPDATE_ERROR, { storeId, error: null })
    },
    revertDowngrade({ commit, dispatch }, { storeId }) {
      return axios.put(`store/${storeId}/revert-downgrade`)
    },
    updateStore({ commit, dispatch }, { storeId, data }) {
      commit(TYPE.SET_UPDATE_ERROR, { storeId: storeId, error: null })

      const call = axios
        .put(`store/${storeId}`, data)
        .then(() => dispatch('loadStoreDetails', storeId))
        .catch(error => commit(TYPE.SET_UPDATE_ERROR, { storeId, error }))
        .finally(() => commit(TYPE.SET_UPDATE_CALL, { storeId, call: null }))

      commit(TYPE.SET_UPDATE_CALL, { storeId, call })

      return call
    },
    loadRecentStores({ commit, getters, rootGetters }) {
      const recentStoreIds = readRecentStoreIds({ userId: rootGetters['auth/user'].id })
      const recentStores = []

      if (recentStoreIds)
        recentStoreIds.forEach(recentStoreId => {
          getters.stores.forEach(store => {
            if (store.id === recentStoreId) recentStores.push(store)
          })
        })

      commit(TYPE.SET_RECENT_STORES, recentStores)
    },
    addStoreToRecents({ commit, getters, rootGetters }, store) {
      commit(TYPE.SET_RECENT_STORE, { store, userId: rootGetters['auth/user'].id })
    },
    clear({ commit }) {
      commit(TYPE.SET_SELECTED_STORE_ID, null)
      commit(TYPE.SET_STORES, {})
      commit(TYPE.SET_FETCHING_STORE_DETAILS_ERROR, null)
      commit(TYPE.SET_FETCHING_STORES_ERROR, null)
      commit(TYPE.SET_IS_FETCHING_STORES, false)
      commit(TYPE.SET_INITIAL_FETCH_DONE, false)
    },
  },
  mutations: {
    [TYPE.SET_STORES](state, stores) {
      state.stores = stores
    },
    [TYPE.SET_IS_FETCHING_STORES](state, isLoading) {
      state.fetchingStores = isLoading
    },
    [TYPE.SET_FETCHING_STORES_ERROR](state, error) {
      state.fetchingStoresError = error
    },
    [TYPE.SET_SELECTED_STORE_ID](state, id) {
      state.selectedStoreId = parseInt(id)
    },
    [TYPE.SET_IS_LOADING_STORE](state, { storeId, loading }) {
      state.loadingStore = {
        ...state.loadingStore,
        [storeId]: loading,
      }
    },
    [TYPE.SET_FETCHING_STORE_DETAILS_ERROR](state, error) {
      state.fetchingStoreError = error
    },
    [TYPE.SET_STORE](state, store) {
      state.stores = { ...state.stores, [store.id]: store }
    },
    [TYPE.SET_INITIAL_FETCH_DONE](state, value) {
      state.initialFetchDone = value
    },
    [TYPE.SET_UPDATE_CALL](state, { storeId, call }) {
      state.updateCalls = {
        ...state.updateCalls,
        [storeId]: call,
      }
    },
    [TYPE.SET_UPDATE_ERROR](state, { storeId, error }) {
      state.updateError = {
        ...state.updateError,
        [storeId]: error,
      }
    },
    [TYPE.SET_RECENT_STORES](state, stores) {
      state.recentStores = stores
    },
    [TYPE.SET_RECENT_STORE](state, { store, userId }) {
      if (state.recentStores.find(recentStore => recentStore.id === store.id)) {
        state.recentStores = state.recentStores.filter(recentStore => recentStore.id !== store.id)
      }
      if (state.recentStores.length === 4) {
        state.recentStores.pop()
      }
      state.recentStores.unshift(store)
      writeRecentStoreIds({ userId, recentStoreIds: state.recentStores.map(recentStore => recentStore.id) })
    },
  },
}
