import { pick } from '@/utils/ObjectHelpers'

export const proSteps = async (rootGetters, dispatch, commit, stepConfigurations, TYPE) => {
  const store = rootGetters['store/selectedStore']
  const account = rootGetters['account/getAccount']

  await Promise.all([
    dispatch('card/loadCardsIfNeeded', null, { root: true }),
    dispatch('billing/loadAccountBillingsIfNeeded', null, { root: true }),
    dispatch('feed/loadFeedsByStoreIdIfNeeded', store.id, { root: true }),
    dispatch('campaign/loadCampaignsByStoreIdIfNeeded', store.id, { root: true }),
  ])

  const cards = rootGetters['card/getCards']
  const feedsByStoreId = rootGetters['feed/getFeedsByStoreId'](store.id)
  const billings = rootGetters['billing/getAccountBillings']
  const campaigns = rootGetters['campaign/getCampaignsByStoreId'](store.id)

  if (!billings.length) {
    localStorage.setItem('GMC_NOT_SET', 'true')
  } else {
    localStorage.removeItem('GMC_NOT_SET')
  }

  const GMC_SET = localStorage.getItem('GMC_NOT_SET')

  let picks

  if (account.sales_led) {
    picks = ['storeInfo', 'contentApi', 'supplementalFeed', 'productFeed', 'billingInformation', 'success']
  } else {
    picks = [
      'creditCard',
      'billingInformation',
      'storeInfo',
      'contentApi',
      'supplementalFeed',
      'productFeed',
      'success',
    ]
  }

  let configuration = pick(stepConfigurations, ...picks)

  if (!account.sales_led && cards.length) {
    configuration = { ...configuration, creditCard: { ...configuration.creditCard, completed: true } }
  }
  if (feedsByStoreId.length) {
    configuration = {
      ...configuration,
      productFeed: { ...configuration.productFeed, completed: true },
    }
  }
  if (feedsByStoreId.length || !GMC_SET) {
    configuration = { ...configuration, supplementalFeed: { ...configuration.supplementalFeed, completed: true } }
  }
  if (store.country_id) {
    configuration = { ...configuration, storeInfo: { ...configuration.storeInfo, completed: true } }
  }
  if (billings.length) {
    configuration = { ...configuration, billingInformation: { ...configuration.billingInformation, completed: true } }
  }
  if (billings.length && feedsByStoreId.length) {
    configuration = { ...configuration, success: { ...configuration.success, completed: true } }
  }
  configuration = {
    ...configuration,
    contentApi: {
      ...configuration.contentApi,
      completed:
        (store.integration.content_api.has_token && !!store.integration.content_api.account_id) ||
        feedsByStoreId.length,
    },
  }

  const configurationToArray = Object.keys(configuration).map(c => configuration[c])
  commit(TYPE.SET_STEPS_CONFIGS, configuration)
  commit(TYPE.SET_STEPS, configurationToArray)
}
