<template>
  <div
    ref="scrollContainer"
    class="space-y-4 max-h-68 overflow-y-auto base-scrollbar rounded-b"
  >
    <div
      v-for="organization in organizations"
      :key="organization.id"
      class="mb-4 py-2 px-6 flex justify-between items-center transition duration-300 ease-in-out"
      :class="[
        selectedSite?.site_organization_id === organization.value || selectedOrganization === organization
          ? 'bg-bb-purple-100 hover:bg-bb-purple-100'
          : 'hover:bg-bb-purple-100',
      ]"
      @mouseover="selectOrganization($event, organization)"
    >
      <p>{{ organization.label }}</p>
      <ic-chevron
        direction="right"
        :size="16"
      />
    </div>
    <div
      v-if="selectedOrganization"
      :style="menuPosition"
      class="absolute w-80 bg-purple-0 shadow-lg rounded-sm ml-5"
    >
      <p class="text-bb-text-secondary p3 pt-6 px-6">Sites</p>
      <div>
        <SiteDropdownMenu
          :organization="selectedOrganization"
          :sites="selectedOrganization.sites"
          @close="close"
        >
        </SiteDropdownMenu>
      </div>
      <div
        v-if="!standardUser"
        class="py-5 flex justify-center border-t border-neutral-100 rounded-b"
        @click="addSite"
      >
        <p><span class="text-lg mr-2">+</span> Add site</p>
      </div>
    </div>
  </div>
</template>

<script>
import IcChevron from '@/components/icon/ic-chevron.vue'

import SiteDropdownMenu from '@/components/layout/Navbar/OrganizationAndSite/SiteDropdownMenu.vue'
import { mapState } from 'vuex'
export default {
  name: 'OrganizationDropdownMenu',
  components: { IcChevron, SiteDropdownMenu },
  props: {
    organizations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedOrganization: null,
      position: { x: 0, y: 0 },
    }
  },
  mounted() {
    this.$refs.scrollContainer.addEventListener('scroll', this.clearSelection)
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.clearSelection)
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('auth', ['user']),
    standardUser() {
      return this.user.role.name === 'standard'
    },
    menuPosition() {
      return {
        top: `${this.position.y}px`,
        left: `${this.position.x}px`,
      }
    },
  },
  methods: {
    clearSelection() {
      this.selectedOrganization = null
    },
    selectOrganization(event, organization) {
      this.selectedOrganization = null
      const rect = event.currentTarget.getBoundingClientRect()
      this.position = { x: rect.left + 75, y: rect.top - 163 }
      this.selectedOrganization = organization
    },
    addSite() {
      this.$router.push({
        name: 'onboarding-wizard',
        query: {
          desiredState: 'CREATE_SITE_INITIAL',
          organizationId: this.selectedOrganization.value,
        },
      })
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped></style>
